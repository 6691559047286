<template>
    <v-dialog v-model="show" max-width="600px" :persistent="loading" scrollable>
        <v-card class="pa-4">
            <v-card-title>
                Deletar contato de emergência <i>{{ contactData?.name }}</i>
            </v-card-title>
            <v-card-text>
                <v-form ref="formContact">
                    <v-row>
                        <v-col v-if="error_message" cols="12">
                            <v-alert type="error darken-4">
                                {{ error_message }}
                            </v-alert>
                        </v-col>
                        <v-col v-if="success_message" cols="12">
                            <v-alert type="success">
                                {{ success_message }}
                            </v-alert>
                        </v-col>
                        <v-col cols="12">
                            Tem certeza que deseja excluir o contato de emergência <b>{{ contactData?.name }}</b>?
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-row no-gutters justify="space-between">
                    <v-col cols="auto">
                        <v-btn :disabled="loading" :loading="loading" dark color="primary-pink" elevation="0" @click="close"><v-icon left>mdi-arrow-u-left-top</v-icon> Voltar</v-btn>
                    </v-col>
                    <v-col cols="auto">
                        <v-btn :disabled="loading" :loading="loading" dark color="secondary-red" elevation="0" @click="save">Deletar <v-icon right>mdi-check-bold</v-icon></v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    props: {
        value: {
            type: Boolean,
            required: false,
            default: false
        },
        contactData: {
            type: Object,
            required: false,
            default() {
                return {};
            }
        }
    },
    data() {
        return {
            show: this.value,
            basic_rules: {
                required: value => !!value || "Informação obrigatória",
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return pattern.test(value) || 'Email inválido'
                }
            },
            loading: false,
            error_message: null,
            success_message: null
        };
    },
    watch: {
        value() {
            this.error_message = null;
            this.success_message = null;
            this.show = this.value;
        },
        show() {
            if (!this.show) {
                this.$emit("close");
            }
            this.$emit("input", this.show);
        }
    },
    methods: {
        ...mapActions("customers", ["ActionDoDeleteEmergencyContact"]),
        close() {
            this.$refs.formContact.reset();
            this.show = false;
        },
        save() {
            this.loading = true;
            this.error_message = null;
            this.success_message = null;

            this.ActionDoDeleteEmergencyContact(this.contactData.id).then(() => {
                this.success_message = "Contato de emergência deletado com sucesso";
                this.$refs.formContact.reset();
                this.loading = false;
                this.$emit("deleted");
                this.show = false;
            }).catch(error => {
                console.error(error);
                this.error_message = error.response.data;
                this.loading = false;
            });
        }
    }
};
</script>
