import * as types from "./mutations-types";

export default {
    [types.SET_ALERTS_TODAY](state, list) {
        state.alerts_today = list;
    },
    [types.SET_CALENDAR_EVENTS](state, events) {
        state.calendar_events = events;
    },
    [types.SET_LAST_START_DATE](state, start_date) {
        state.last_start_date = start_date;
    },
    [types.SET_LAST_END_DATE](state, end_date) {
        state.last_end_date = end_date;
    }
};
