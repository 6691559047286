import services from "../../../http/index";
import * as types from "./mutations-types";

export const ActionDoGetTaxes = ({ commit }) => {
    return services.api.request({
        url: "taxes",
        method: "get"
    }).then(response => {
        commit(types.SET_TAXES_LIST, response.data);
        return response.data;
    });
};

export const ActionDoCreateTaxe = (context, payload) => {
    return services.api.request({
        url: "taxes",
        method: "post",
        data: payload
    }).then(response => {
        return response.data;
    });
};

export const ActionDoUpdateTaxe = (context, payload) => {
    return services.api.request({
        url: `taxes/${payload.taxe_id}`,
        method: "put",
        data: payload
    }).then(response => {
        return response.data;
    });
};

export const ActionDoDeleteTaxe = (context, taxe_id) => {
    return services.api.request({
        url: `taxes/${taxe_id}`,
        method: "delete"
    }).then(response => {
        return response.data;
    });
};
