<template>
    <v-dialog v-model="show" max-width="600px" :persistent="loading" scrollable>
        <v-card class="pa-4">
            <v-card-title>
                Novo contato de emergência
            </v-card-title>
            <v-card-text>
                <v-form ref="formContact">
                    <v-row>
                        <v-col v-if="error_message" cols="12">
                            <v-alert type="error darken-4">
                                {{ error_message }}
                            </v-alert>
                        </v-col>
                        <v-col v-if="success_message" cols="12">
                            <v-alert type="success">
                                {{ success_message }}
                            </v-alert>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field v-model="name" label="Nome" :rules="[basic_rules.required]" />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field v-model="degree_kinship" label="Grau de parentesco" :rules="[basic_rules.required]" />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field-simplemask
                                v-model="phone"
                                label="Telefone"
                                :options="{
                                    inputMask: '+## (##) #####-####',
                                    outputMask: '#############',
                                    empty: null,
                                    applyAfter: false,
                                    alphanumeric: true,
                                    lowerCase: false,
                                }"
                            />
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-row no-gutters justify="space-between">
                    <v-col cols="auto">
                        <v-btn :disabled="loading" :loading="loading" dark color="primary-pink" elevation="0" @click="close"><v-icon left>mdi-arrow-u-left-top</v-icon> Voltar</v-btn>
                    </v-col>
                    <v-col cols="auto">
                        <v-btn :disabled="loading" :loading="loading" dark color="primary-blue" elevation="0" @click="save">Salvar <v-icon right>mdi-check-bold</v-icon></v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    props: {
        value: {
            type: Boolean,
            required: false,
            default: false
        },
        customerId: {
            type: Number,
            required: false,
            default: null
        }
    },
    data() {
        return {
            show: this.value,
            basic_rules: {
                required: value => !!value || "Informação obrigatória",
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return pattern.test(value) || 'Email inválido'
                }
            },
            loading: false,
            error_message: null,
            success_message: null,
            name: null,
            degree_kinship: null,
            phone: null
        };
    },
    watch: {
        value() {
            this.show = this.value;
        },
        show() {
            this.error_message = null;
            this.success_message = null;
            this.$emit("input", this.show);
        }
    },
    methods: {
        ...mapActions("customers", ["ActionDoCreateEmergencyContact"]),
        close() {
            this.$refs.formContact.reset();
            this.show = false;
        },
        save() {
            this.loading = true;
            this.error_message = null;
            this.success_message = null;

            if (this.$refs.formContact.validate()) {
                this.ActionDoCreateEmergencyContact({
                    name: this.name,
                    degree_kinship: this.degree_kinship,
                    phone: this.phone,
                    customer_id: this.customerId
                }).then(response => {
                    if (response.emergency_contact.id) {
                        this.success_message = "Contato de emergência cadastrado com sucesso";
                        this.$refs.formContact.reset();
                        this.loading = false;
                        this.$emit("created");
                        this.show = false;
                    } else {
                        this.error_message = "Houve um erro ao cadastrar o contato de emergÇencia";
                        this.loading = false;
                    }
                }).catch(error => {
                    console.error(error);
                    this.error_message = error.response.data;
                    this.loading = false;
                });
            } else {
                this.loading = false;
            }
        }
    }
};
</script>
