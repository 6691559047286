import dayjs from "dayjs";
import services from "../../../http/index";
import * as types from "./mutations-types";

export const ActionDoGetTodayEvents = ({ commit }, payload = null) => {
    return services.api.request({
        url: "schedule/events",
        method: "get",
        params: payload
    }).then(response => {
        if (payload?.start_date === undefined) {
            let data = response.data;
            let new_alerts = {
                birthdays: [],
                sessions: [],
                one_more_session: [],
                payments: []
            };
            
            for (let customer of data.birthdays) {
                new_alerts.birthdays.push({
                    id: customer.id,
                    name: customer.name,
                    birthday: customer.birthday
                });
            }
            
            for (let customer of data.sessions) {
                new_alerts.sessions.push({
                    id: customer.id,
                    name: customer.name,
                    hour_session: dayjs(customer.next_session).format('HH:mm')
                });
            }
            
            for (let customer of data.one_more_session) {
                new_alerts.one_more_session.push({
                    id: customer.id,
                    name: customer.name,
                    balance: customer.balance,
                    session_value: customer.ammount
                });
            }
            
            for (let customer of data.payments) {
                let customer_payday = dayjs(customer.payday);

                new_alerts.payments.push({
                    id: customer.id,
                    name: customer.name,
                    ammount: customer.ammount,
                    payday: customer_payday.format('DD/MM/YYYY'),
                    overdue: customer_payday.isBefore(dayjs(), 'date')
                });
            }

            new_alerts.sessions.sort((horarioA, horarioB) => {
                const [horasA, minutosA] = horarioA.hour_session.split(':').map(Number);
                const [horasB, minutosB] = horarioB.hour_session.split(':').map(Number);
                return horasA - horasB || minutosA - minutosB;
            });

            commit(types.SET_ALERTS_TODAY, new_alerts);

            return data;
        } else {
            return response.data;
        }
    });
};

export const ActionDoGetCalendarEvents = ({ commit, state }, payload = null) => {
    if (payload?.start_date && payload?.end_date) {
        commit(types.SET_LAST_START_DATE, payload.start_date);
        commit(types.SET_LAST_END_DATE, payload.end_date);
    } else if (state.last_start_date && state.last_end_date) {
        payload = {
            start_date: state.last_start_date,
            end_date: state.last_end_date
        };
    }
    
    return services.api.request({
        url: "schedule/calendar-events",
        method: "post",
        params: payload
    }).then(response => {
        let data = response.data;
        commit(types.SET_CALENDAR_EVENTS, data);
        return data;
    });
}