<template>
    <v-hover>
        <template v-slot:default="{ hover }">
            <v-card class="pa-4 transition-swing" :elevation="hover ? 4 : 0" :color="hover ? 'secondary-gray' : 'transparent'">
                <v-row no-gutters>
                    <v-col cols="12" class="mb-2">
                        <v-card @click="$emit('click')" elevation="0" class="transparent">
                            <v-icon left :color="color">{{ icon }}</v-icon>
                            <span class="text-body-2">{{ title }}
                                <span v-if="!loading" class="text-h6">{{ titleValue }}</span>
                                <v-progress-circular v-else indeterminate :color="color" />
                            </span>
                        </v-card>
                    </v-col>
                    <v-col v-if="$scopedSlots.default" cols="12" class="mb-2">
                        <slot name="default" />
                    </v-col>
                    <v-col cols="12">
                        <v-card @click="$emit('clickProgress')" elevation="0" class="transparent">
                            <v-progress-linear :color="color" :indeterminate="loading" dark :value="progressValue" rounded height="20">
                                <span v-if="!loading" class="text-caption">{{ progressPercentLabel ?? Math.round(progressValue) }}% {{ descriptionPercent }} ({{ progressTotalLabel }})</span>
                            </v-progress-linear>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col>
                        <apexchart
                            v-if="!loadingCharts"
                            type="area"
                            height="120px"
                            :options="chartOptions"
                            :series="chartSeries"
                        />
                        <v-skeleton-loader v-else type="image" height="120px" />
                    </v-col>
                </v-row>
            </v-card>
        </template>
    </v-hover>
</template>

<script>
export default {
    props: {
        icon: {
            type: String,
            required: false,
            default: null
        },
        title: {
            type: String,
            required: false,
            default: null
        },
        titleValue: {
            type: [String, Number],
            required: false,
            default: null
        },
        chartOptions: {
            type: [Object, Array],
            required: true
        },
        chartSeries: {
            type: [Object, Array],
            required: true
        },
        descriptionPercent: {
            type: String,
            required: false,
            default: "do previsto"
        },
        progressValue: {
            type: [Number, String],
            required: false,
            default: 0
        },
        progressTotalLabel: {
            type: [String, Number],
            required: false,
            default: 100
        },
        loading: {
            type: Boolean,
            required: false,
            default: false
        },
        color: {
            type: String,
            required: false,
            default: "primary-blue"
        },
        progressPercentLabel: {
            type: [String, Number],
            required: false,
            default: null
        },
        loadingCharts: {
            type: Boolean,
            required: false,
            default: false
        }
    },
}
</script>