import services from "../../../http/index";
import * as types from "./mutations-types";

export const ActionDoGetSessions = ({ commit }, payload = null) => {
    return services.api.request({
        url: "sessions",
        method: "get",
        params: payload
    }).then(response => {
        if (!payload) {
            commit(types.SET_SESSION_LIST, response.data);
        }
        return response.data;
    });
};

export const ActionDoCreateSession = (context, payload) => {
    return services.api.request({
        url: "sessions",
        method: "post",
        data: payload
    }).then(response => {
        return response.data;
    });
};

export const ActionDoUpdateSession = (context, payload) => {
    return services.api.request({
        url: `sessions/${payload.session_id}`,
        method: "put",
        data: payload
    }).then(response => {
        return response.data;
    });
};

export const ActionDoDeleteSession = (context, session_id) => {
    return services.api.request({
        url: `sessions/${session_id}`,
        method: "delete"
    }).then(response => {
        return response.data;
    });
};

export const ActionDoGetSession = (context, session_id) => {
    return services.api.request({
        url: `sessions/${session_id}`,
        method: "get"
    }).then(response => {
        return response.data;
    });
};
