<template>
    <v-dialog v-model="show" max-width="600px" :persistent="loading" scrollable>
        <v-card class="pa-4">
            <v-card-title>
                Editar informações pessoais
            </v-card-title>
            <v-card-text>
                <v-form ref="formUserInfo">
                    <v-row>
                        <v-col v-if="error_message" cols="12">
                            <v-alert type="error darken-4">
                                {{ error_message }}
                            </v-alert>
                        </v-col>
                        <v-col v-if="success_message" cols="12">
                            <v-alert type="success">
                                {{ success_message }}
                            </v-alert>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field v-model="name" label="Name" :rules="[basic_rules.required]" />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field v-model="email" label="Email" :rules="[basic_rules.required]" />
                        </v-col>
                        <v-col v-if="user.user_image" cols="12">
                            <v-card elevation="0">
                                <v-row>
                                    <v-col cols="12">Imagem atual</v-col>
                                    <v-col>
                                        <v-img :src="user.user_image" max-width="500px" max-height="300px" contain />
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                        <v-col cols="12">
                            <v-file-input v-model="user_image" prepend-icon="mdi-camera" accept="image/*" label="Nova imagem de perfil" @change="handleFileUpload" />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field v-model="password" label="Nova senha" :rules="password_rules" type="password"/>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field v-model="repeat_password" label="Confirme a nova senha" :rules="repeat_password_rules" type="password" />
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-row no-gutters justify="space-between">
                    <v-col cols="auto">
                        <v-btn :disabled="loading" :loading="loading" outlined color="secondary-blue" @click="close"><v-icon left>mdi-arrow-u-left-top</v-icon> Voltar</v-btn>
                    </v-col>
                    <v-col cols="auto">
                        <v-btn :disabled="loading" :loading="loading" color="primary-blue" elevation="0" @click="save">Salvar <v-icon right>mdi-check-bold</v-icon></v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import dayjs from "dayjs";
dayjs.extend(require('dayjs/plugin/customParseFormat'));
import { mapActions, mapState } from 'vuex';

export default {
    props: {
        value: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            show: this.value,
            basic_rules: {
                required: value => !!value || "Informação obrigatória"
            },
            password_rules: [
                value => !value || value.length > 8 || "Mínimo de 8 caracteres",
                value => !value || /^(?=.*[a-z])(?=.*[A-Z])/.test(value) || "Precisa conter letras maiusculas e minusculas",
                value => !value || /^(?=.*\d)/.test(value) || "Precisa conter números",
                value => !value || /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(value) || "Precisa conter caracteres especiais"
            ],
            repeat_password_rules: [
                value => {
                    if (this.password?.length) {
                        return this.password === value || "As senhas são diferentes"
                    } else {
                        return true;
                    }
                }
            ],
            loading: false,
            error_message: null,
            success_message: null,
            name: null,
            email: null,
            user_image: null,
            password: null,
            repeat_password: null
        };
    },
    computed: {
        ...mapState("auth", {
            user: state => state.user
        }),
    },
    watch: {
        value() {
            this.show = this.value;
        },
        show() {
            if (!this.show) {
                this.$emit("close");

                this.loading = false;
                this.error_message = null;
                this.success_message = null;
                this.name = null;
                this.email = null;
                this.user_image = null;
                this.password = null;
                this.repeat_password = null;
            } else {
                this.setUser();
            }

            this.error_message = null;
            this.success_message = null;
            this.$emit("input", this.show);
        },
        user() {
            this.setUser();
        }
    },
    mounted() {
        this.setUser();
    },
    methods: {
        ...mapActions("utils", ["ActionDoUpdateUser"]),
        setUser() {
            if (this.user) {
                this.name = this.user.name;
                this.email = this.user.email;
            }
        },
        close() {
            this.$refs.formUserInfo.reset();
            this.show = false;
        },
        async save() {
            this.loading = true;
            this.error_message = null;
            this.success_message = null;

            if (this.$refs.formUserInfo.validate()) {
                this.ActionDoUpdateUser({
                    user_id: this.user.id,
                    name: this.name,
                    email: this.email,
                    password: this.password,
                    user_image: this.user_image ? await this.convertImageToBase64(this.user_image) : null
                }).then(() => {
                    this.success_message = "Informações alteradas com sucesso, faça login novamente para ver as alterações";
                    this.$refs.formUserInfo.reset();
                    this.loading = false;
                    this.$emit("edited");
                }).catch(error => {
                    console.error(error);
                    this.error_message = error.response.data;
                    this.show_confirmation_free_session = false;
                    this.loading_confirmation = false;
                    this.loading = false;
                });
            } else {
                this.loading = false;
            }
        },
        handleFileUpload(file) {
            this.user_image = file;
        },
        convertImageToBase64(image) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    resolve(reader.result);
                };
                reader.onerror = (error) => {
                    reject(error);
                };
                reader.readAsDataURL(image);
            });
        }
    }
};
</script>
