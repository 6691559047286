<template>
    <v-row no-gutters>
        <v-col cols="12">
            <v-row>
                <v-col cols="12" md="7">
                    <v-card class="pa-8" rounded="lg" color="primary-gray" height="100%">
                        <the-calendar @clickEvent="showEventInfo" />
                    </v-card>
                </v-col>
                <v-col cols="12" md="5">
                    <v-card rounded="lg" color="primary-gray" min-height="925">
                        <v-row no-gutters class="fill-height">
                            <v-col v-if="session_info" class="pa-6">
                                <customer-info :customer-id="session_info.customer.id" :session-info="session_info" @closeInfo="session_info = null" />
                            </v-col>
                            <v-col v-else>
                                <v-card elevation="0" color="primary-white" class="pa-6 ma-3" rounded="lg" height="188">
                                    <v-row align="center" class="text-center">
                                        <v-col cols="12">
                                            <span class=" text-h5">Sessões</span>
                                        </v-col>
                                        <v-col cols="3">
                                            <v-row no-gutters>
                                                <v-col cols="12">
                                                    <span class=" text-caption">Finalizadas</span>
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-tooltip bottom color="primary-blue">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <span class=" text-h4" v-bind="attrs" v-on="on">{{ sessions_done + sessions_rescheduled + sessions_unattended }}</span>
                                                        </template>
                                                        <v-row no-gutters>
                                                            <v-col>
                                                                <v-row no-gutters>
                                                                    <v-col>
                                                                        Realizadas: <b>{{ sessions_done }}</b>
                                                                    </v-col>
                                                                </v-row>
                                                                <v-row no-gutters>
                                                                    <v-col>
                                                                        Reagendadas: <b>{{ sessions_rescheduled }}</b>
                                                                    </v-col>
                                                                </v-row>
                                                                <v-row no-gutters>
                                                                    <v-col>
                                                                        Faltas: <b>{{ sessions_unattended }}</b>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-col>
                                                        </v-row>
                                                    </v-tooltip>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="3">
                                            <v-row no-gutters>
                                                <v-col cols="12">
                                                    <span class=" text-caption">Agendadas</span>
                                                </v-col>
                                                <v-col cols="12">
                                                    <span class=" text-h4">{{ sessions_scheduled }}</span>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="3">
                                            <v-row no-gutters>
                                                <v-col cols="12">
                                                    <span class=" text-caption">Previstas</span>
                                                </v-col>
                                                <v-col cols="12">
                                                    <span class=" text-h4">{{ sessions_future }}</span>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="3">
                                            <v-row no-gutters>
                                                <v-col cols="12">
                                                    <span class=" text-body-1">Total</span>
                                                </v-col>
                                                <v-col cols="12">
                                                    <span class=" text-h4 font-weight-bold">{{ sessions_done + sessions_scheduled + sessions_future + sessions_rescheduled + sessions_unattended }}</span>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-card>
                                <v-card elevation="0" color="primary-white" class="pa-6 ma-3" rounded="lg" height="134">
                                    <v-row align="center" class="text-center">
                                        <v-col cols="12">
                                            <span class=" text-h5">Desempenho</span>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-row no-gutters>
                                                <v-col cols="12" class="d-flex flex-nowrap text-subtitle-2 align-content-center">
                                                    <v-card :width="((sessions_done * 100) / total_sessions) + '%'" height="30" color="primary-pink" rounded="0" elevation="0" class=" overflow-hidden align-content-center rounded-l-lg">
                                                        <v-tooltip v-if="sessions_done > 0" bottom color="primary-pink darken-1">
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-row no-gutters v-bind="attrs" v-on="on">
                                                                    <v-col>
                                                                        <span>{{ Math.round((sessions_done * 100) / total_sessions) + '%' }}</span>
                                                                    </v-col>
                                                                </v-row>
                                                            </template>
                                                            Realizadas {{ sessions_done }}
                                                        </v-tooltip>
                                                    </v-card>
                                                    <v-card :width="((sessions_unattended * 100) / total_sessions) + '%'" height="30" color="secondary-red" rounded="0" elevation="0" class=" overflow-hidden align-content-center">
                                                        <v-tooltip v-if="sessions_unattended > 0"  bottom color="secondary-red">
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-row no-gutters v-bind="attrs" v-on="on">
                                                                    <v-col>
                                                                        <span>{{ Math.round((sessions_unattended * 100) / total_sessions) + '%' }}</span>
                                                                    </v-col>
                                                                </v-row>
                                                            </template>
                                                            Faltas {{ sessions_unattended }}
                                                        </v-tooltip>
                                                    </v-card>
                                                    
                                                    <v-card :width="((sessions_rescheduled * 100) / total_sessions) + '%'" height="30" color="secondary-yellow" rounded="0" elevation="0" class=" overflow-hidden align-content-center">
                                                        <v-tooltip v-if="sessions_rescheduled > 0" bottom color="secondary-yellow darken-1">
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-row no-gutters v-bind="attrs" v-on="on">
                                                                    <v-col>
                                                                        <span>{{ Math.round((sessions_rescheduled * 100) / total_sessions) + '%' }}</span>
                                                                    </v-col>
                                                                </v-row>
                                                            </template>
                                                            Reagendamentos {{ sessions_rescheduled }}
                                                        </v-tooltip>
                                                    </v-card>
                                                    <v-card :width="((sessions_scheduled * 100) / total_sessions) + '%'" height="30" color="secondary-purple" rounded="0" elevation="0" class=" overflow-hidden align-content-center" :class="sessions_future == 0 ? 'rounded-r-lg' : ''">
                                                        <v-tooltip v-if="sessions_scheduled > 0" bottom color="secondary-purple">
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-row no-gutters v-bind="attrs" v-on="on">
                                                                    <v-col>
                                                                        <span>{{ Math.round((sessions_scheduled * 100) / total_sessions) + '%' }}</span>
                                                                    </v-col>
                                                                </v-row>
                                                            </template>
                                                            Agendadas {{ sessions_scheduled }}
                                                        </v-tooltip>
                                                    </v-card>
                                                    <v-card :width="((sessions_future * 100) / total_sessions) + '%'" height="30" color="secondary-green" rounded="0" elevation="0" class=" overflow-hidden align-content-center rounded-r-lg">
                                                        <v-tooltip v-if="sessions_future > 0" bottom color="secondary-green">
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-row no-gutters v-bind="attrs" v-on="on">
                                                                    <v-col>
                                                                        <span>{{ Math.round((sessions_future * 100) / total_sessions) + '%' }}</span>
                                                                    </v-col>
                                                                </v-row>
                                                            </template>
                                                            Previstas {{ sessions_future }}
                                                        </v-tooltip>
                                                    </v-card>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-card>
                                <v-card elevation="0" color="primary-white" class="pa-6 ma-3" rounded="lg" height="555">
                                    <v-row align="center">
                                        <v-col cols="12" class="text-center">
                                            <span class="text-center text-h5">Lembretes</span>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-tabs v-model="tab" grow color="primary-blue">
                                                <v-tab key="birthdays" class="text-caption">
                                                    <v-badge :value="birthdays.length" :content="birthdays.length" color="secondary-green">Aniversário</v-badge>
                                                </v-tab>
                                                <v-tab key="one_more" class="text-caption">
                                                    <v-badge :content="one_more_session.length" color="secondary-yellow darken-1">Saldo acabando</v-badge>
                                                </v-tab>
                                                <v-tab key="payments" class="text-caption">
                                                    <v-badge :content="payments.length" color="secondary-red">Pagamentos</v-badge>
                                                </v-tab>
                                            </v-tabs>
                                            <v-tabs-items v-model="tab">
                                                <v-tab-item key="birthdays">
                                                    <v-card elevation="0" color="transparent" class=" overflow-y-auto overflow-x-hidden" height="425">
                                                        <v-timeline v-if="birthdays.length" dense align-top>
                                                            <v-timeline-item v-for="(customer, b_key) in birthdays" :key="b_key" small color="secondary-green" fill-dot>
                                                                <v-row class="pt-2">
                                                                    <v-col cols="3">
                                                                        <span class=" text-body-2 font-weight-bold">Dia {{ customer.birthday.split('-').pop() }}</span>
                                                                    </v-col>
                                                                    <v-col>
                                                                        <v-row no-gutters>
                                                                            <v-col cols="12">
                                                                                <span class=" text-body-2 font-weight-bold">{{ customer.name }}</span>
                                                                            </v-col>
                                                                            <v-col cols="12">
                                                                                <span class=" text-caption">Hoje tem {{ getAge(customer.birthday) }} anos</span>
                                                                            </v-col>
                                                                        </v-row>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-timeline-item>
                                                        </v-timeline>
                                                        <v-row v-else>
                                                            <v-col cols="12" class="text-center pa-8">
                                                                <span>Nenhum aniversário no período</span>
                                                            </v-col>
                                                        </v-row>
                                                    </v-card>
                                                </v-tab-item>
                                                <v-tab-item key="one_more">
                                                    <v-card elevation="0" color="transparent" class=" overflow-y-auto overflow-x-hidden" height="425">
                                                        <v-row v-if="one_more_session.length" class="pa-4">
                                                            <v-col cols="12">
                                                                <v-row v-for="(customer, o_key) in one_more_session" :key="o_key" no-gutters>
                                                                    <v-col cols="12">
                                                                        <span class=" text-body-2">{{ customer.name }}</span>
                                                                    </v-col>
                                                                    <v-col cols="12">
                                                                        <span class=" text-caption">Saldo atual <span class=" text-subtitle-2">{{ (customer.balance / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</span></span>
                                                                    </v-col>
                                                                    <v-col cols="12">
                                                                        <span class=" text-caption">Valor da sessão <span class=" text-subtitle-2">{{ (customer.ammount / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</span></span>
                                                                    </v-col>
                                                                    <v-col v-if="o_key < one_more_session.length - 1" cols="12" class="py-4">
                                                                        <v-divider></v-divider>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-col>
                                                        </v-row>
                                                        <v-row v-else>
                                                            <v-col cols="12" class="text-center pa-8">
                                                                <span>Nenhum saldo acabando no período</span>
                                                            </v-col>
                                                        </v-row>
                                                    </v-card>
                                                </v-tab-item>
                                                <v-tab-item key="payments">
                                                    <v-card elevation="0" color="transparent" class=" overflow-y-auto overflow-x-hidden" height="425">
                                                        <v-timeline v-if="payments.length" dense>
                                                            <v-timeline-item v-for="(customer, b_key) in payments" :key="b_key" small color="secondary-green">
                                                                <v-row class="pt-2">
                                                                    <v-col cols="3">
                                                                        <span class=" text-body-2 font-weight-bold">{{ isOverdue(customer.payday) ? 'Atrasado desde ' : 'Pagar dia '}} {{ formatPayday(customer.payday) }}</span>
                                                                    </v-col>
                                                                    <v-col>
                                                                        <v-row no-gutters>
                                                                            <v-col cols="12">
                                                                                <span class=" text-body-2 font-weight-bold">{{ customer.name }}</span>
                                                                            </v-col>
                                                                            <v-col cols="12">
                                                                                <span class=" text-caption">Valor a ser pago R$ {{ (customer.ammount / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</span>
                                                                            </v-col>
                                                                        </v-row>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-timeline-item>
                                                        </v-timeline>
                                                        <v-row v-else>
                                                            <v-col cols="12" class="text-center pa-8">
                                                                <span>Nenhum pagamento previsto para o período</span>
                                                            </v-col>
                                                        </v-row>
                                                    </v-card>
                                                </v-tab-item>
                                            </v-tabs-items>
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="6">
                    <v-card class="white--text" rounded="lg" color="primary-gray" height="100%">
                        <v-row no-gutters class="fill-height">
                            <v-col class="pa-6">
                                <session-records />
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card class="white--text" rounded="lg" color="primary-gray" height="100%">
                        <v-row no-gutters class="fill-height">
                            <v-col class="pa-6">
                                <session-mount-pending />
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import TheCalendar from './components/TheCalendar.vue';
import CustomerInfo from './components/CustomerInfo.vue';
import SessionRecords from './components/SessionRecords.vue';
import SessionMountPending from './components/SessionMountPending.vue';

import { mapState, mapActions } from "vuex";
import dayjs from 'dayjs';

export default {
    components: {
        TheCalendar,
        CustomerInfo,
        SessionRecords,
        SessionMountPending
    },
    data() {
        return {
            session_info: null,
            sessions_done: 0,
            sessions_scheduled: 0,
            sessions_future: 0,
            sessions_rescheduled: 0,
            sessions_unattended: 0,
            total_sessions: 0,
            birthdays: [],
            one_more_session: [],
            payments: [],
            tab: 'birthdays'
        };
    },
    computed: {
        ...mapState("schedule", {
            calendar_events: state => state.calendar_events,
            alerts: state => state.alerts_today,
            last_start_date: state => state.last_start_date,
            last_end_date: state => state.last_end_date
        })
    },
    watch: {
        calendar_events() {
            if (this.calendar_events) {
                this.mountSummaryData();
                this.getAlerts();
            }
        }
    },
    methods: {
        ...mapActions("schedule", ["ActionDoGetTodayEvents"]),
        showEventInfo(event) {
            this.session_info = event?.session ?? null;
        },
        mountSummaryData() {
            this.sessions_done = 0;
            this.sessions_scheduled = 0;
            this.sessions_future = 0;
            this.sessions_rescheduled = 0;
            this.sessions_unattended = 0;
            this.total_sessions = 0;

            for (let event of this.calendar_events) {
                if (event.session) {
                    if (event.session.finished && !event.session.rescheduled && event.session.attended) {
                        this.sessions_done++;
                    } else if (!event.session.finished && !event.session.rescheduled && !event.session.attended) {
                        this.sessions_scheduled++;
                    } else if (event.session.rescheduled) {
                        this.sessions_rescheduled++;
                    } else if (!event.session.attended) {
                        this.sessions_unattended++;
                    }

                    this.total_sessions++;
                } else if (event.future) {
                    this.sessions_future++;
                    this.total_sessions++;
                }
            }
        },
        getAlerts() {
            this.birthdays = [];
            this.one_more_session = [];
            this.payments = [];

            if (this.last_start_date && this.last_end_date) {
                this.ActionDoGetTodayEvents({
                    start_date: this.last_start_date,
                    end_date: this.last_end_date
                }).then(response => {
                    this.birthdays = response.birthdays;
                    this.one_more_session = response.one_more_session;
                    this.payments = response.payments;
                });
            }
        },
        getAge(birthday) {
            return dayjs().diff(dayjs(birthday), 'year');
        },
        isOverdue(payday) {
            return dayjs(payday).isBefore(dayjs(), 'date');
        },
        formatPayday(payday) {
            return dayjs(payday).format('DD/MM')
        }
    }
};
</script>
