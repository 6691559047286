<template>
    <v-card class="pa-8" rounded="lg" color="primary-gray">
        <v-row no-gutters>
            <v-col>
                <v-data-table
                    :headers="headers"
                    :items="taxes_list"
                    class="primary-gray"
                    :loading="loading"
                    sort-by="title"
                    :search="search"
                >
                    <template #top>
                        <v-row align="center">
                            <v-col cols="6">
                                <v-text-field v-model="search" label="Buscar" append-icon="mdi-magnify" />
                            </v-col>
                            <v-col cols="6" class="d-flex justify-end">
                                <v-btn elevation="0" color="primary-blue" dark @click="show_add = true">Programar nova despesa <v-icon right>mdi-cash-clock</v-icon></v-btn>
                            </v-col>
                        </v-row>
                    </template>
                    <template v-slot:[`item.ammount`]="{ item }">
                        {{ (item.ammount / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}
                    </template>
                    <template v-slot:[`item.due_date`]="{ item }">
                        {{ item.due_date ? formatDate(item.due_date) : 'Não programado' }}
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-row no-gutters class="flex-nowrap">
                            <v-col cols="auto" class="px-1">
                                <v-tooltip top color="blue">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon @click="payments(item)" color="blue" v-bind="attrs" v-on="on">
                                            <v-icon>
                                                mdi-list-box-outline
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    Pagamentos realizados
                                </v-tooltip>
                            </v-col>
                            <v-col cols="auto" class="px-1">
                                <v-tooltip top color="primary-blue">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon @click="edit(item)" color="primary-blue" v-bind="attrs" v-on="on">
                                            <v-icon>
                                                mdi-pencil
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    Editar
                                </v-tooltip>
                            </v-col>
                            <v-col cols="auto" class="px-1">
                                <v-tooltip top color="error darken-4">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon @click="disable(item)" color="error darken-4"  v-bind="attrs" v-on="on">
                                            <v-icon>
                                                mdi-close-thick
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    Deletar
                                </v-tooltip>
                            </v-col>
                        </v-row>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <dialog-add-taxe v-model="show_add" @created="getTaxes" />
        <dialog-edit-taxe v-model="show_edit" :taxe-data="taxe_selected" @edited="getTaxes" @close="taxe_selected = null" />
        <dialog-delete-taxe v-model="show_delete" :taxe-data="taxe_selected" @deleted="getTaxes" @close="taxe_selected = null" />
        <dialog-list-payments v-model="show_list_payments" :taxe-data="taxe_selected" @close="show_list_payments = null" @updateData="getTaxes" />
    </v-card>
</template>

<script>
import dayjs from 'dayjs';
import DialogAddTaxe from './components/DialogAddTaxe.vue';
import DialogEditTaxe from './components/DialogEditTaxe.vue';
import DialogDeleteTaxe from './components/DialogDeleteTaxe.vue';
import DialogListPayments from '../extract/components/DialogListPayments.vue';
import { mapActions } from 'vuex';

export default {
    components: {
        DialogAddTaxe,
        DialogEditTaxe,
        DialogDeleteTaxe,
        DialogListPayments
    },
    data() {
        return {
            headers: [
                { text: "Título", value: "title" },
                { text: "Descrição", value: "description" },
                { text: "Valor a ser pago", value: "ammount" },
                { text: "Data de vencimento", value: "due_date" },
                { text: '', value: 'actions' },
            ],
            loading: false,
            search: "",
            show_add: false,
            taxe_selected: null,
            show_edit: false,
            show_delete: false,
            show_list_payments: false,
            taxes_list: []
        };
    },
    mounted() {
        this.getTaxes();
    },
    methods: {
        ...mapActions("taxes", ["ActionDoGetTaxes"]),
        getTaxes() {
            this.loading = true;
            this.ActionDoGetTaxes().then((taxes) => {
                this.taxes_list = taxes;
                if (this.taxe_selected) {
                    this.taxe_selected = taxes.find((taxe) => taxe.id === this.taxe_selected.id);
                }
                this.loading = false;
            });
        },
        formatDate(date) {
            return dayjs(date).format('DD/MM/YYYY');
        },
        edit(taxe) {
            this.taxe_selected = taxe;
            this.show_edit = true;
        },
        disable(taxe) {
            this.taxe_selected = taxe;
            this.show_delete = true;
        },
        payments(taxe) {
            this.taxe_selected = taxe;
            this.show_list_payments = true;
        }
    }
};
</script>
