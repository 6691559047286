<template>
    <v-dialog v-model="show" max-width="600px" :persistent="loading" scrollable>
        <v-card class="pa-4">
            <v-card-title>
                Novo cliente
            </v-card-title>
            <v-card-text>
                <v-form ref="formCustomer">
                    <v-row>
                        <v-col v-if="error_message" cols="12">
                            <v-alert type="error darken-4">
                                {{ error_message }}
                            </v-alert>
                        </v-col>
                        <v-col v-if="success_message" cols="12">
                            <v-alert type="success">
                                {{ success_message }}
                            </v-alert>
                        </v-col>
                        <v-col cols="12">
                            <v-row align="center">
                                <v-col>
                                    <v-divider></v-divider>
                                </v-col>
                                <v-col cols="auto">
                                    Informações do cliente
                                </v-col>
                                <v-col>
                                    <v-divider></v-divider>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field v-model="name" label="Nome" :rules="[basic_rules.required]" />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field-simplemask
                                v-model="cpf"
                                label="CPF"
                                :options="{
                                    inputMask: '###.###.###-##',
                                    outputMask: '###########',
                                    empty: null,
                                    applyAfter: false,
                                    alphanumeric: true,
                                    lowerCase: false,
                                }"
                            />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field v-model="rg" label="RG" />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field v-model="email" label="Email" />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field-simplemask
                                v-model="phone"
                                label="Telefone"
                                :options="{
                                    inputMask: '+## (##) #####-####',
                                    outputMask: '#############',
                                    empty: null,
                                    applyAfter: false,
                                    alphanumeric: true,
                                    lowerCase: false,
                                }"
                            />
                        </v-col>
                        <v-col cols="6">
                            <v-menu
                                ref="menuBirthPicker"
                                v-model="show_datepicker_birthday"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        :value="formatDate(birthday)"
                                        label="Data de nascimento"
                                        append-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        @input="$event => birthday = formatUsa($event)"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="birthday"
                                    no-title
                                    scrollable
                                    editable
                                    @input="show_datepicker_birthday = false"
                                />
                            </v-menu>
                        </v-col>
                        <v-col cols="6">
                            <v-select
                                v-model="status"
                                label="Status do cliente"
                                :items="[
                                    { text: 'Primeiro contato', value: 'P' },
                                    { text: 'Cliente ativo', value: 'A' },
                                    { text: 'Cliente inativo', value: 'I' }
                                ]"
                                :rules="[basic_rules.required]"
                            />
                        </v-col>
                        <v-col cols="6">
                            <v-switch
                                v-model="signed_contract"
                                color="primary-blue"
                                label="Contrato assinado"
                            ></v-switch>
                        </v-col>
                        <v-col cols="12">
                            <v-row align="center">
                                <v-col>
                                    <v-divider></v-divider>
                                </v-col>
                                <v-col cols="auto">
                                    Informações do pagamento
                                </v-col>
                                <v-col>
                                    <v-divider></v-divider>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12">
                            <v-switch
                                v-model="equal_payer"
                                color="primary-blue"
                                label="O cliente é o pagador"
                            ></v-switch>
                        </v-col>
                        <v-col v-if="!equal_payer" cols="6">
                            <v-text-field v-model="payer_name" label="Nome do pagador" :rules="!equal_payer ? [basic_rules.required] : []" />
                        </v-col>
                        <v-col v-if="!equal_payer" cols="6">
                            <v-text-field-simplemask
                                v-model="payer_cpf"
                                label="CPF do pagador"
                                :options="{
                                    inputMask: '###.###.###-##',
                                    outputMask: '###########',
                                    empty: null,
                                    applyAfter: false,
                                    alphanumeric: true,
                                    lowerCase: false,
                                }"
                            />
                        </v-col>
                        <v-col cols="6">
                            <v-select
                                v-model="payment_type"
                                label="Tipo de pagamento"
                                :items="[
                                    { text: 'Pagamento mensal', value: 'M' },
                                    { text: 'Pagamento por sessão', value: 'S' }
                                ]"
                                :rules="[basic_rules.required]"
                            />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field-money
                                v-model="ammount"
                                label="Valor a ser pago"
                                :properties="{
                                    prefix: 'R$',
                                }"
                                :options="{
                                    locale: 'pt-BR',
                                    length: 11,
                                    precision: 2,
                                    empty: null,
                                }"
                            />
                        </v-col>
                        <v-col :cols="payment_type !== 'M' ? 6 : 12">
                            <v-menu
                                ref="menuDatePicker"
                                v-model="show_datepicker"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        :value="formatDate(payday)"
                                        label="Dia do próximo pagamento"
                                        append-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        @input="$event => payday = formatUsa($event)"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="payday"
                                    no-title
                                    scrollable
                                    editable
                                    @input="show_datepicker = false"
                                />
                            </v-menu>
                        </v-col>
                        <v-col v-if="payment_type !== 'M'" cols="6">
                            <v-select
                                v-model="payment_frequency"
                                label="Frequencia de pagamento"
                                :items="[
                                    { text: 'Mensal', value: 'M' },
                                    { text: 'Quinzenal', value: 'Q' },
                                    { text: 'Semanal', value: 'S' }
                                ]"
                                :rules="[basic_rules.required]"
                            />
                        </v-col>
                        <v-col cols="12">
                            <v-text-field-money
                                v-model="balance"
                                label="Saldo inicial"
                                :properties="{
                                    prefix: 'R$',
                                }"
                                :options="{
                                    locale: 'pt-BR',
                                    length: 11,
                                    precision: 2,
                                    empty: null,
                                }"
                            />
                        </v-col>
                        <v-col cols="12">
                            <v-row align="center">
                                <v-col>
                                    <v-divider></v-divider>
                                </v-col>
                                <v-col cols="auto">
                                    Informações do processo terapeutico
                                </v-col>
                                <v-col>
                                    <v-divider></v-divider>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="6">
                            <v-select
                                v-model="week_day_session"
                                label="Dia da sessão"
                                :items="[
                                    { text: 'Segunda', value: 'monday' },
                                    { text: 'Terça', value: 'tuesday' },
                                    { text: 'Quarta', value: 'wednesday' },
                                    { text: 'Quinta', value: 'thursday' },
                                    { text: 'Sexta', value: 'friday' },
                                    { text: 'Sábado', value: 'saturday' },
                                    { text: 'Domingo', value: 'sunday' }
                                ]"
                            />
                        </v-col>
                        <v-col cols="6">
                            <v-menu
                                ref="menuTimePicker"
                                v-model="show_timepicker"
                                :close-on-content-click="false"
                                :return-value.sync="hour_session"
                                transition="scale-transition"
                                offset-y
                                min-width="300px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="hour_session"
                                        label="Hora da sessão"
                                        append-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-time-picker
                                    v-if="show_timepicker"
                                    v-model="hour_session"
                                    format="24hr"
                                    full-width
                                    @click:minute="$refs.menuTimePicker.save(hour_session)"
                                />
                            </v-menu>
                        </v-col>
                        <v-col cols="6">
                            <v-select
                                v-model="session_frequency"
                                label="Frequencia da sessão"
                                :items="[
                                    { text: 'Mensal', value: 'M' },
                                    { text: 'Quinzenal', value: 'Q' },
                                    { text: 'Semanal', value: 'S' }
                                ]"
                                :rules="[basic_rules.required]"
                            />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field v-model="session_duration" label="Duração (em minutos)" type="number" />
                        </v-col>
                        <v-col v-if="status !== 'I'" cols="6">
                            <v-menu
                                ref="menuNextSession"
                                v-model="show_next_session"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        :value="formatDate(next_session)"
                                        label="Data da próxima sessão"
                                        append-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        @input="$event => next_session = formatUsa($event)"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="next_session"
                                    no-title
                                    scrollable
                                    editable
                                    @input="show_next_session = false"
                                />
                            </v-menu>
                        </v-col>
                        <v-col v-if="status !== 'I'" cols="6">
                            <v-menu
                                ref="menuTimePickerSession"
                                v-model="show_timepicker_session"
                                :close-on-content-click="false"
                                :return-value.sync="hour_next_session"
                                transition="scale-transition"
                                offset-y
                                min-width="300px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="hour_next_session"
                                        label="Hora da próxima sessão"
                                        append-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        :rules="[basic_rules.required]"
                                    ></v-text-field>
                                </template>
                                <v-time-picker
                                    v-if="show_timepicker_session"
                                    v-model="hour_next_session"
                                    format="24hr"
                                    full-width
                                    @click:minute="$refs.menuTimePickerSession.save(hour_next_session)"
                                />
                            </v-menu>
                        </v-col>
                        <v-col v-if="status === 'A'" cols="12">
                            <v-switch
                                v-model="next_session_mounted"
                                color="primary-blue"
                                label="Próxima sessão estruturada?"
                            ></v-switch>
                        </v-col>
                        <v-col cols="12">
                            <v-row align="center">
                                <v-col>
                                    <v-divider></v-divider>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12">
                            <v-textarea v-model="comments" label="Observações" />
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-row no-gutters justify="space-between">
                    <v-col cols="auto">
                        <v-btn :disabled="loading" :loading="loading" dark color="primary-pink" elevation="0" @click="close"><v-icon left>mdi-arrow-u-left-top</v-icon> Voltar</v-btn>
                    </v-col>
                    <v-col cols="auto">
                        <v-btn :disabled="loading" :loading="loading" dark color="primary-blue" elevation="0" @click="save">Salvar <v-icon right>mdi-check-bold</v-icon></v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import dayjs from "dayjs";
dayjs.extend(require('dayjs/plugin/customParseFormat'));
import { mapActions } from 'vuex';

export default {
    props: {
        value: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            show: this.value,
            basic_rules: {
                required: value => !!value || "Informação obrigatória",
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return pattern.test(value) || 'Email inválido'
                }
            },
            show_datepicker: false,
            show_datepicker_birthday: false,
            loading: false,
            error_message: null,
            success_message: null,
            name: null,
            cpf: null,
            rg: null,
            email: null,
            phone: null,
            status: "P",
            signed_contract: false,
            ammount: null,
            payment_type: "M",
            payday: null,
            birthday: null,
            payment_frequency: "M",
            week_day_session: null,
            session_frequency: "S",
            session_duration: 50,
            comments: null,
            show_timepicker: false,
            show_timepicker_session: false,
            hour_session: null,
            hour_next_session: null,
            show_next_session: false,
            next_session: null,
            payer_name: null,
            payer_cpf: null,
            equal_payer: true,
            balance: 0,
            next_session_mounted: false
        };
    },
    watch: {
        value() {
            this.show = this.value;
        },
        show() {
            this.error_message = null;
            this.success_message = null;
            this.$emit("input", this.show);
        },
        signed_contract(new_value) {
            if (new_value) {
                this.status = 'A';
            }
        }
    },
    methods: {
        ...mapActions("customers", ["ActionDoCreateCustomer", "ActionDoGetCustomers"]),
        ...mapActions("sessions", ["ActionDoCreateSession"]),
        formatDate(date) {
            return date ? dayjs(date).format("DD/MM/YYYY") : null;
        },
        formatUsa(date) {
            if (date) {
                if (date.length === 8) {
                    return dayjs(date, 'DDMMYYYY').format('YYYY-MM-DD');
                } else if (date.length === 10) {
                    return dayjs(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
                }
            }

            return null;
        },
        close() {
            this.$refs.formCustomer.reset();
            this.show = false;
        },
        save() {
            this.loading = true;
            this.error_message = null;
            this.success_message = null;

            if (this.$refs.formCustomer.validate()) {
                this.ActionDoCreateCustomer({
                    name: this.name,
                    cpf: this.cpf,
                    rg: this.rg,
                    email: this.email,
                    phone: this.phone,
                    birthday: this.birthday,
                    ammount: this.ammount?.replaceAll(".", ""),
                    payment_type: this.payment_type,
                    status: this.status,
                    signed_contract: this.signed_contract,
                    payday: this.payday,
                    payment_frequency: this.payment_frequency,
                    session_frequency: this.session_frequency,
                    week_day_session: this.week_day_session,
                    session_duration: this.session_duration,
                    hour_session: this.hour_session,
                    next_session: null,
                    comments: this.comments,
                    payer_name: this.payer_name,
                    payer_cpf: this.payer_cpf,
                    balance: this.balance !== 0 ? parseInt(this.balance?.replaceAll(".", "")) : this.balance,
                    next_session_mounted: this.next_session_mounted
                }).then(response => {
                    if (this.status !== 'I') {
                        let session_value = this.ammount ? this.ammount.replaceAll(".", "") : 0;
                        switch (this.payment_type) {
                            case "S":
                                session_value = session_value / 100;
                                break;
                            case "M":
                                session_value = (session_value / 100) / 4;
                                break;
                        }
                        
                        this.ActionDoCreateSession({
                            customer_id: response.customer_id.id,
                            session_date: this.next_session + ' ' + this.hour_next_session,
                            attended: false,
                            comments: null,
                            session_value: session_value,
                            session_duration: this.session_duration,
                            session_record: false,
                            finished: false,
                            mounted: false
                        });
                    }
                    this.ActionDoGetCustomers();

                    if (response.customer_id) {
                        this.success_message = "Cliente cadastrado com sucesso";
                        this.$refs.formCustomer.reset();
                        this.loading = false;
                        this.$emit("created");
                        this.show = false;
                    } else {
                        this.error_message = "Houve um erro ao cadastrar o cliente";
                        this.loading = false;
                    }
                }).catch(error => {
                    console.error(error);
                    this.error_message = error.response.data;
                    this.loading = false;
                });
            } else {
                this.loading = false;
            }
        }
    }
};
</script>
