<template>
    <v-dialog v-model="show" max-width="600px" :persistent="loading">
        <v-card class="pa-4">
            <v-card-title>
                Editar despesa programada: <i>&nbsp;{{ title }}</i>
            </v-card-title>
            <v-card-text>
                <v-form ref="formTaxe">
                    <v-row>
                        <v-col v-if="error_message" cols="12">
                            <v-alert type="error darken-4">
                                {{ error_message }}
                            </v-alert>
                        </v-col>
                        <v-col v-if="success_message" cols="12">
                            <v-alert type="success">
                                {{ success_message }}
                            </v-alert>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field v-model="title" label="Título" :rules="[basic_rules.required]" />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field-money
                                v-model="ammount"
                                label="Valor a ser pago"
                                :properties="{
                                    prefix: 'R$',
                                }"
                                :options="{
                                    locale: 'pt-BR',
                                    length: 11,
                                    precision: 2,
                                    empty: null,
                                }"
                            />
                        </v-col>
                        <v-col cols="12">
                            <v-menu
                                ref="menuDatePicker"
                                v-model="show_datepicker"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        :value="formatDate(due_date)"
                                        label="Dia do próximo pagamento"
                                        append-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                    />
                                </template>
                                <v-date-picker
                                    v-model="due_date"
                                    no-title
                                    scrollable
                                    editable
                                    @input="show_datepicker = false"
                                />
                            </v-menu>
                        </v-col>
                        <v-col cols="12">
                            <v-textarea v-model="description" label="Descrição" />
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-row no-gutters justify="space-between">
                    <v-col cols="auto">
                        <v-btn :disabled="loading" :loading="loading" color="primary-pink" dark elevation="0" @click="close"><v-icon left>mdi-arrow-u-left-top</v-icon> Voltar</v-btn>
                    </v-col>
                    <v-col cols="auto">
                        <v-btn :disabled="loading" :loading="loading" color="primary-blue" dark elevation="0" @click="save">Salvar <v-icon right>mdi-check-bold</v-icon></v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import dayjs from "dayjs";
import { mapActions } from 'vuex';

export default {
    props: {
        value: {
            type: Boolean,
            required: false,
            default: false
        },
        taxeData: {
            type: Object,
            required: false,
            default() {
                return {};
            }
        }
    },
    data() {
        return {
            show: this.value,
            basic_rules: {
                required: value => !!value || "Informação obrigatória"
            },
            show_datepicker: false,
            loading: false,
            error_message: null,
            success_message: null,
            title: null,
            description: null,
            ammount: null,
            due_date: null
        };
    },
    watch: {
        value() {
            this.show = this.value;
        },
        show() {
            if (!this.show) {
                this.$emit("close");

                this.show_datepicker = false;
                this.loading = false;
                this.error_message = null;
                this.success_message = null;
                this.title = null;
                this.description = null;
                this.ammount = null;
                this.due_date = null;
            }

            this.error_message = null;
            this.success_message = null;
            this.$emit("input", this.show);
        },
        taxeData() {
            if (this.taxeData) {
                this.title = this.taxeData.title;
                this.description = this.taxeData.description;
                this.ammount = this.taxeData.ammount / 100;
                this.due_date = this.taxeData.due_date;
            }
        }
    },
    methods: {
        ...mapActions("taxes", ["ActionDoUpdateTaxe"]),
        formatDate(date) {
            return date ? dayjs(date).format("DD/MM/YYYY") : null;
        },
        close() {
            this.$refs.formTaxe.reset();
            this.show = false;
        },
        save() {
            this.loading = true;
            this.error_message = null;
            this.success_message = null;

            if (this.$refs.formTaxe.validate()) {
                this.ActionDoUpdateTaxe({
                    title: this.title,
                    description: this.description,
                    ammount : Number.isInteger(this.ammount) ? this.ammount * 100 : parseInt(this.ammount.toString().replace(/[^0-9]+/g, "")),
                    due_date: this.due_date,
                    taxe_id: this.taxeData.id
                }).then(() => {
                    this.success_message = "Despesa programada editada com sucesso";
                    this.$refs.formTaxe.reset();
                    this.loading = false;
                    this.$emit("edited");
                    this.show = false;
                }).catch(error => {
                    console.error(error);
                    this.error_message = error.response.data;
                    this.loading = false;
                });
            } else {
                this.loading = false;
            }
        }
    }
};
</script>
