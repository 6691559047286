<template>
    <v-row>
        <v-col cols="12">
            <v-row dense justify="space-between" align="center">
                <v-col cols="auto" order="2" order-lg="first">
                    <v-btn icon @click="$refs.calendar.prev()">
                        <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                </v-col>
                <v-col cols="12" lg="auto">
                    <v-select
                        v-model="calendar_type"
                        :items="calendar_types"
                        hide-details
                        solo
                        dense
                        flat
                        elevation="0"
                        label="Tipo de visualização"
                    ></v-select>
                </v-col>
                <v-col v-if="$refs.calendar" cols="auto" order="3">
                    <h3>{{ $refs.calendar.title.toLocaleString('default', { month: 'long' }) }}</h3>
                </v-col>
                <v-col cols="auto" order="last">
                    <v-btn icon @click="$refs.calendar.next()">
                        <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-sheet height="687">
                        <v-calendar
                            ref="calendar"
                            v-model="calendar_value"
                            :type="calendar_type"
                            :events="events"
                            first-time="10:00"
                            interval-count="13"
                            color="secondary-purple"
                            :event-ripple="false"
                            @change="getCalendarEvents"
                            @click:event="showEvent"
                            @click:more="viewDay"
                            @click:date="viewDay"
                        >
                            <template v-slot:day-body="{ date }">
                                <div
                                    :class="{ first: date === today, 'v-current-time': date === today }"
                                    :style="{ top: nowY }"
                                />
                            </template>
                            <template v-slot:event="{ event }">
                                <v-card 
                                    v-if="!event.future"
                                    class="px-1 overflow-hidden"
                                    width="100%"
                                    style="white-space: nowrap;"
                                    height="100%"
                                    color="transparent"
                                    elevation="0"
                                >
                                    <v-row no-gutters>
                                        <v-col>
                                            <span class="text-caption font-weight-bold white--text">{{ event.name }}</span>
                                        </v-col>
                                    </v-row>
                                </v-card>
                                <div v-else class="pa-1 fill-height">
                                    <v-card 
                                        class="px-1 overflow-hidden rounded-0"
                                        width="100%"
                                        style="white-space: nowrap;"
                                        height="100%"
                                        color="primary-white"
                                        elevation="0"
                                    >
                                        <v-row no-gutters>
                                            <v-col>
                                                <span class="text-caption font-weight-bold black--text">{{ event.name }}</span>
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                </div>
                            </template>
                        </v-calendar>
                    </v-sheet>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-card elevation="0" color="primary-white">
                        <v-row align="center" justify="center">
                            <v-col cols="auto">
                                Legenda:
                            </v-col>
                            <v-col cols="auto">
                                <v-chip color="white--text font-weight-bold secondary-purple lighten-1">Compareceu</v-chip>
                            </v-col>
                            <v-col cols="auto">
                                <v-chip color="white--text font-weight-bold secondary-red">Faltou</v-chip>
                            </v-col>
                            <v-col cols="auto">
                                <v-chip color="white--text font-weight-bold secondary-yellow">Remarcou</v-chip>
                            </v-col>
                            <v-col cols="auto">
                                <v-chip color="white--text font-weight-bold secondary-purple">Agendadas</v-chip>
                            </v-col>
                            <v-col cols="auto">
                                <v-chip color="white--text font-weight-bold primary-pink">Extra</v-chip>
                            </v-col>
                            <v-col cols="auto">
                                <v-chip color="white--text font-weight-bold tertiary-red">Pagamento pendente</v-chip>
                            </v-col>
                            <v-col cols="auto">
                                <v-chip color="white--text font-weight-bold secondary-green">Aniversário</v-chip>
                            </v-col>
                            <v-col cols="auto">
                                <v-chip color="black--text secondary-purple" outlined><b>Previsto</b></v-chip>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import dayjs from 'dayjs';

dayjs.extend(require('dayjs/plugin/isSameOrBefore'));

export default {
    data() {
        return {
            calendar_type: this.$vuetify.breakpoint.mobile ? 'day' : 'week',
            calendar_types: [
                { value: 'month', text: 'Visualizar mês' },
                { value: 'week', text: 'Visualizar semana' },
                { value: 'day', text: 'Visualizar dia' }
            ],
            calendar_value: '',
            ready: false,
            today: dayjs().format('YYYY-MM-DD'),
            selectedEvent: null,
            overlayStyle: {
                top: 0,
                left: 0
            }
        }
    },
    computed: {
        ...mapState("schedule", {
            events: state => state.calendar_events
        }),
        cal () {
            return this.ready ? this.$refs.calendar : null
        },
        nowY () {
            return this.cal ? this.cal.timeToY(this.cal.times.now) + 'px' : '-10px'
        },
    },
    mounted() {
        this.scrollToTime();
        this.updateTime();

        setTimeout(() => {
            this.getCalendarEvents({ start: this.$refs.calendar.lastStart, end: this.$refs.calendar.lastEnd });
        }, 1000);
    },
    methods: {
        ...mapActions("schedule", ["ActionDoGetCalendarEvents"]),
        getCalendarEvents({ start, end }) {
            this.ActionDoGetCalendarEvents({
                start_date: start.date,
                end_date: end.date
            }).then(() => {
                this.ready = true;
            })
        },
        viewDay({ date }) {
            this.calendar_value = date;
            this.calendar_type = 'day';
        },
        showEvent({ nativeEvent, event }) {
            nativeEvent.stopPropagation();
            if (event?.session) {
                this.$emit("clickEvent", event);
                this.selectedEvent = event;
            } else {
                this.$emit("clickEvent", null);
                this.selectedEvent = null;
            }
        },
        getCurrentTime () {
            return this.cal ? this.cal.times.now.hour * 60 + this.cal.times.now.minute : 0;
        },
        scrollToTime () {
            const time = this.getCurrentTime();
            const first = Math.max(0, time - (time % 30) - 30);

            this.cal?.scrollToTime(first);
        },
        updateTime () {
            setInterval(() => this.cal.updateTimes(), 60 * 1000);
        }
    }
}
</script>

<style lang="scss">
.v-current-time {
    height: 2px;
    background-color: #ea4335;
    position: absolute;
    left: -1px;
    right: 0;
    pointer-events: none;

    &.first::before {
        content: '';
        position: absolute;
        background-color: #ea4335;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        margin-top: -5px;
        margin-left: -6.5px;
    }
}
</style>
