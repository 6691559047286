<template>
    <v-dialog v-model="show" max-width="600px" :persistent="loading" scrollable>
        <v-card class="pa-4">
            <v-card-title>
                Editar sessão
            </v-card-title>
            <v-card-text>
                <v-form ref="formSession">
                    <v-row>
                        <v-col v-if="error_message" cols="12">
                            <v-alert type="error darken-4">
                                {{ error_message }}
                            </v-alert>
                        </v-col>
                        <v-col v-if="success_message" cols="12">
                            <v-alert type="success">
                                {{ success_message }}
                            </v-alert>
                        </v-col>
                        <v-col v-if="!finished" cols="12">
                            <v-switch
                                v-model="rescheduled"
                                color="primary-blue"
                                label="Marcar como reagendada"
                            ></v-switch>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                v-model="customer_name"
                                label="Cliente"
                                :disabled="true"
                            />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field-money
                                v-model="session_value"
                                label="Valor da sessão"
                                :properties="{
                                    prefix: 'R$',
                                }"
                                :options="{
                                    locale: 'pt-BR',
                                    length: 11,
                                    precision: 2,
                                    empty: null,
                                }"
                            />
                        </v-col>
                        <v-col cols="4">
                            <v-menu
                                ref="sessionDate"
                                v-model="show_datepicker_date"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        :value="formatDate(session_date)"
                                        label="Data da sessão"
                                        append-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        :rules="[basic_rules.required]"
                                        @input="$event => session_date = formatUsa($event)"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="session_date"
                                    no-title
                                    scrollable
                                    editable
                                    @input="show_datepicker_date = false"
                                />
                            </v-menu>
                        </v-col>
                        <v-col cols="4">
                            <v-menu
                                ref="menuTimePicker"
                                v-model="show_timepicker"
                                :close-on-content-click="false"
                                :return-value.sync="hour_session"
                                transition="scale-transition"
                                offset-y
                                min-width="300px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="hour_session"
                                        label="Hora da sessão"
                                        append-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        :rules="[basic_rules.required]"
                                    ></v-text-field>
                                </template>
                                <v-time-picker
                                    v-if="show_timepicker"
                                    v-model="hour_session"
                                    format="24hr"
                                    full-width
                                    @click:minute="$refs.menuTimePicker.save(hour_session)"
                                />
                            </v-menu>
                        </v-col>
                        <v-col cols="4">
                            <v-text-field v-model="session_duration" label="Duração (em minutos)" type="number" />
                        </v-col>
                        <v-col v-if="sessionData?.finished" cols="6">
                            <v-switch
                                v-model="attended"
                                color="primary-blue"
                                label="O cliente compareceu"
                            ></v-switch>
                        </v-col>
                        <v-col v-if="sessionData?.finished" cols="6">
                            <v-switch
                                v-model="session_record"
                                color="primary-blue"
                                label="Prontuário montado?"
                            ></v-switch>
                        </v-col>
                        <v-col cols="12">
                            <v-textarea v-model="comments" label="Observações" />
                        </v-col>
                        <v-col v-if="sessionData?.finished" cols="6">
                            <v-switch
                                v-model="finished"
                                color="primary-blue"
                                label="Sessão finalizada"
                            ></v-switch>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-row no-gutters justify="space-between">
                    <v-col cols="auto">
                        <v-btn :disabled="loading" :loading="loading" color="primary-pink" dark elevation="0" @click="close"><v-icon left>mdi-arrow-u-left-top</v-icon> Voltar</v-btn>
                    </v-col>
                    <v-col cols="auto">
                        <v-btn :disabled="loading" :loading="loading" color="primary-blue" dark elevation="0" @click="save">Salvar <v-icon right>mdi-check-bold</v-icon></v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
            <v-dialog v-model="show_confirmation_free_session" max-width="500px">
                <v-card class="pa-4">
                    <v-card-title>
                        Sessão gratuita
                    </v-card-title>
                    <v-card-text>
                        Essa sessão está com valor <b>R$0,00</b>. Tem certeza que deseja salvar?
                    </v-card-text>
                    <v-card-actions>
                        <v-row no-gutters justify="space-between">
                            <v-col cols="auto">
                                <v-btn :disabled="loading_confirmation" :loading="loading_confirmation" dark color="primary-pink" elevation="0" @click="closeConfirmation"><v-icon left>mdi-close-thick</v-icon> Cancelar</v-btn>
                            </v-col>
                            <v-col cols="auto">
                                <v-btn :disabled="loading_confirmation" :loading="loading_confirmation" dark color="primary-blue" elevation="0" @click="updateSession">Salvar <v-icon right>mdi-check-bold</v-icon></v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-card>
    </v-dialog>
</template>

<script>
import dayjs from "dayjs";
dayjs.extend(require('dayjs/plugin/customParseFormat'));
import { mapActions } from 'vuex';
import * as types from '../../../utils/store/mutations-types';

export default {
    props: {
        value: {
            type: Boolean,
            required: false,
            default: false
        },
        customerData: {
            type: Object,
            required: false,
            default: null
        },
        sessionData: {
            type: Object,
            required: false,
            default: null
        }
    },
    data() {
        return {
            show: this.value,
            basic_rules: {
                required: value => !!value || "Informação obrigatória"
            },
            show_datepicker_date: false,
            loading: false,
            error_message: null,
            success_message: null,
            show_timepicker: false,
            session_date: null,
            hour_session: null,
            comments: null,
            attended: false,
            session_value: null,
            session_duration: null,
            session_record: false,
            show_confirmation_free_session: false,
            loading_confirmation: false,
            customer_name: null,
            finished: false,
            rescheduled: false
        };
    },
    watch: {
        value() {
            this.show = this.value;
        },
        show() {
            if (!this.show) {
                this.$emit("close");

                this.show_datepicker_date = false;
                this.loading = false;
                this.error_message = null;
                this.success_message = null;
                this.show_timepicker = false;
                this.session_date = null;
                this.hour_session = null;
                this.comments = null;
                this.attended = false;
                this.session_value = null
                this.session_duration = null;
                this.customer_id = null;
                this.session_record = false;
                this.show_confirmation_free_session = false;
                this.loading_confirmation = false;
                this.customer_name = null;
                this.finished = false;
                this.rescheduled = false;
            }

            this.error_message = null;
            this.success_message = null;
            this.$emit("input", this.show);
        },
        sessionData() {
            if (this.sessionData) {
                this.customer_name = this.sessionData.customer ? this.sessionData.customer.name : this.customerData.name;
                this.customer_id = this.sessionData.customer_id;
                this.session_value = this.sessionData.session_value / 100;
                this.session_date = dayjs(this.sessionData.session_date).format('YYYY-MM-DD');
                this.hour_session = dayjs(this.sessionData.session_date).format('HH:mm');
                this.attended = this.sessionData.attended;
                this.comments = this.sessionData.comments;
                this.session_duration = this.sessionData.session_duration;
                this.session_record = this.sessionData.session_record;
                this.finished = this.sessionData.finished;
            }
        },
        attended() {
            if (this.finished) {
                if (!this.attended) {
                    this.session_duration = 0;
                } else {
                    if (this.sessionData) {
                        this.session_duration = this.sessionData.session_duration;
                    }
                }
            }
        }
    },
    methods: {
        ...mapActions("sessions", ["ActionDoUpdateSession", "ActionDoCreateSession"]),
        formatDate(date) {
            return date ? dayjs(date).format("DD/MM/YYYY") : null;
        },
        formatUsa(date) {
            if (date) {
                if (date.length === 8) {
                    return dayjs(date, 'DDMMYYYY').format('YYYY-MM-DD');
                } else if (date.length === 10) {
                    return dayjs(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
                }
            }

            return null;
        },
        close() {
            this.$refs.formSession.reset();
            this.show = false;
        },
        save() {
            this.loading = true;
            this.error_message = null;
            this.success_message = null;

            if (this.$refs.formSession.validate()) {
                let session_value = Number.isInteger(this.session_value) ? this.session_value * 100 : parseInt(this.session_value.toString().replace(/[^0-9]+/g, ""));

                if (session_value > 0) {
                    this.updateSession();
                } else {
                    this.show_confirmation_free_session = true;
                }
            } else {
                this.loading = false;
            }
        },
        updateSession() {
            this.loading_confirmation = true;

            this.ActionDoUpdateSession({
                customer_id: this.sessionData.customer_id,
                session_date: this.rescheduled ? this.sessionData.session_date : this.session_date + ' ' + this.hour_session,
                attended: this.rescheduled ? false : (this.attended === null ? false : this.attended),
                comments: this.comments,
                session_value: this.rescheduled ? 0 : Number.isInteger(this.session_value) ? this.session_value * 100 : parseInt(this.session_value.toString().replace(/[^0-9]+/g, "")),
                session_duration: this.rescheduled ? 0 : this.session_duration,
                session_record: this.rescheduled ? true : this.session_record,
                finished: this.rescheduled ? true : this.finished,
                mounted: this.rescheduled ? true : this.sessionData.mounted,
                rescheduled: this.rescheduled || this.sessionData.rescheduled,
                session_id: this.sessionData.id
            }).then(() => {
                if (this.rescheduled) {
                    this.ActionDoCreateSession({
                        customer_id: this.sessionData.customer_id,
                        session_date: this.session_date + ' ' + this.hour_session,
                        attended: this.attended === null ? false : this.attended,
                        comments: this.comments,
                        session_value: Number.isInteger(this.session_value) ? this.session_value * 100 : parseInt(this.session_value.toString().replace(/[^0-9]+/g, "")),
                        session_duration: this.session_duration,
                        session_record: this.session_record,
                        finished: this.finished,
                        mounted: this.sessionData.mounted
                    });
                }

                this.$store.commit("utils/" + types.UPDATE_REFRESH);
                this.success_message = "Sessão editada com sucesso";
                this.$refs.formSession.reset();
                this.loading = false;
                this.$emit("edited");
                this.show = false;
            }).catch(error => {
                console.error(error);
                this.error_message = error.response.data;
                this.show_confirmation_free_session = false;
                this.loading_confirmation = false;
                this.loading = false;
            });
        },
        closeConfirmation() {
            this.loading = false;
            this.show_confirmation_free_session = false;
        }
    }
};
</script>
