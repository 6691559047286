<template>
    <div>
        <v-app-bar v-if="$vuetify.breakpoint.mobile" app color="primary-gray">
            <v-app-bar-nav-icon aria-label="Menu" @click="drawer = !drawer" />
            <v-spacer />
            <div class="d-flex align-center">
                <v-img
                    alt="Melissa Andrade"
                    contain
                    src="/images/new_logo_blue.png"
                    transition="scale-transition"
                    width="60"
                />
            </div>
            <v-spacer />
        </v-app-bar>
        <v-navigation-drawer
            v-model="drawer"
            app
            :mini-variant="!$vuetify.breakpoint.mobile"
            :expand-on-hover="!$vuetify.breakpoint.mobile"
            color="primary-gray"
        >
            <v-list>
                <v-list-item class="px-2">
                    <v-list-item-avatar height="50">
                        <v-img alt="Melissa Andrade" contain src="/images/new_logo_blue.png" />
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title class="text-h6">Melissa Andrade</v-list-item-title>
                        <v-list-item-subtitle>Gestão & Controle</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item class="px-2" @click="show_dialog_edit_user = true">
                    <v-list-item-avatar size="40">
                        <v-icon v-if="!user.user_image">mdi-account</v-icon>
                        <v-img v-else :src="user.user_image" />
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title>{{ user.name }}</v-list-item-title>
                        <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-list nav dense>
                <v-list-item v-for="(menu, index) in menus" :key="index" link :to="menu.link">
                    <v-list-item-icon>
                        <v-icon>{{ menu.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{ menu.name }}</v-list-item-title>
                </v-list-item>
            </v-list>
            <template #append>
                <v-list-item link @click="logout">
                    <v-list-item-icon>
                        <v-icon>mdi-logout</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Sair</v-list-item-title>
                </v-list-item>
            </template>
        </v-navigation-drawer>
        <dialog-edit-user v-model="show_dialog_edit_user" />
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import DialogEditUser from '../utils/components/DialogEditUser.vue';

export default {
    components: {
        DialogEditUser
    },
    data() {
        return {
            menus: [
                {
                    name: "Agenda",
                    link: "/",
                    icon: "mdi-calendar"
                },
                {
                    name: "Dashboard",
                    link: "/dashboard",
                    icon: "mdi-view-dashboard"
                },
                {
                    name: "Clientes",
                    link: "/customers",
                    icon: "mdi-account-group-outline"
                },
                {
                    name: "Sessões",
                    link: "/sessions",
                    icon: "mdi-brain"
                },
                {
                    name: "Recibos",
                    link: "/vouchers",
                    icon: "mdi-file-sign"
                },
                {
                    name: "Despesas programadas",
                    link: "/taxes",
                    icon: "mdi-hand-coin-outline"
                }
            ],
            drawer: undefined,
            show_dialog_edit_user: false
        };
    },
    computed: {
        ...mapState("auth", {
            user: state => state.user
        })
    },
    mounted() {
        this.checkTodayEvents();
    },
    methods: {
        ...mapActions("auth", ["ActionDoLogout"]),
        ...mapActions("schedule", ["ActionDoGetTodayEvents"]),
        async logout() {
            await this.ActionDoLogout();
        },
        checkTodayEvents() {
            this.ActionDoGetTodayEvents();
        }
    }
}
</script>
