import services from "../../../http/index";
import * as types from "./mutations-types";

export const ActionDoGetExtracts = ({ commit }) => {
    return services.api.request({
        url: "extracts",
        method: "get"
    }).then(response => {
        commit(types.SET_EXTRACT_LIST, response.data);
        return response.data;
    });
};

export const ActionDoCreateExtract = (context, payload) => {
    return services.api.request({
        url: "extracts",
        method: "post",
        data: payload
    }).then(response => {
        return response.data;
    });
};

export const ActionDoUpdateExtract = (context, payload) => {
    return services.api.request({
        url: `extracts/${payload.extract_id}`,
        method: "put",
        data: payload
    }).then(response => {
        return response.data;
    });
};

export const ActionDoDeleteExtract = (context, extract_id) => {
    return services.api.request({
        url: `extracts/${extract_id}`,
        method: "delete"
    }).then(response => {
        return response.data;
    });
};
