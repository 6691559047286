import services from "../../../http/index";
import * as types from "./mutations-types";
import router from "../../../router";

export const ActionDoLogin = ({ commit }, payload) => {
    return services.api.request({
        url: "auth/login",
        method: "post",
        data: payload
    }).then(response_data => {
        let response = response_data.data;

        if (response.error === "" && response.token) {
            commit(types.SET_USER, response.data);
            commit(types.SET_TOKEN, response.token);
        }

        return response;
    });
};

export const ActionDoLogout = ({ commit }) => {
    return services.api.request({
        url: "auth/logout",
        method: "post"
    }).then(() => {
        commit(types.SET_USER, null);
        commit(types.SET_TOKEN, null);

        router.push({ name: "auth" });
    });
};
