<template>
    <v-row no-gutters>
        <v-col cols="12">
            <v-row align="start">
                <v-col cols="12">
                    <v-card class="pa-8" rounded="lg" color="transparent" elevation="0" height="100%">
                        <the-graphics />
                    </v-card>
                </v-col>
            
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import TheGraphics from './components/TheGraphics.vue';
import { mapState } from 'vuex';
import dayjs from 'dayjs';

export default {
    components: {
        TheGraphics
    },
    data() {
        return {};
    },
    computed: {
        ...mapState("schedule", {
            alerts: state => state.alerts_today
        })
    },
    methods: {
        getAge(birthday) {
            return dayjs().diff(dayjs(birthday), 'year');
        }
    }
};
</script>
