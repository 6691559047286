<template>
    <v-card id="create">
        <v-speed-dial
            v-model="fab"
            :top="false"
            :bottom="true"
            :right="true"
            :left="false"
            direction="top"
            :open-on-hover="false"
            transition="slide-y-reverse-transition"
        >
            <template v-slot:activator>
                <v-btn v-model="fab" color="secondary-pink" dark fab>
                    <v-icon v-if="fab">mdi-close</v-icon>
                    <v-icon v-else>mdi-plus</v-icon>
                </v-btn>
            </template>
            <v-tooltip left color="secondary-purple">
                <template #activator="{ attrs, on }">
                    <v-btn fab dark small color="secondary-purple" @click="show_add_session = true" v-bind="attrs" v-on="on">
                        <v-icon>mdi-clock-plus-outline</v-icon>
                    </v-btn>
                </template>
                Agendar nova sessão
            </v-tooltip>
            <v-tooltip left color="secondary-green">
                <template #activator="{ attrs, on }">
                    <v-btn fab dark small @click="show_add_extract = true" color="secondary-green" v-bind="attrs" v-on="on">
                        <v-icon>mdi-cash-register</v-icon>
                    </v-btn>
                </template>
                Adicionar registro financeiro
            </v-tooltip>
            <v-tooltip left color="primary-blue">
                <template #activator="{ attrs, on }">
                    <v-btn fab dark small color="primary-blue" @click="show_add_customer = true" v-bind="attrs" v-on="on">
                        <v-icon>mdi-account-plus</v-icon>
                    </v-btn>
                </template>
                Adicionar cliente
            </v-tooltip>
            <v-tooltip left color="tertiary-red">
                <template #activator="{ attrs, on }">
                    <v-btn fab dark small @click="show_add_taxe = true" color="tertiary-red" v-bind="attrs" v-on="on">
                        <v-icon>mdi-cash-clock</v-icon>
                    </v-btn>
                </template>
                Adicionar despesa programada
            </v-tooltip>
        </v-speed-dial>
        <dialog-add-session v-model="show_add_session" @created="notify('session')"/>
        <dialog-add-customer v-model="show_add_customer" @created="notify('customer')" />
        <dialog-add-taxe v-model="show_add_taxe" @created="notify('taxe')" />
        <dialog-add-extract v-model="show_add_extract" @created="notify('extract')" />
        <v-layout align-end justify-end>
            <v-snackbar
                v-model="show_notify"
                :timeout="5000"
                top
                right
                :color="notify_color"
                text
                elevation="24"
                rounded="pill"
            >
                <v-icon left x-large>{{ notify_icon }}</v-icon><span v-html="notify_label" />
                <template v-slot:action="{ attrs }">
                    <v-btn :color="notify_color" icon v-bind="attrs" @click="show_notify = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </template>
            </v-snackbar>
        </v-layout>
    </v-card>
</template>

<script>
import DialogAddSession from "../views/sessions/components/DialogAddSession.vue";
import DialogAddCustomer from "../views/customers/components/DialogAddCustomer.vue";
import DialogAddTaxe from "../views/taxes/components/DialogAddTaxe.vue";
import DialogAddExtract from "../views/extract/components/DialogAddExtract.vue";
import { mapActions } from 'vuex';

export default {
    components: {
        DialogAddSession,
        DialogAddCustomer,
        DialogAddTaxe,
        DialogAddExtract
    },
    data() {
        return {
            fab: false,
            show_add_session: false,
            show_add_customer: false,
            show_add_taxe: false,
            show_add_extract: false,
            show_notify: false,
            notify_color: "primary-blue",
            notify_icon: "mdi-information-outline",
            notify_label: ""
        };
    },
    methods: {
        ...mapActions("schedule", ["ActionDoGetCalendarEvents"]),
        notify(type) {
            this.ActionDoGetCalendarEvents();

            switch (type) {
                case "customer":
                    this.notify_color = "purple";
                    this.notify_icon = "mdi-account-plus";
                    this.notify_label = "Cliente cadastrado com sucesso";
                    this.show_notify = true;
                    break;
                case "session":
                    this.notify_color = "secondary-blue darken-1";
                    this.notify_icon = "mdi-head-plus";
                    this.notify_label = "Sessão adicionada com sucesso";
                    this.show_notify = true;
                    break;
                case "taxe":
                    this.notify_color = "teal";
                    this.notify_icon = "mdi-cash-clock";
                    this.notify_label = "Despesa programada adicionada com sucesso";
                    this.show_notify = true;
                    break;
                case "extract":
                    this.notify_color = "orange";
                    this.notify_icon = "mdi-cash-register";
                    this.notify_label = "Registro financeiro adicionado com sucesso";
                    this.show_notify = true;
                    break;
            }
        }
    }
}
</script>

<style>
  /* This is for documentation purposes and will not be needed in your application */
  #create .v-speed-dial {
    position: fixed;
  }

  #create .v-btn--floating {
    position: relative;
  }
</style>