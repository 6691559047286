<template>
    <v-card class="pa-8" rounded="lg" color="primary-gray">
        <v-row no-gutters>
            <v-col cols="12">
                <v-row align="center">
                    <v-col cols="6">
                        <h2>Sessões</h2>
                    </v-col>
                    <v-col cols="6" class="d-flex justify-end">
                        <v-btn elevation="0" color="primary-blue" dark @click="show_add_session = true">Agendar sessão <v-icon right>mdi-clock-plus-outline</v-icon></v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-row align="center">
                            <v-col cols="auto">
                                <v-btn text @click="show_filter = !show_filter">
                                    <v-icon left>mdi-filter</v-icon> Filtros <v-icon right>{{ show_filter ? 'mdi-menu-up' : 'mdi-menu-down' }}</v-icon>
                                </v-btn>
                            </v-col>
                            <v-col>
                                <v-divider />
                            </v-col>
                        </v-row>
                        <v-slide-y-transition>
                            <v-row v-if="show_filter" justify="center">
                                <v-col cols="12" md="9">
                                    <v-row>
                                        <v-col cols="6" md="3">
                                            <v-menu
                                                ref="startDate"
                                                v-model="show_datepicker_start"
                                                :close-on-content-click="false"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="auto"
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                        :value="formatDate(start_date)"
                                                        label="Data de início"
                                                        append-icon="mdi-calendar"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        @input="$event => start_date = formatUsa($event)"
                                                        dense
                                                        clearable
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker
                                                    v-model="start_date"
                                                    no-title
                                                    scrollable
                                                    editable
                                                    @input="show_datepicker_start = false"
                                                />
                                            </v-menu>
                                        </v-col>
                                        <v-col cols="6" md="3">
                                            <v-menu
                                                ref="endDate"
                                                v-model="show_datepicker_end"
                                                :close-on-content-click="false"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="auto"
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                        :value="formatDate(end_date)"
                                                        label="Data de início"
                                                        append-icon="mdi-calendar"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        @input="$event => end_date = formatUsa($event)"
                                                        dense
                                                        clearable
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker
                                                    v-model="end_date"
                                                    no-title
                                                    scrollable
                                                    editable
                                                    @input="show_datepicker_end = false"
                                                />
                                            </v-menu>
                                        </v-col>
                                        <v-col cols="6" md="3">
                                            <v-select label="Sessão finalizada" v-model="finished" dense :items="boolean_items" clearable />
                                        </v-col>
                                        <v-col cols="6" md="3">
                                            <v-select label="Cliente compareceu" v-model="attended" dense :items="boolean_items" clearable />
                                        </v-col>
                                        <v-col cols="6" md="3">
                                            <v-select label="Prontuário finalizado" v-model="session_record" :items="boolean_items" clearable />
                                        </v-col>
                                        <v-col cols="6" md="3">
                                            <v-autocomplete
                                                v-model="customer"
                                                :items="customers_list"
                                                label="Cliente"
                                            />
                                        </v-col>
                                        <v-col cols="6" md="3">
                                            <v-text-field label="Duração" v-model="duration" />
                                        </v-col>
                                        <v-col cols="6" md="3">
                                            <v-text-field-money
                                                v-model="session_value"
                                                label="Valor da sessão"
                                                :properties="{
                                                    prefix: 'R$',
                                                    clearable: true
                                                }"
                                                :options="{
                                                    locale: 'pt-BR',
                                                    length: 11,
                                                    precision: 2,
                                                    empty: null
                                                }"
                                            />
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-slide-y-transition>
                        <v-slide-y-transition>
                            <v-row v-if="show_filter" align="center">
                                <v-col>
                                    <v-divider />
                                </v-col>
                                <v-col cols="auto">
                                    <v-btn color="secondary-blue" class="white--text" @click="getSessions()">
                                        Buscar <v-icon right>mdi-filter-check-outline</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-slide-y-transition>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12">
                <v-data-table
                    :headers="headers"
                    :items="session_list"
                    class="primary-gray"
                    :loading="loading"
                    sort-by="session_date"
                    sort-desc
                    :options.sync="options"
                    :server-items-length="total_items"
                    :page.sync="page"
                >
                    <template v-slot:[`item.session_date`]="{ item }">
                        {{ formatDate(item.session_date, true) }}
                    </template>
                    <template v-slot:[`item.session_duration`]="{ item }">
                        {{ item.session_duration }} minutos
                    </template>
                    <template v-slot:[`item.status`]="{ item }">
                        <span v-if="item.rescheduled">Reagendada <v-icon right color="blue">mdi-calendar-refresh-outline</v-icon></span>
                        <span v-else-if="item.finished">Finalizada <v-icon right color="success darken-4">mdi-check-circle-outline</v-icon></span>
                        <span v-else>Agendada <v-icon right color="tertiary">mdi-clock-outline</v-icon></span>
                    </template>
                    <template v-slot:[`item.attended`]="{ item }">
                        <v-icon v-if="!item.finished || item.rescheduled" color="gray">mdi-minus</v-icon>
                        <v-icon v-else-if="item.attended" color="success darken-4">mdi-check-bold</v-icon>
                        <v-icon v-else color="error darken-4">mdi-close-thick</v-icon>
                    </template>
                    <template v-slot:[`item.session_record`]="{ item }">
                        <v-icon v-if="!item.finished || item.rescheduled" color="gray">mdi-minus</v-icon>
                        <v-icon v-else-if="item.session_record" color="success darken-4">mdi-check-bold</v-icon>
                        <v-icon v-else color="error darken-4">mdi-close-thick</v-icon>
                    </template>
                    <template v-slot:[`item.session_value`]="{ item }">
                        {{ (item.session_value / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-row no-gutters class="flex-nowrap">
                            <v-col cols="auto" class="px-1">
                                <v-btn icon @click="openEditSession(item)" color="primary-blue">
                                    <v-icon>
                                        mdi-eye-outline
                                    </v-icon>
                                </v-btn>
                            </v-col>
                            <v-col cols="auto" class="px-1">
                                <v-btn icon @click="openDeleteSession(item)" color="error darken-4">
                                    <v-icon>
                                        mdi-delete-empty-outline
                                    </v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <dialog-add-session v-model="show_add_session" @close="show_add_session = false" @created="getSessions"/>
        <dialog-edit-session :customer-data="show_edit_session ? session_data.customer : null" :session-data="session_data" v-model="show_edit_session" @close="closeEditSession" @edited="getSessions" />
        <dialog-delete-session :customer-data="show_delete_session ? session_data.customer : null" :session-data="session_data" v-model="show_delete_session" @close="closeDeleteSession" @deleted="getSessions" />
    </v-card>
</template>

<script>
import dayjs from 'dayjs';
import DialogEditSession from './components/DialogEditSession.vue';
import DialogDeleteSession from './components/DialogDeleteSession.vue';
import DialogAddSession from './components/DialogAddSession.vue';
import { mapActions, mapState } from 'vuex';

export default {
    components: {
        DialogEditSession,
        DialogDeleteSession,
        DialogAddSession
    },
    data() {
        return {
            headers: [
                { text: "Cliente", value: "customer.name", sortable: false },
                { text: "Data", value: "session_date", sortable: true },
                { text: "Duração", value: "session_duration", sortable: true },
                { text: "Status", value: "status", sortable: false },
                { text: "Compareceu", value: "attended", sortable: true },
                { text: "Prontuário", value: "session_record", sortable: true },
                { text: "Valor", value: "session_value", sortable: true },
                { text: "Comentários", value: "comments", sortable: true },
                { text: '', value: 'actions', sortable: false },
            ],
            loading: false,
            session_data: null,
            show_add_session: false,
            show_edit_session: false,
            show_delete_session: false,
            show_filter: false,
            show_datepicker_start: false,
            show_datepicker_end: false,
            start_date: dayjs().startOf('month').format('YYYY-MM-DD'),
            end_date: dayjs().format('YYYY-MM-DD'),
            customer: null,
            duration: null,
            session_value: null,
            options: {},
            total_items: 0,
            session_list: [],
            page: 1,
            boolean_items: [
                { value: true, text: "Sim" },
                { value: false, text: "Não" }
            ],
            finished: null,
            attended: null,
            session_record: null
        };
    },
    computed: {
        ...mapState("customers", {
            customers_list: state => state.customers_list?.map(customer => {
                return { text: customer.name, value: customer };
            })
        })
    },
    watch: {
        options() {
            this.getSessions();
        }
    },
    mounted() {
        this.getSessions();
    },
    methods: {
        ...mapActions("sessions", ["ActionDoGetSessions"]),
        getSessions() {
            this.loading = true;
            this.ActionDoGetSessions({
                start_date: this.start_date + ' 00:00:00',
                end_date: this.end_date + ' 23:59:59',
                customer_id: this.customer?.id ?? null,
                duration: this.duration,
                session_value: this.session_value ? (typeof this.session_value === 'number' ? this.session_value * 100 : parseInt(this.session_value.toString().replace(/[^0-9]+/g, ""))) : null,
                finished: this.finished,
                attended: this.attended,
                session_record: this.session_record,
                ...this.makeDataTableParams()
            }).then((response) => {
                this.session_list = response.data;
                this.total_items = response.recordsTotal;

                if (this.session_data) {
                    this.session_data = this.session_list.find((session) => session.id === this.session_data.id);
                }
                this.loading = false;
            });
        },
        formatDate(date, hour = false) {
            return dayjs(date).format('DD/MM/YYYY' + (hour ? ' HH:mm' : ''));
        },
        formatUsa(date) {
            if (date) {
                if (date.length === 8) {
                    return dayjs(date, 'DDMMYYYY').format('YYYY-MM-DD');
                } else if (date.length === 10) {
                    return dayjs(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
                }
            }

            return null;
        },
        closeEditSession() {
            this.show_edit_session = false;
            this.session_data = null;
        },
        closeDeleteSession() {
            this.show_delete_session = false;
            this.session_data = null;
        },
        openEditSession(session) {
            this.session_data = session;
            this.show_edit_session = true;
        },
        openDeleteSession(session) {
            this.session_data = session;
            this.show_delete_session = true;
        },
        makeDataTableParams() {
            if (this.options) {
                let config = {
                    order: [],
                    columns: [],
                    start: (this.options.page * this.options.itemsPerPage) - this.options.itemsPerPage,
                    length: this.options.itemsPerPage,
                    page: this.options.page
                };

                if (this.options.sortBy && this.options.sortBy.length > 0) {
                    for (let index in this.options.sortBy) {
                        if (!isNaN(index)) {
                            config.order.push({
                                column: index,
                                dir: this.options.sortDesc[index] ? "DESC" : "ASC"
                            });

                            config.columns.push({
                                data: this.options.sortBy[index],
                                name: "",
                                searchable: true,
                                orderable: true,
                                search: {
                                    value: "",
                                    regex: ""
                                }
                            });
                        } else {
                            config.columns.push({
                                data: "",
                                name: "",
                                searchable: true,
                                orderable: true,
                                search: {
                                    value: "",
                                    regex: ""
                                }
                            });
                        }
                    }
                }

                return config;
            } else {
                return {};
            }
        }
    }
};
</script>
