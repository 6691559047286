<template>
    <v-card class="pa-8" rounded="lg" color="primary-gray">
        <v-row>
            <v-col cols="12">
                <v-row align="center">
                    <v-col cols="6">
                        <h2>Clientes</h2>
                    </v-col>
                    <v-col cols="6" class="d-flex justify-end">
                        <v-btn elevation="0" color="primary-blue" dark @click="show_add = true">Novo cliente <v-icon right>mdi-account-plus</v-icon></v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-row align="center">
                            <v-col cols="auto">
                                <v-btn text @click="show_filter = !show_filter">
                                    <v-icon left>mdi-filter</v-icon> Filtros <v-icon right>{{ show_filter ? 'mdi-menu-up' : 'mdi-menu-down' }}</v-icon>
                                </v-btn>
                            </v-col>
                            <v-col>
                                <v-divider />
                            </v-col>
                        </v-row>
                        <v-slide-y-transition>
                            <v-row v-if="show_filter" justify="center">
                                <v-col cols="12" md="9">
                                    <v-row>
                                        <v-col cols="6" md="3">
                                            <v-text-field label="Nome" v-model="name" dense clearable></v-text-field>
                                        </v-col>
                                        <v-col cols="6" md="3">
                                            <v-text-field label="CPF" v-model="cpf" dense clearable></v-text-field>
                                        </v-col>
                                        <v-col cols="6" md="3">
                                            <v-text-field label="Email" v-model="email" dense clearable></v-text-field>
                                        </v-col>
                                        <v-col cols="6" md="3">
                                            <v-text-field label="Telefone" v-model="phone" dense clearable></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="6" md="3">
                                            <v-text-field label="RG" v-model="rg" dense clearable></v-text-field>
                                        </v-col>
                                        <v-col cols="6" md="3">
                                            <v-select label="Mês de Aniversário" v-model="birthday_month" dense :items="months" clearable multiple></v-select>
                                        </v-col>
                                        <v-col cols="6" md="3">
                                            <v-select label="Tipo de pagamento" v-model="payment_type" dense :items="frequency_type_items" clearable></v-select>
                                        </v-col>
                                        <v-col cols="6" md="3">
                                            <v-select label="Status do cliente" v-model="status" dense :items="status_items" clearable multiple></v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="6" md="3">
                                            <v-select label="Frequencia de pagamento" v-model="payment_frequency" dense :items="frequency_type_items" clearable></v-select>
                                        </v-col>
                                        <v-col cols="6" md="3">
                                            <v-select label="Frequencia da sessão" v-model="session_frequency" dense :items="frequency_type_items" clearable></v-select>
                                        </v-col>
                                        <v-col cols="6" md="3">
                                            <v-select label="Contrato assinado" v-model="signed_contract" dense :items="boolean_items" clearable></v-select>
                                        </v-col>
                                        <v-col cols="6" md="3">
                                            <v-select label="Tem saldo" v-model="balance" dense :items="boolean_items" clearable></v-select>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-slide-y-transition>
                        <v-slide-y-transition>
                            <v-row v-if="show_filter" align="center">
                                <v-col>
                                    <v-divider />
                                </v-col>
                                <v-col cols="auto">
                                    <v-btn color="secondary-blue" class="white--text" @click="getCustomers()">
                                        Buscar <v-icon right>mdi-filter-check-outline</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-slide-y-transition>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-row align="center">
                            <v-col cols="auto">
                                <v-btn text @click="show_columns = !show_columns">
                                    <v-icon left>mdi-view-column</v-icon> Colunas da tabela <v-icon right>{{ show_columns ? 'mdi-menu-up' : 'mdi-menu-down' }}</v-icon>
                                </v-btn>
                            </v-col>
                            <v-col>
                                <v-divider />
                            </v-col>
                        </v-row>
                        <v-slide-y-transition>
                            <v-row v-if="show_columns" justify="center">
                                <v-col cols="12">
                                    <v-row justify="center">
                                        <v-col v-for="(column, index) in table_columns" :key="index" cols="auto">
                                            <v-btn small outlined :color="column.show ? 'primary-blue' : 'primary-pink'" :elevation="column.show ? undefined : 0" class="white--text" @click="toggleColumn(column)">{{ column.name }}</v-btn>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-slide-y-transition>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12">
                <v-data-table
                    :headers="headers"
                    :items="customers_list"
                    class="primary-gray"
                    :loading="loading"
                    sort-by="name"
                    item-key="id"
                    :search="search"
                    single-expand
                    show-expand
                    :items-per-page="-1"
                >
                    <template v-slot:[`item.status`]="{ item }">
                        <v-chip small label :color="status_config.color[item.status]"><v-icon left small>{{ status_config.icon[item.status] }}</v-icon> {{ status_config.description[item.status] }}</v-chip>
                    </template>
                    <template v-slot:[`item.week_day_session`]="{ item }">
                        {{ week_day_description[item.week_day_session] }}
                    </template>
                    <template v-slot:[`item.session_duration`]="{ item }">
                        {{ item.session_duration + ' min' }}
                    </template>
                    <template v-slot:[`item.next_session`]="{ item }">
                        {{ formatDate(item.last_scheduled_session?.session_date) }}
                    </template>
                    <template v-slot:[`item.session_frequency`]="{ item }">
                        <v-chip small :color="frequency_config.color[item.session_frequency]" class="white--text"><v-icon left small>{{ frequency_config.icon[item.session_frequency] }}</v-icon> {{ frequency_config.description[item.session_frequency] }}</v-chip>
                    </template>
                    <template v-slot:[`item.cpf`]="{ item }">
                        {{ item.cpf?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4") }}
                    </template>
                    <template v-slot:[`item.email`]="{ item }">
                        <a :href="'mailto:' + item.email" target="_blank">{{ item.email }}</a>
                    </template>
                    <template v-slot:[`item.phone`]="{ item }">
                        <a :href="'https://wa.me/+' + item.phone" target="_blank">{{ item.phone?.replace(/(\d{2})(\d{2})(\d{5})(\d{4})/, "+$1 ($2) $3-$4") }}</a>
                    </template>
                    <template v-slot:[`item.birthday`]="{ item }">
                        {{ formatDate(item.birthday) }}
                    </template>
                    <template v-slot:[`item.balance`]="{ item }">
                        <span :class="item.balance === 0 ? 'error--text' : ''">{{ (item.balance / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</span>
                    </template>
                    <template v-slot:[`item.payment_frequency`]="{ item }">
                        <v-chip small :color="frequency_config.color[item.payment_frequency]" class="white--text"><v-icon left small>{{ frequency_config.icon[item.payment_frequency] }}</v-icon> {{ frequency_config.description[item.payment_frequency] }}</v-chip>
                    </template>
                    <template v-slot:[`item.ammount`]="{ item }">
                        {{ (item.ammount / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}
                    </template>
                    <template v-slot:[`item.payday`]="{ item }">
                        {{ formatDate(item.payday) }}
                    </template>
                    <template v-slot:[`item.payment_type`]="{ item }">
                        <v-chip small :color="frequency_config.color[item.payment_type]" class="white--text"><v-icon left small>{{ frequency_config.icon[item.payment_type] }}</v-icon> {{ frequency_config.description[item.payment_type] }}</v-chip>
                    </template>
                    <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length" class="primary-gray pa-4">
                            <v-card class="pa-4">
                                <v-row>
                                    <v-col cols="12" md="">
                                        <v-row align="center">
                                            <v-col><v-divider /></v-col>
                                            <v-col cols="auto" class="font-weight-bold">Cliente</v-col>
                                            <v-col><v-divider /></v-col>
                                        </v-row>
                                        <v-row v-if="!table_columns.name.show && item.name">
                                            <v-col class="font-weight-bold">Nome</v-col>
                                            <v-col>{{ item.name }}</v-col>
                                        </v-row>
                                        <v-row v-if="!table_columns.status.show && item.status">
                                            <v-col class="font-weight-bold">Status</v-col>
                                            <v-col>
                                                <v-chip small label :color="status_config.color[item.status]"><v-icon left small>{{ status_config.icon[item.status] }}</v-icon> {{ status_config.description[item.status] }}</v-chip>
                                            </v-col>
                                        </v-row>
                                        <v-row v-if="!table_columns.week_day_session.show && item.week_day_session">
                                            <v-col class="font-weight-bold">Dia da sessão</v-col>
                                            <v-col>{{ week_day_description[item.week_day_session] }}</v-col>
                                        </v-row>
                                        <v-row v-if="!table_columns.hour_session.show && item.hour_session">
                                            <v-col class="font-weight-bold">Hora da sessão</v-col>
                                            <v-col>{{ item.hour_session }}</v-col>
                                        </v-row>
                                        <v-row v-if="!table_columns.session_duration.show && item.session_duration">
                                            <v-col class="font-weight-bold">Duração</v-col>
                                            <v-col>{{ item.session_duration + ' min' }}</v-col>
                                        </v-row>
                                        <v-row v-if="!table_columns.next_session.show && item.next_session">
                                            <v-col class="font-weight-bold">Próx. sessão</v-col>
                                            <v-col>{{ formatDate(item.next_session) }}</v-col>
                                        </v-row>
                                        <v-row v-if="!table_columns.session_frequency.show && item.session_frequency">
                                            <v-col class="font-weight-bold">Frequência da sessão</v-col>
                                            <v-col>
                                                <v-chip small :color="frequency_config.color[item.session_frequency]" class="white--text"><v-icon left small>{{ frequency_config.icon[item.session_frequency] }}</v-icon> {{ frequency_config.description[item.session_frequency] }}</v-chip>
                                            </v-col>
                                        </v-row>
                                        <v-row v-if="!table_columns.cpf.show && item.cpf">
                                            <v-col class="font-weight-bold">CPF</v-col>
                                            <v-col>{{ item.cpf?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4") }}</v-col>
                                        </v-row>
                                        <v-row v-if="!table_columns.rg.show && item.rg">
                                            <v-col class="font-weight-bold">RG</v-col>
                                            <v-col>{{ item.rg }}</v-col>
                                        </v-row>
                                        <v-row v-if="!table_columns.email.show && item.email">
                                            <v-col class="font-weight-bold">Email</v-col>
                                            <v-col>
                                                <a :href="'mailto:' + item.email" target="_blank">{{ item.email }}</a>
                                            </v-col>
                                        </v-row>
                                        <v-row v-if="!table_columns.phone.show && item.phone">
                                            <v-col class="font-weight-bold">Telefone</v-col>
                                            <v-col>
                                                <a :href="'https://wa.me/+' + item.phone" target="_blank">{{ item.phone?.replace(/(\d{2})(\d{2})(\d{5})(\d{4})/, "+$1 ($2) $3-$4") }}</a>
                                            </v-col>
                                        </v-row>
                                        <v-row v-if="!table_columns.birthday.show && item.birthday">
                                            <v-col class="font-weight-bold">Data de nascimento</v-col>
                                            <v-col>{{ formatDate(item.birthday) }}</v-col>
                                        </v-row>
                                        <v-row align=center>
                                            <v-col class="font-weight-bold">Contrato</v-col>
                                            <v-col>
                                                <v-row align="center">
                                                    <v-col cols="auto">
                                                        <span v-if="item.signed_contract">Assinado <v-icon color="success darken-4">mdi-check-circle</v-icon></span>
                                                        <span v-else >Não assinado <v-icon color="error darken-4">mdi-close-circle</v-icon></span>
                                                    </v-col>
                                                    <v-col>
                                                        <v-btn v-if="!item.contract" :loading="item.loading_contract" small color="secondary-blue" dark elevation="0" @click="generateContract(item)">
                                                            Gerar contrato <v-icon right>mdi-hammer-wrench</v-icon>
                                                        </v-btn>
                                                        <v-btn v-else :loading="item.loading_contract" :disabled="item.loading_contract" small color="blue" elevation="0" @click="generatePDF(item)">
                                                            <span class="white--text">Baixar <v-icon right>mdi-download</v-icon></span>
                                                        </v-btn>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                        <v-row align="center">
                                            <v-col class="font-weight-bold">Sessões</v-col>
                                            <v-col>
                                                <v-row>
                                                    <v-col cols="12">
                                                        <v-card v-if="item.sessions_attended_count + item.sessions_missed_count + item.sessions_rescheduled_count" elevation="0" height="40" class="rounded-lg d-flex nowrap overflow-hidden">
                                                            <v-tooltip top color="primary-pink darken-1 darken-2">
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-card
                                                                        elevation="0"
                                                                        class="primary-pink darken-1 rounded-0 d-flex align-center justify-center"
                                                                        dark
                                                                        height="100%"
                                                                        :width="((item.sessions_attended_count / (item.sessions_attended_count + item.sessions_missed_count + item.sessions_rescheduled_count )) * 100) + '%'"
                                                                        v-bind="attrs"
                                                                        v-on="on"
                                                                    >
                                                                        {{ Math.round((item.sessions_attended_count / (item.sessions_attended_count + item.sessions_missed_count + item.sessions_rescheduled_count)) * 100) }}%
                                                                    </v-card>
                                                                </template>
                                                                Compareceu em {{ item.sessions_attended_count }} sessões
                                                            </v-tooltip>
                                                            <v-tooltip top color="secondary-red darken-2">
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-card
                                                                        elevation="0"
                                                                        class="secondary-red rounded-0 d-flex align-center justify-center"
                                                                        dark height="100%"
                                                                        :width="((item.sessions_missed_count / (item.sessions_attended_count + item.sessions_missed_count + item.sessions_rescheduled_count)) * 100) + '%'"
                                                                        v-bind="attrs"
                                                                        v-on="on"
                                                                    >
                                                                        <span v-if="item.sessions_missed_count">{{ Math.round((item.sessions_missed_count / (item.sessions_attended_count + item.sessions_missed_count + item.sessions_rescheduled_count)) * 100) }}%</span>
                                                                    </v-card>
                                                                </template>
                                                                Faltou em {{ item.sessions_missed_count }} sessões
                                                            </v-tooltip>
                                                            <v-tooltip top color="secondary-yellow darken-1">
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-card
                                                                        elevation="0"
                                                                        class="secondary-yellow darken-1 rounded-0 d-flex align-center justify-center"
                                                                        dark height="100%"
                                                                        :width="((item.sessions_rescheduled_count / (item.sessions_attended_count + item.sessions_missed_count + item.sessions_rescheduled_count)) * 100) + '%'"
                                                                        v-bind="attrs"
                                                                        v-on="on"
                                                                    >
                                                                        <span v-if="item.sessions_rescheduled_count">{{ Math.round((item.sessions_rescheduled_count / (item.sessions_attended_count + item.sessions_missed_count + item.sessions_rescheduled_count)) * 100) }}%</span>
                                                                    </v-card>
                                                                </template>
                                                                Reagendou {{ item.sessions_rescheduled_count }} sessões
                                                            </v-tooltip>
                                                        </v-card>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                        <v-row v-if="!table_columns.balance.show">
                                            <v-col class="font-weight-bold">Saldo do cliente</v-col>
                                            <v-col>
                                                <span :class="item.balance === 0 ? 'error--text' : ''">{{ (item.balance / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</span>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col v-if="item.payment_frequency || item.ammount || item.payday || item.payment_type || item.payer_name" cols="auto" class="d-none d-md-block">
                                        <v-divider vertical />
                                    </v-col>
                                    <v-col v-if="item.payment_frequency || item.ammount || item.payday || item.payment_type || item.payer_name" cols="12" md="">
                                        <v-row align="center">
                                            <v-col><v-divider /></v-col>
                                            <v-col cols="auto" class="font-weight-bold">Pagamento</v-col>
                                            <v-col><v-divider /></v-col>
                                        </v-row>
                                        <v-row v-if="!table_columns.payment_frequency.show && item.payment_frequency">
                                            <v-col class="font-weight-bold">Frequência de pagamento</v-col>
                                            <v-col>
                                                <v-chip small :color="frequency_config.color[item.payment_frequency]" class="white--text"><v-icon left small>{{ frequency_config.icon[item.payment_frequency] }}</v-icon> {{ frequency_config.description[item.payment_frequency] }}</v-chip>
                                            </v-col>
                                        </v-row>
                                        <v-row v-if="!table_columns.ammount.show && item.ammount">
                                            <v-col class="font-weight-bold">Valor a ser pago</v-col>
                                            <v-col>
                                                {{ (item.ammount / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}
                                            </v-col>
                                        </v-row>
                                        <v-row v-if="!table_columns.payday.show && item.payday">
                                            <v-col class="font-weight-bold">Data do próx. pagamento</v-col>
                                            <v-col>
                                                {{ formatDate(item.payday) }}
                                            </v-col>
                                        </v-row>
                                        <v-row v-if="!table_columns.payment_type.show && item.payment_type">
                                            <v-col class="font-weight-bold">Tipo de pagamento</v-col>
                                            <v-col>
                                                <v-chip small :color="frequency_config.color[item.payment_type]" class="white--text"><v-icon left small>{{ frequency_config.icon[item.payment_type] }}</v-icon> {{ frequency_config.description[item.payment_type] }}</v-chip>
                                            </v-col>
                                        </v-row>
                                        <v-row v-if="item.payer_name">
                                            <v-col class="font-weight-bold">Nome do pagador</v-col>
                                            <v-col>
                                                {{ item.payer_name }}
                                            </v-col>
                                        </v-row>
                                        <v-row v-if="item.payer_cpf">
                                            <v-col class="font-weight-bold">CPF do pagador</v-col>
                                            <v-col>
                                                {{ item.payer_cpf?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4") }}
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="auto" class="d-none d-md-block">
                                        <v-divider vertical />
                                    </v-col>
                                    <v-col v-if="item.comments" cols="12" md="">
                                        <v-row align="center">
                                            <v-col><v-divider /></v-col>
                                            <v-col cols="auto" class="font-weight-bold">Observações</v-col>
                                            <v-col><v-divider /></v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12">
                                                <span class="text-pre">
                                                    {{ item.comments }}
                                                </span>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </td>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-row no-gutters class="flex-nowrap">
                            <v-col cols="auto" class="px-1">
                                <v-tooltip top color="orange">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon @click="showEmergencyContacts(item)" color="orange" v-bind="attrs" v-on="on">
                                            <v-icon>
                                                mdi-human-greeting-proximity
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    Contatos de emergência
                                </v-tooltip>
                            </v-col>
                            <v-col cols="auto" class="px-1">
                                <v-tooltip top color="primary-blue">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon @click="showSessions(item)" color="primary-blue" v-bind="attrs" v-on="on">
                                            <v-icon>
                                                mdi-list-box-outline
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    Lista de sessões
                                </v-tooltip>
                            </v-col>
                            <v-col cols="auto" class="px-1">
                                <v-tooltip top color="secondary-blue">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon @click="showPayments(item)" color="secondary-blue" v-bind="attrs" v-on="on">
                                            <v-icon>
                                                mdi-list-box-outline
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    Lista de pagamentos
                                </v-tooltip>
                            </v-col>
                            <v-col cols="auto" class="px-1">
                                <v-tooltip top color="blue">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon @click="edit(item)" color="blue" v-bind="attrs" v-on="on">
                                            <v-icon>
                                                mdi-account-edit
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    Editar
                                </v-tooltip>
                            </v-col>
                            <v-col cols="auto" class="px-1">
                                <v-tooltip top color="error darken-4">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon @click="disable(item)" color="error darken-4" v-bind="attrs" v-on="on">
                                            <v-icon>
                                                mdi-account-remove
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    Deletar
                                </v-tooltip>
                            </v-col>
                        </v-row>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <dialog-add-customer v-model="show_add" @created="getCustomers" />
        <dialog-edit-customer v-model="show_edit" :customer-data="customer_selected" @edited="getCustomers" @close="customer_selected = null" />
        <dialog-delete-customer v-model="show_delete" :customer-data="customer_selected" @deleted="getCustomers" @close="customer_selected = null" />
        <dialog-list-sessions v-model="show_sessions" :customer-data="customer_selected" @close="customer_selected = null" @updateData="getCustomers"/>
        <dialog-list-emergency-contacts v-model="show_emergency_contacts" :customer-data="customer_selected" @close="customer_selected = null"/>
        <dialog-list-payments v-model="show_list_customer_payments" :customer-data="customer_selected" @close="show_list_customer_payments = null" @updateData="getCustomers" />
        <dialog-generate-contract v-model="show_generate_contract" :customer="customer_selected" @close="customer_selected = null" @created="getCustomers" />
    </v-card>
</template>

<script>
import dayjs from 'dayjs';
import localeData from "dayjs/plugin/localeData";
import axios from 'axios';
import html2pdf from 'html2pdf.js';
import extenso from 'extenso';
import DialogAddCustomer from './components/DialogAddCustomer.vue';
import DialogEditCustomer from './components/DialogEditCustomer.vue';
import DialogDeleteCustomer from './components/DialogDeleteCustomer.vue';
import DialogListSessions from '../sessions/components/DialogListSessions.vue';
import DialogListEmergencyContacts from './components/DialogListEmergencyContacts.vue';
import DialogListPayments from '../extract/components/DialogListPayments.vue';
import DialogGenerateContract from './components/DialogGenerateContract.vue';
import { mapActions } from 'vuex';

export default {
    components: {
        DialogAddCustomer,
        DialogEditCustomer,
        DialogDeleteCustomer,
        DialogListSessions,
        DialogListEmergencyContacts,
        DialogListPayments,
        DialogGenerateContract
    },
    data() {
        return {
            headers: [
                { text: "Nome", value: "name" },
                { text: "Status", value: "status" },
                { text: "Dia da sessão", value: "week_day_session" },
                { text: "Hora da sessão", value: "hour_session" },
                { text: "Duração", value: "session_duration" },
                { text: "Próx. sessão", value: "next_session" },
                { text: "Frequência da sessão", value: "session_frequency" },
                { text: '', value: 'data-table-expand', sortable: false },
                { text: '', value: 'actions', sortable: false },
            ],
            show_columns: false,
            loading: false,
            search: "",
            show_add: false,
            status_config: {
                description: {
                    P: "Primeiro contato",
                    A: "Cliente ativo",
                    I: "Cliente inativo"
                },
                color: {
                    P: "yellow darken-2",
                    A: "lime",
                    I: "grey lighten-1"
                },
                icon: {
                    P: "mdi-account-clock",
                    A: "mdi-account-check",
                    I: "mdi-account-cancel"
                }
            },
            frequency_config: {
                description: {
                    M: "Mensal",
                    Q: "Quinzenal",
                    S: "Semanal"
                },
                color: {
                    M: "purple",
                    Q: "deep-purple",
                    S: "teal"
                },
                icon: {
                    M: "mdi-calendar-month",
                    Q: "mdi-calendar-text",
                    S: "mdi-calendar-week"
                }
            },
            week_day_description: {
                monday: 'Segunda-feira',
                tuesday: 'Terça-feira',
                wednesday: 'Quarta-feira',
                thursday: 'Quinta-feira',
                friday: 'Sexta-feira',
                saturday: 'Sábado',
                sunday: 'Domingo'
            },
            customer_selected: null,
            show_edit: false,
            show_delete: false,
            show_sessions: false,
            show_emergency_contacts: false,
            show_generate_contract: false,
            show_list_customer_payments: false,
            show_filter: false,
            months: [
                { value: '01', text: 'Janeiro' },
                { value: '02', text: 'Fevereiro' },
                { value: '03', text: 'Março' },
                { value: '04', text: 'Abril' },
                { value: '05', text: 'Maio' },
                { value: '06', text: 'Junho' },
                { value: '07', text: 'Julho' },
                { value: '08', text: 'Agosto' },
                { value: '09', text: 'Setembro' },
                { value: '10', text: 'Outubro' },
                { value: '11', text: 'Novembro' },
                { value: '12', text: 'Dezembro' }
            ],
            frequency_type_items: [
                { value: 'M', text: "Mensal" },
                { value: 'Q', text: "Quinzenal" },
                { value: 'S', text: "Semanal" }
            ],
            status_items: [
                { value: 'P', text: "Primeiro contato" },
                { value: 'A', text: "Cliente ativo" },
                { value: 'I', text: "Cliente inativo" }
            ],
            boolean_items: [
                { value: true, text: "Sim" },
                { value: false, text: "Não" }
            ],
            name: null,
            cpf: null,
            email: null,
            phone: null,
            birthday_month: null,
            payment_type: null,
            status: ['A', 'P'],
            payment_frequency: null,
            session_frequency: null,
            rg: null,
            balance: null,
            signed_contract: null,
            customers_list: [],
            table_columns: {
                name: { text: "Nome", value: "name", name: "Nome", show: true },
                cpf: { text: "CPF", value: "cpf", name: "CPF", show: false },
                rg: { text: "RG", value: "rg", name: "RG", show: false },
                email: { text: "Email", value: "email", name: "Email", show: false },
                phone: { text: "Telefone", value: "phone", name: "Telefone", show: false },
                birthday: { text: "Data de nascimento", value: "birthday", name: "Data de nascimento", show: false },
                balance: { text: "Saldo do cliente", value: "balance", name: "Saldo do cliente", show: false },
                status: { text: "Status", value: "status", name: "Status", show: true },
                week_day_session: { text: "Dia da sessão", value: "week_day_session", name: "Dia da sessão", show: true },
                hour_session: { text: "Hora da sessão", value: "hour_session", name: "Hora da sessão", show: true },
                session_duration: { text: "Duração", value: "session_duration", name: "Duração", show: true },
                next_session: { text: "Próx. sessão", value: "next_session", name: "Próx. sessão", show: true },
                session_frequency: { text: "Frequência da sessão", value: "session_frequency", name: "Frequência da sessão", show: true },
                payment_frequency: { text: "Frequência de pagamento", value: "payment_frequency", name: "Frequência de pagamento", show: false },
                ammount: { text: "Valor a ser pago", value: "ammount", name: "Valor a ser pago", show: false },
                payday: { text: "Data do próx. pagamento", value: "payday", name: "Data do próx. pagamento", show: false },
                payment_type: { text: "Tipo de pagamento", value: "payment_type", name: "Tipo de pagamento", show: false },
                'data-table-expand': { text: '', value: 'data-table-expand', sortable: false, name: "Expandir informações", show: true },
                actions: { text: '', value: 'actions', sortable: false, name: "Ações", show: true },
            }
        };
    },
    mounted() {
        dayjs.extend(require('dayjs/plugin/customParseFormat'));
        dayjs.extend(localeData);
        dayjs.locale('pt-br');
        this.getCustomers();

        let local_storage = window.localStorage.getItem('table_columns');
        if (local_storage) {
            this.table_columns = JSON.parse(local_storage);
            this.mountColumns();
        }
    },
    methods: {
        ...mapActions("customers", ["ActionDoGetCustomers"]),
        getCustomers() {
            this.loading = true;
            this.ActionDoGetCustomers(this.mountFilter()).then((customers) => {
                this.customers_list = customers;
                if (this.customer_selected) {
                    this.customer_selected = customers.find((customer) => customer.id === this.customer_selected.id);
                }
                this.loading = false;
            });
        },
        mountFilter() {
            let filter = {};

            if (this.name) {
                filter['name'] = this.name;
            }
            if (this.cpf) {
                filter['cpf'] = this.cpf;
            }
            if (this.email) {
                filter['email'] = this.email;
            }
            if (this.phone) {
                filter['phone'] = this.phone;
            }
            if (this.birthday_month) {
                filter['birthday_month'] = this.birthday_month;
            }
            if (this.payment_type) {
                filter['payment_type'] = this.payment_type;
            }
            if (this.status) {
                filter['status'] = this.status;
            }
            if (this.payment_frequency) {
                filter['payment_frequency'] = this.payment_frequency;
            }
            if (this.session_frequency) {
                filter['session_frequency'] = this.session_frequency;
            }
            if (this.rg) {
                filter['rg'] = this.rg;
            }
            if (this.balance !== null) {
                filter['balance'] = this.balance;
            }
            if (this.signed_contract !== null) {
                filter['signed_contract'] = this.signed_contract;
            }

            return Object.values(filter).length > 0 ? filter : null;
        },
        formatDate(date) {
            return dayjs(date).format('DD/MM/YYYY');
        },
        edit(customer) {
            this.customer_selected = customer;
            this.show_edit = true;
        },
        disable(customer) {
            this.customer_selected = customer;
            this.show_delete = true;
        },
        showSessions(customer) {
            this.customer_selected = customer;
            this.show_sessions = true;
        },
        showPayments(customer) {
            this.customer_selected = customer;
            this.show_list_customer_payments = true;
        },
        showEmergencyContacts(customer) {
            this.customer_selected = customer;
            this.show_emergency_contacts = true;
        },
        generateContract(customer) {
            this.customer_selected = customer;
            this.show_generate_contract = true;
        },
        async generatePDF(customer) {
            customer.loading_contract = true;
            this.$forceUpdate();
            let contract = customer.contract;
            let options = {
                margin: 25,
                filename: 'Contrato_' + contract.contractor.replace(/\s+/g, '_') + '.pdf',
                image: { type: 'jpeg', quality: 1 },
                html2canvas: { dpi: 192, letterRendering: true },
                jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
            }

            return axios.get('/contract/contract.html').then(response => {
                let html = response.data;
                html = html.replaceAll('[CONTRACTOR]',contract.contractor);
                html = html.replaceAll('[CPF]', contract.cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4"));
                html = html.replace('[RG]', contract.rg);
                html = html.replace('[MINUTES]', contract.minutes);
                html = html.replace('[WEEKDAY]', this.week_day_description[contract.weekday]);
                html = html.replace('[HOUR]', contract.hour);
                html = html.replace('[FREQUENCY]', this.frequency_config.description[customer.session_frequency]);
                html = html.replace('[VALUE]', (contract.value / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }));
                html = html.replace('[FULL_VALUE]', extenso((contract.value / 100).toLocaleString('pt-BR'), { mode: 'currency' }));
                html = html.replace('[PAYMENT_TYPE]', contract.payment_type);
                html = html.replace('[CANCEL_VALUE]', (contract.cancel_value / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }));
                html = html.replace('[DATE]', dayjs(contract.date).format('DD [de] MMMM [de] YYYY'));

                html2pdf().from(html).set(options).save();
                customer.loading_contract = false;
                this.$forceUpdate();
            });
        },
        toggleColumn(column) {
            column.show = !column.show;
            window.localStorage.setItem('table_columns', JSON.stringify(this.table_columns));
            this.mountColumns();
        },
        mountColumns() {
            let new_headers = [];

            for (let column in this.table_columns) {
                if (this.table_columns[column].show) {
                    new_headers.push(this.table_columns[column]);
                }
            }

            this.headers = new_headers;
        }
    }
};
</script>
