<template>
    <v-dialog v-model="show" max-width="1200px" scrollable>
        <v-card class="pa-4">
            <v-card-title>
                Sessões de &nbsp;<span class="primary-blue--text font-italic font-weight-bold">{{ customerData?.name }}</span>
            </v-card-title>
            <v-card-text>
                <v-data-table
                    :headers="headers"
                    :items="customerData?.sessions"
                    sort-by="session_date"
                    sort-desc
                >
                    <template v-slot:[`item.session_date`]="{ item }">
                        {{ formatDate(item.session_date, true) }}
                    </template>
                    <template v-slot:[`item.session_duration`]="{ item }">
                        {{ item.session_duration }} minutos
                    </template>
                    <template v-slot:[`item.attended`]="{ item }">
                        <span v-if="item.rescheduled">Reagendou <v-icon color="primary-blue">mdi-calendar-refresh-outline</v-icon></span>
                        <v-icon v-else-if="item.attended" color="secondary-green">mdi-check-bold</v-icon>
                        <v-icon v-else color="secondary-red">mdi-close-thick</v-icon>
                    </template>
                    <template v-slot:[`item.session_record`]="{ item }">
                        <span v-if="item.rescheduled">Reagendou <v-icon color="primary-blue">mdi-calendar-refresh-outline</v-icon></span>
                        <v-icon v-else-if="item.session_record" color="secondary-green">mdi-check-bold</v-icon>
                        <v-icon v-else color="secondary-red">mdi-close-thick</v-icon>
                    </template>
                    <template v-slot:[`item.session_value`]="{ item }">
                        {{ (item.session_value / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-row no-gutters class="flex-nowrap">
                            <v-col cols="auto" class="px-1">
                                <v-btn icon @click="openEditSession(item)" color="primary-blue">
                                    <v-icon>
                                        mdi-eye-outline
                                    </v-icon>
                                </v-btn>
                            </v-col>
                            <v-col cols="auto" class="px-1">
                                <v-btn icon @click="openDeleteSession(item)" color="tertiary-red">
                                    <v-icon>
                                        mdi-delete-empty-outline
                                    </v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </template>
                </v-data-table>
            </v-card-text>
            <v-card-actions>
                <v-row no-gutters justify="space-between">
                    <v-col cols="auto">
                        <v-btn dark color="primary-pink" elevation="0" @click="close"><v-icon left>mdi-arrow-u-left-top</v-icon> Voltar</v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
            <dialog-edit-session :customer-data="show_edit_session ? customerData : null" :session-data="session_data" v-model="show_edit_session" @close="closeEditSession" @edited="$emit('updateData')" />
            <dialog-delete-session :customer-data="show_delete_session ? customerData : null" :session-data="session_data" v-model="show_delete_session" @close="closeDeleteSession" @deleted="$emit('updateData')" />
        </v-card>
    </v-dialog>
</template>

<script>
import dayjs from "dayjs";
dayjs.extend(require('dayjs/plugin/customParseFormat'));

import DialogEditSession from "./DialogEditSession.vue";
import DialogDeleteSession from "./DialogDeleteSession.vue";

export default {
    components: {
        DialogEditSession,
        DialogDeleteSession
    },
    props: {
        value: {
            type: Boolean,
            required: false,
            default: false
        },
        customerData: {
            type: Object,
            required: false,
            default: null
        }
    },
    data() {
        return {
            show: this.value,
            headers: [
                { text: "Data", value: "session_date", sortable: true },
                { text: "Duração", value: "session_duration", sortable: true },
                { text: "Compareceu", value: "attended", sortable: true },
                { text: "Prontuário", value: "session_record", sortable: true },
                { text: "Valor", value: "session_value", sortable: true },
                { text: "Comentários", value: "comments", sortable: true },
                { text: '', value: 'actions', sortable: false },
            ],
            session_data: null,
            show_edit_session: false,
            show_delete_session: false
        };
    },
    watch: {
        value() {
            this.show = this.value;
        },
        show() {
            if (!this.show) {
                this.$emit("close");
            }

            this.$emit("input", this.show);
        }
    },
    methods: {
        formatDate(date, hour = false) {
            return date ? dayjs(date).format("DD/MM/YYYY" + (hour ? " HH:mm" : "")) : null;
        },
        close() {
            this.show = false;
        },
        closeEditSession() {
            this.show_edit_session = false;
            this.session_data = null;
        },
        closeDeleteSession() {
            this.show_delete_session = false;
            this.session_data = null;
        },
        openEditSession(session) {
            this.session_data = session;
            this.show_edit_session = true;
        },
        openDeleteSession(session) {
            this.session_data = session;
            this.show_delete_session = true;
        }
    }
};
</script>
