var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"600px","persistent":_vm.loading,"scrollable":""},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',{staticClass:"pa-4"},[_c('v-card-title',[_vm._v(" Remover sessão ")]),_c('v-card-text',[_c('v-form',{ref:"formSession"},[_c('v-row',[(_vm.error_message)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"type":"error darken-4"}},[_vm._v(" "+_vm._s(_vm.error_message)+" ")])],1):_vm._e(),(_vm.success_message)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"type":"success"}},[_vm._v(" "+_vm._s(_vm.success_message)+" ")])],1):_vm._e(),_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"label":"Cliente","disabled":_vm.customer ? true : false,"items":_vm.customers_list,"readonly":""},model:{value:(_vm.customer),callback:function ($$v) {_vm.customer=$$v},expression:"customer"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field-money',{attrs:{"label":"Valor da sessão","properties":{
                                prefix: 'R$',
                                readonly: true
                            },"options":{
                                locale: 'pt-BR',
                                length: 11,
                                precision: 2,
                                empty: null,
                            }},model:{value:(_vm.session_value),callback:function ($$v) {_vm.session_value=$$v},expression:"session_value"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"value":_vm.formatDate(_vm.session_date),"label":"Data da sessão","append-icon":"mdi-calendar","readonly":""}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"Hora da sessão","append-icon":"mdi-calendar","readonly":""},model:{value:(_vm.hour_session),callback:function ($$v) {_vm.hour_session=$$v},expression:"hour_session"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-switch',{attrs:{"color":"primary-blue","label":"O cliente compareceu","readonly":""},model:{value:(_vm.attended),callback:function ($$v) {_vm.attended=$$v},expression:"attended"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"value":_vm.formatDate(_vm.next_session),"label":"Data da próxima sessão","append-icon":"mdi-calendar","readonly":""}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"label":"Observações","readonly":""},model:{value:(_vm.comments),callback:function ($$v) {_vm.comments=$$v},expression:"comments"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-row',{attrs:{"no-gutters":"","justify":"space-between"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"disabled":_vm.loading,"loading":_vm.loading,"color":"primary-pink","dark":"","elevation":"0"},on:{"click":_vm.close}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-arrow-u-left-top")]),_vm._v(" Voltar")],1)],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"disabled":_vm.loading,"loading":_vm.loading,"color":"secondary-red","dark":"","elevation":"0"},on:{"click":_vm.save}},[_vm._v("Excluir "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-check-bold")])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }