<template>
    <v-row no-gutters>
        <v-col cols="12">
            <v-row justify="center">
                <v-col cols="auto">
                    <v-select v-model="month" label="Mês" solo :items="month_list" />
                </v-col>
                <v-col cols="auto">
                    <v-select v-model="year" label="Ano" solo :items="yearsList" />
                </v-col>
            </v-row>
            <v-row align="center">
                <v-col cols="12">
                    <v-card height="100%" class="pa-4" color="primary-gray">
                        <v-row>
                            <v-col cols="auto">
                                <span class="text-h5">Média diária do mês</span>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="4">
                                <v-icon left>mdi-timelapse</v-icon> Horas em sessão <span class="text-h6">{{ daily_average_data?.hours_session_average }}</span>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-icon left>mdi-head-check</v-icon> Sessões realizadas <span class="text-h6">{{ daily_average_data?.total_sessions_average.toFixed(2) }}</span>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-icon left>mdi-cash-check</v-icon> Ganhos diários <span class="text-h6">{{ (daily_average_data?.total_received_average / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</span>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
            <v-row align="center">
                <v-col cols="12">
                    <v-card height="100%" class="pa-4" color="primary-gray">
                        <v-row>
                            <v-col cols="auto">
                                <span class="text-h5">Sessões</span>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="4">
                                <graphic-card
                                    icon="mdi-timelapse"
                                    title="Horas em sessão"
                                    :title-value="dashboard_data?.worked_hours"
                                    :chart-options="getChartData('worked_hours', this.$vuetify.theme.themes.light['secondary-pink'])?.chartOptions"
                                    :chart-series="getChartData('worked_hours', this.$vuetify.theme.themes.light['secondary-pink'])?.series"
                                    description-percent="do previsto"
                                    :progress-value="dashboard_data?.worked_minutes ? (dashboard_data?.worked_minutes * 100) / dashboard_data?.expected_total_session_minutes : 0"
                                    :progress-total-label="formatMinutesInTime(dashboard_data?.expected_total_session_minutes)"
                                    color="secondary-pink"
                                    :loading="loading"
                                    :loading-charts="loading_charts"
                                    @click="showListDialog('worked_hours')"
                                    @clickProgress="showListDialog('expected_total_session_minutes')"
                                />
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                                <graphic-card
                                    icon="mdi-head-check"
                                    title="Sessões realizadas"
                                    :title-value="dashboard_data?.total_sessions"
                                    :chart-options="getChartData('total_sessions', this.$vuetify.theme.themes.light['secondary-purple'])?.chartOptions"
                                    :chart-series="getChartData('total_sessions', this.$vuetify.theme.themes.light['secondary-purple'])?.series"
                                    description-percent="das sessões previstas"
                                    :progress-value="dashboard_data?.total_sessions ? (dashboard_data?.total_sessions * 100) / dashboard_data?.expected_total_sessions : 0"
                                    :progress-total-label="dashboard_data?.expected_total_sessions"
                                    color="secondary-purple"
                                    :loading="loading"
                                    :loading-charts="loading_charts"
                                    @click="showListDialog('total_sessions')"
                                    @clickProgress="showListDialog('expected_total_sessions')"
                                />
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                                <graphic-card
                                    icon="mdi-head-remove"
                                    title="Sessões não realizadas"
                                    :title-value="dashboard_data?.missed_sessions"
                                    :chart-options="getChartData('missed_sessions', this.$vuetify.theme.themes.light['secondary-red'])?.chartOptions"
                                    :chart-series="getChartData('missed_sessions', this.$vuetify.theme.themes.light['secondary-red'])?.series"
                                    description-percent="das sessões previstas"
                                    :progress-value="dashboard_data?.missed_sessions ? (dashboard_data?.missed_sessions * 100) / dashboard_data?.expected_total_sessions : 0"
                                    :progress-total-label="dashboard_data?.expected_total_sessions"
                                    color="secondary-red"
                                    :loading="loading"
                                    :loading-charts="loading_charts"
                                    @click="showListDialog('missed_sessions')"
                                    @clickProgress="showListDialog('expected_total_sessions')"
                                />
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
            <v-row align="center">
                <v-col cols="12">
                    <v-card height="100%" class="pa-4" color="primary-gray">
                        <v-row>
                            <v-col cols="auto">
                                <span class="text-h5">Valores</span>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <graphic-card
                                    icon="mdi-cash"
                                    title="Total recebido"
                                    :title-value="(dashboard_data?.total_received / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })"
                                    :chart-options="getChartData('total_received', this.$vuetify.theme.themes.light['secondary-green'])?.chartOptions"
                                    :chart-series="getChartData('total_received', this.$vuetify.theme.themes.light['secondary-green'])?.series"
                                    description-percent="do previsto"
                                    :progress-value="dashboard_data?.total_received ? (dashboard_data?.total_received * 100) / dashboard_data?.expected_total_received : 0"
                                    :progress-total-label="(dashboard_data?.expected_total_received / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })"
                                    color="secondary-green"
                                    :loading="loading"
                                    :loading-charts="loading_charts"
                                    @click="showListDialog('total_received')"
                                    @clickProgress="showListDialog('expected_total_received')"
                                >
                                    <div v-if="!loading">
                                        <span class="text-caption"> + {{ (dashboard_data?.surplus_value / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</span>
                                        <span class="text-caption"> = {{ ((dashboard_data?.total_received + dashboard_data.surplus_value) / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</span>
                                    </div>
                                    <v-progress-circular v-else indeterminate color="secondary-green" />
                                </graphic-card>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <graphic-card
                                    icon="mdi-cash-minus"
                                    title="Total gasto"
                                    :title-value="(dashboard_data?.total_expenses / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })"
                                    :chart-options="getChartData('total_expenses', this.$vuetify.theme.themes.light['tertiary-red'])?.chartOptions"
                                    :chart-series="getChartData('total_expenses', this.$vuetify.theme.themes.light['tertiary-red'])?.series"
                                    description-percent="do previsto"
                                    :progress-value="dashboard_data?.total_expenses ? (dashboard_data?.total_expenses * 100) / dashboard_data?.expected_total_expenses : 0"
                                    :progress-total-label="(dashboard_data?.expected_total_expenses / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })"
                                    color="tertiary-red"
                                    :loading="loading"
                                    :loading-charts="loading_charts"
                                    @click="showListDialog('total_expenses')"
                                    @clickProgress="showListDialog('expected_total_expenses')"
                                >
                                    <span class="text-caption">Total de despesas</span>
                                </graphic-card>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <graphic-card
                                    icon="mdi-cash-check"
                                    title="Total realizado"
                                    :title-value="(dashboard_data?.total_realized / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })"
                                    :chart-options="getChartData('total_realized', this.$vuetify.theme.themes.light['primary-blue'])?.chartOptions"
                                    :chart-series="getChartData('total_realized', this.$vuetify.theme.themes.light['primary-blue'])?.series"
                                    description-percent="do previsto"
                                    :progress-value="dashboard_data?.total_realized ? (dashboard_data?.total_realized * 100) / dashboard_data?.expected_total_realized : 0"
                                    :progress-total-label="(dashboard_data?.expected_total_realized / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })"
                                    color="primary-blue"
                                    :loading="loading"
                                    :loading-charts="loading_charts"
                                    @click="showListDialog('total_realized')"
                                    @clickProgress="showListDialog('expected_total_realized')"
                                >
                                    <span class="text-caption">Total das sessões já realizadas</span>
                                </graphic-card>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <graphic-card
                                    icon="mdi-cash-clock"
                                    title="Valor da hora em sessão"
                                    :title-value="(dashboard_data?.hourly_rate / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })"
                                    :chart-options="getChartData('hourly_rate', this.$vuetify.theme.themes.light['secondary-blue'])?.chartOptions"
                                    :chart-series="getChartData('hourly_rate', this.$vuetify.theme.themes.light['secondary-blue'])?.series"
                                    description-percent="do previsto"
                                    :progress-value="dashboard_data?.hourly_rate ? (dashboard_data?.hourly_rate * 100) / (dashboard_data?.expected_total_realized / getDurationByMinutes(dashboard_data?.expected_total_session_minutes - dashboard_data?.unworked_minutes)) : 0"
                                    :progress-total-label="((dashboard_data?.expected_total_realized / getDurationByMinutes(dashboard_data?.expected_total_session_minutes - dashboard_data?.unworked_minutes)) / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })"
                                    color="secondary-blue"
                                    :loading="loading"
                                    :loading-charts="loading_charts"
                                >
                                    <span class="text-caption">Total realizado ÷ Horas em sessão</span>
                                </graphic-card>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
            <v-row align="center">
                <v-col cols="12">
                    <v-card height="100%" class="pa-4" color="primary-gray">
                        <v-row>
                            <v-col cols="auto">
                                <span class="text-h5">Clientes</span>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="6">
                                <graphic-card
                                    icon="mdi-calendar-check-outline"
                                    title="Taxa de comparecimento"
                                    :title-value="dashboard_data?.total_sessions"
                                    :chart-options="getChartData('attendance_rate', this.$vuetify.theme.themes.light['secondary-pink'])?.chartOptions"
                                    :chart-series="getChartData('attendance_rate', this.$vuetify.theme.themes.light['secondary-pink'])?.series"
                                    description-percent="das sessões previstas"
                                    :progress-value="dashboard_data?.attendance_rate"
                                    :progress-total-label="dashboard_data?.expected_total_sessions"
                                    color="secondary-pink"
                                    :loading="loading"
                                    :loading-charts="loading_charts"
                                />
                            </v-col>
                            <v-col cols="12" md="6">
                                <graphic-card
                                    icon="mdi-calendar-refresh-outline"
                                    title="Taxa de reagendamento"
                                    :title-value="dashboard_data?.rescheduled_sessions"
                                    :chart-options="getChartData('reschedule_rate', this.$vuetify.theme.themes.light['tertiary-gray'])?.chartOptions"
                                    :chart-series="getChartData('reschedule_rate', this.$vuetify.theme.themes.light['tertiary-gray'])?.series"
                                    description-percent="das sessões previstas"
                                    :progress-value="dashboard_data?.reschedule_rate"
                                    :progress-total-label="dashboard_data?.expected_total_sessions"
                                    color="tertiary-gray"
                                    :loading="loading"
                                    :loading-charts="loading_charts"
                                />
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
        <v-dialog v-model="show_list_dialog" max-width="1200px" scrollable>
            <v-card class="pa-4">
                <v-card-title>
                    Lista de {{ type_selected }}
                </v-card-title>
                <v-card-text>
                    <v-data-table
                        :headers="headers"
                        :items="list_items"
                        single-expand
                        :show-expand="show_expand"
                    >
                        <template v-slot:[`item.session_date`]="{ item }">
                            {{ formatDate(item.session_date, true) }}
                        </template>
                        <template v-slot:[`item.session_duration`]="{ item }">
                            {{ item.session_duration }} minutos
                        </template>
                        <template v-slot:[`item.attended`]="{ item }">
                            <v-icon v-if="item.attended" color="success darken-4">mdi-check-bold</v-icon>
                            <v-icon v-else color="error darken-4">mdi-close-thick</v-icon>
                        </template>
                        <template v-slot:[`item.session_record`]="{ item }">
                            <v-icon v-if="item.session_record" color="success darken-4">mdi-check-bold</v-icon>
                            <v-icon v-else color="error darken-4">mdi-close-thick</v-icon>
                        </template>
                        <template v-slot:[`item.session_value`]="{ item }">
                            {{ (item.session_value / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}
                        </template>
                        <template v-slot:[`item.extract_date`]="{ item }">
                            {{ formatDate(item.extract_date) }}
                        </template>
                        <template v-slot:[`item.ammount_paid`]="{ item }">
                            {{ (item.ammount_paid / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}
                        </template>
                        <template v-slot:[`item.total_hours`]="{ item }">
                            {{ formatMinutesInTime(item.total) }}
                        </template>
                        <template v-slot:expanded-item="{ headers, item }">
                            <td :colspan="headers.length" class="primary-gray pa-4">
                                <v-card class="pa-4">
                                    <v-row>
                                        <v-col cols="12">
                                            <v-data-table
                                                :headers="expanded_header"
                                                :items="item.items"
                                                sort-by="date"
                                                hide-default-footer
                                                :items-per-page="-1"
                                                dense
                                            >
                                                <template v-slot:[`item.date`]="{ item }">
                                                    {{ formatDate(item.date, true) }}
                                                </template>
                                                <template v-slot:[`item.simple_date`]="{ item }">
                                                    {{ formatDate(item.date) }}
                                                </template>
                                                <template v-slot:[`item.ammount_paid`]="{ item }">
                                                    {{ (item.ammount_paid / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}
                                                </template>
                                            </v-data-table>
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </td>
                        </template>
                    </v-data-table>
                </v-card-text>
                <v-card-actions>
                    <v-row no-gutters justify="space-between">
                        <v-col cols="auto">
                            <v-btn outlined color="secondary-blue" @click="close"><v-icon left>mdi-arrow-u-left-top</v-icon> Voltar</v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import dayjs from 'dayjs';
dayjs.extend(require('dayjs/plugin/duration'));
dayjs.extend(require('dayjs/plugin/isSameOrAfter'));
dayjs.extend(require('dayjs/plugin/isSameOrBefore'));
dayjs.extend(require('dayjs/plugin/isBetween'));
import { mapActions } from 'vuex';
import GraphicCard from './GraphicCard.vue';

export default {
    components: {
        GraphicCard
    },
    data() {
        return {
            month: dayjs().format('MM'),
            month_list: [
                { value: '01', text: 'Janeiro' },
                { value: '02', text: 'Fevereiro' },
                { value: '03', text: 'Março' },
                { value: '04', text: 'Abril' },
                { value: '05', text: 'Maio' },
                { value: '06', text: 'Junho' },
                { value: '07', text: 'Julho' },
                { value: '08', text: 'Agosto' },
                { value: '09', text: 'Setembro' },
                { value: '10', text: 'Outubro' },
                { value: '11', text: 'Novembro' },
                { value: '12', text: 'Dezembro' }
            ],
            label_sparkline: [
                "Janeiro",
                "Fevereiro",
                "Março",
                "Abril",
                "Maio",
                "Junho",
                "Julho",
                "Agosto",
                "Setembro",
                "Outubro",
                "Novembro",
                "Dezembro"
            ],
            year: dayjs().format('YYYY'),
            loading: true,
            description_types: {
                worked_hours: "Horas em sessão",
                total_sessions: "Sessões realizadas",
                missed_sessions: "Sessões não realizadas",
                hourly_rate: "Valor da hora em sessão",
                total_received: "Total recebido",
                total_realized: "Total realizado",
                surplus_value: "Total excedente",
                attendance_rate: "Taxa de comparecimento",
                reschedule_rate: "Taxa de reagendamento",
                total_expenses: "Total gasto",
                expected_total_session_minutes: "Horas em sessão previstas",
                expected_total_sessions: "Sessões previstas",
                expected_total_received: "Valores previstos",
                expected_total_expenses: "Despesas previstas",
                expected_total_realized: "Valores à realizar"
            },
            show_list_dialog: false,
            headers: [],
            list_items: [],
            type_selected: "",
            week_day_index: {
                sunday: 0,
                monday: 1,
                tuesday: 2,
                wednesday: 3,
                thursday: 4,
                friday: 5,
                saturday: 6
            },
            dashboard_data: {},
            charts_data: {},
            loading_charts: false,
            loading_daily: false,
            show_expand: false,
            expanded_header: [],
            daily_average_data: null
        }
    },
    computed: {
        yearsList() {
            let current_year = 2023;
            let years = [];

            while (current_year <= dayjs().format('YYYY')) {
                years.push(current_year.toString());
                current_year++;
            }

            return years;
        }
    },
    watch: {
        month() {
            this.mountDailyAverage();
            this.mountDataMonth();
        },
        year() {
            this.mountDailyAverage();
            this.mountDataMonth();
            this.mountDataYear();
        }
    },
    mounted() {
        this.mountDailyAverage();
        this.mountDataMonth();
        this.mountDataYear();
    },
    methods: {
        ...mapActions("dashboard", ["ActionDoGetDashboardDataChartsMonth", "ActionDoGetDashboardDataChartsYear", "ActionDoGetDashboardDailyAverage"]),
        mountDataMonth() {
            this.loading = true;
            let start_date = dayjs(this.year + "-" + this.month + "01");

            this.ActionDoGetDashboardDataChartsMonth({
                start: start_date.format('YYYY-MM-DD'),
                end: start_date.endOf('month').format('YYYY-MM-DD')
            }).then(response => {
                this.dashboard_data = response;
                this.loading = false;
            });
        },
        mountDataYear() {
            this.loading_charts = true;

            this.ActionDoGetDashboardDataChartsYear({year: this.year}).then(response => {
                this.charts_data = response;
                this.loading_charts = false;
            });
        },
        mountDailyAverage() {
            this.loading_daily = true;
            let start_date = dayjs(this.year + "-" + this.month + "01");

            this.ActionDoGetDashboardDailyAverage({
                start: start_date.format('YYYY-MM-DD'),
                end: start_date.endOf('month').format('YYYY-MM-DD')
            }).then(response => {
                this.daily_average_data = response;
                this.loading_daily = false;
            });
        },
        getChartData(type_data, color = "#8BC34A") {
            let series = [];
            let series_data = [];

            for (let data of Object.values(this.charts_data)) {
                if (type_data === 'worked_hours') {
                    series_data.push(data.worked_minutes);
                } else {
                    series_data.push(data[type_data]);
                }
            }

            series.push({
                name: this.description_types[type_data],
                data: series_data
            });

            return {
                series: series,
                chartOptions: {
                    chart: {
                        type: "area",
                        zoom: {
                            enabled: false
                        },
                        toolbar: {
                            show: false
                        },
                    },
                    forecastDataPoints: {
                        count: 12 - parseInt(this.month)
                    },
                    dataLabels: {
                        enabled: false
                    },
                    colors: [color],
                    stroke: {
                        curve: "smooth"
                    },
                    grid: {
                        show: false
                    },
                    xaxis: {
                        categories: this.label_sparkline,
                        labels: {
                            show: false
                        }
                    },
                    yaxis: {
                        labels: {
                            formatter: function (value) {
                                switch (type_data) {
                                    case "worked_hours":
                                        return Math.floor(dayjs.duration(value, 'minutes').asHours()) + 'h ' + dayjs.duration(value, 'minutes').minutes() + 'm';
                                    case "hourly_rate":
                                    case "total_received":
                                    case "total_realized":
                                    case "surplus_value":
                                    case "total_expenses":
                                        return (value / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
                                    case "attendance_rate":
                                    case "reschedule_rate":
                                        return value + '%'
                                    default:
                                        return value;
                                }
                            },
                            show: false
                        }
                    },
                    tooltip: {
                        theme: 'dark'
                    }
                }
            };
        },
        showListDialog(type_data) {
            this.show_expand = false;
            this.expanded_header = [];
            switch (type_data) {
                case "worked_hours":
                case "total_sessions":
                case "missed_sessions":
                case "total_realized":
                    this.headers = [
                        { text: "Cliente", value: "customer.name", sortable: true },
                        { text: "Data", value: "session_date", sortable: true },
                        { text: "Duração", value: "session_duration", sortable: true },
                        { text: "Compareceu", value: "attended", sortable: true },
                        { text: "Prontuário", value: "session_record", sortable: true },
                        { text: "Valor", value: "session_value", sortable: true },
                        { text: "Comentários", value: "comments", sortable: true },
                    ];
                    break;
                case "total_received":
                case "total_expenses":
                    this.headers = [
                        { text: "Data", value: "extract_date", sortable: true },
                        { text: "Valor", value: "ammount_paid", sortable: true },
                        { text: "Título", value: "title", sortable: true },
                        { text: "Descrição", value: "description", sortable: true },
                    ];
                    break;
                case "expected_total_session_minutes":
                    this.headers = [
                        { text: "Cliente", value: "customer_name", sortable: true },
                        { text: "Total de horas previstas", value: "total_hours", sortable: true },
                        { text: '', value: 'data-table-expand' }
                    ];
                    this.show_expand = true;
                    this.expanded_header = [
                        { text: 'Data', value: 'date' },
                        { text: 'Duração', value: 'duration' }
                    ]
                    break;
                case "expected_total_sessions":
                    this.headers = [
                        { text: "Cliente", value: "customer_name", sortable: true },
                        { text: "Total de sessões previstas", value: "total", sortable: true },
                        { text: '', value: 'data-table-expand' }
                    ];
                    this.show_expand = true;
                    this.expanded_header = [
                        { text: 'Data', value: 'date' },
                        { text: 'Duração', value: 'duration' }
                    ]
                    break;
                case "expected_total_received":
                    this.headers = [
                        { text: "Cliente", value: "customer_name", sortable: true },
                        { text: "Valor à receber previsto", value: "ammount_paid", sortable: true },
                        { text: '', value: 'data-table-expand' }
                    ];
                    this.show_expand = true;
                    this.expanded_header = [
                        { text: 'Data do pagamento', value: 'date' },
                        { text: 'Valor a ser pago', value: 'ammount_paid' }
                    ]
                    break;
                case "expected_total_expenses":
                    this.headers = [
                        { text: "Despesa", value: "taxe_title", sortable: true },
                        { text: "Valor previsto", value: "ammount_paid", sortable: true },
                        { text: '', value: 'data-table-expand' }
                    ];
                    this.show_expand = true;
                    this.expanded_header = [
                        { text: 'Data do pagamento', value: 'simple_date' },
                        { text: 'Valor a ser pago', value: 'ammount_paid' }
                    ]
                    break;
                case "expected_total_realized":
                    this.headers = [
                        { text: "Cliente", value: "customer_name", sortable: true },
                        { text: "Total das sessões futuras", value: "ammount_paid", sortable: true },
                        { text: '', value: 'data-table-expand' }
                    ];
                    this.show_expand = true;
                    this.expanded_header = [
                        { text: 'Data da sessão', value: 'date' },
                        { text: 'Valor da sessão', value: 'ammount_paid' }
                    ]
                    break;
            }

            this.list_items = this.dashboard_data['list_' + type_data];
            this.type_selected = this.description_types[type_data];

            this.show_list_dialog = true;
        },
        close() {
            this.show_list_dialog = false;
            this.headers = [];
            this.list_items = [];
            this.type_selected = "";
        },
        formatDate(date, hour = false) {
            return date ? dayjs(date).format("DD/MM/YYYY" + (hour ? " HH:mm" : "")) : null;
        },
        formatMinutesInTime(total_minutes) {
            // Configura o tempo total em atendimento
            let duration = dayjs.duration(total_minutes, 'minutes');
            let hours = Math.floor(duration.asHours());
            let left_minutes = duration.minutes();
            return hours + 'h ' + left_minutes + 'm';
        },
        getDurationByMinutes(minutes) {
            return dayjs.duration(minutes, 'minutes').asHours();
        }
    }
}
</script>
