import services from "../../../http/index";

export const ActionDoGetDashboardDataChartsMonth = (context, payload = null) => {
    return services.api.request({
        url: "dashboard/charts-month",
        method: "get",
        params: payload
    }).then(response => {
        return response.data;
    });
};

export const ActionDoGetDashboardDataChartsYear = (context, payload = null) => {
    return services.api.request({
        url: "dashboard/charts-year",
        method: "get",
        params: payload
    }).then(response => {
        return response.data;
    });
};

export const ActionDoGetDashboardDailyAverage = (context, payload = null) => {
    return services.api.request({
        url: "dashboard/daily-average",
        method: "get",
        params: payload
    }).then(response => {
        return response.data;
    });
};