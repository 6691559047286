<template>
    <v-layout align-end justify-end>
        <v-snackbar
            v-model="show_notify"
            :timeout="10000"
            top
            right
            :color="notify_color"
            text
            elevation="24"
            rounded="pill"
            @input="closeNotify"
        >
            <v-icon left x-large>{{ notify_icon }}</v-icon><span v-html="notify_label" />
            <template v-slot:action="{ attrs }">
                <v-btn
                    :color="notify_color"
                    icon
                    v-bind="attrs"
                    @click="closeNotify()"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
    </v-layout>
</template>

<script>
import { mapState } from 'vuex';

export default {
    data() {
        return {
            alerts: {
                birthdays: false,
                sessions: false,
                one_more_session: false,
                payments: false
            },
            show_notify: false,
            notify_color: "primary-blue",
            notify_label: "",
            notify_key: null,
            notify_icon: null
        };
    },
    computed: {
        ...mapState("schedule", {
            alerts_today: state => state.alerts_today
        })
    },
    watch: {
        alerts_today() {
            this.makeAlerts();
        }
    },
    mounted() {
        this.makeAlerts();
    },
    methods: {
        makeAlerts() {
            let birthdays_length = this.alerts_today.birthdays?.length;
            let sessions_length = this.alerts_today.sessions?.length;
            let one_more_session_length = this.alerts_today.one_more_session?.length;
            let payments_length = this.alerts_today.payments?.length;

            this.alerts = {
                birthdays: {
                    show: birthdays_length ? true : false,
                    label: `<b>${birthdays_length}</b> pessoas fazem <b>aniversário</b> hoje!`,
                    color: "secondary-blue",
                    icon: "mdi-cake"
                },
                sessions: {
                    show: sessions_length ? true : false,
                    label: `Existem <b>${sessions_length} sessões</b> agendadas para hoje!`,
                    color: "primary-blue",
                    icon: "mdi-head-cog-outline"
                },
                one_more_session: {
                    show: one_more_session_length ? true : false,
                    label: `<b>${one_more_session_length} clientes</b> possuem saldo para somente mais uma sessão!`,
                    color: "orange",
                    icon: "mdi-cash-off"
                },
                payments: {
                    show: payments_length ? true : false,
                    label: `<b>${payments_length}</b> pessoas devem realizar o <b>pagamento</b> no dia de hoje!`,
                    color: "error darken-4",
                    icon: "mdi-cash-multiple"
                }
            }

            this.showNotify();
        },
        showNotify() {
            for (let key in this.alerts) {
                if (this.alerts[key].show) {
                    this.show_notify = true;
                    this.notify_color = this.alerts[key].color,
                    this.notify_label = this.alerts[key].label,
                    this.notify_icon = this.alerts[key].icon,
                    this.notify_key = key
                }
            }
        },
        closeNotify() {
            this.alerts[this.notify_key].show = false;
            this.notify_key = null;
            this.show_notify = false;

            setTimeout(() => {
                this.showNotify();
            }, 200);
        }
    }
}
</script>