import services from "../../../http/index";
import * as types from "./mutations-types";

export const ActionDoGetCustomers = ({ commit }, payload = null) => {
    return services.api.request({
        url: "customers",
        method: "get",
        params: payload
    }).then(response => {
        if (!payload) {
            commit(types.SET_CUSTOMERS_LIST, response.data);
        }
        return response.data;
    });
};

export const ActionDoGetCustomer = (context, customer_id) => {
    return services.api.request({
        url: `customers/${customer_id}`,
        method: "get"
    }).then(response => {
        return response.data;
    });
};

export const ActionDoCreateCustomer = (context, payload) => {
    return services.api.request({
        url: "customers",
        method: "post",
        data: payload
    }).then(response => {
        return response.data;
    });
};

export const ActionDoUpdateCustomer = (context, payload) => {
    return services.api.request({
        url: `customers/${payload.customer_id}`,
        method: "put",
        data: payload
    }).then(response => {
        return response.data;
    });
};

export const ActionDoDeleteCustomer = (context, customer_id) => {
    return services.api.request({
        url: `customers/${customer_id}`,
        method: "delete"
    }).then(response => {
        return response.data;
    });
};

export const ActionDoGetEmergencyContactsCustomer = (context, payload) => {
    return services.api.request({
        url: `emergency-contact/list`,
        method: "post",
        data: payload
    }).then(response => {
        return response.data;
    });
};

export const ActionDoCreateEmergencyContact = (context, payload) => {
    return services.api.request({
        url: "emergency-contact",
        method: "post",
        data: payload
    }).then(response => {
        return response.data;
    });
};

export const ActionDoUpdateEmergencyContact = (context, payload) => {
    return services.api.request({
        url: `emergency-contact/${payload.contact_id}`,
        method: "put",
        data: payload
    }).then(response => {
        return response.data;
    });
};

export const ActionDoDeleteEmergencyContact = (context, contact_id) => {
    return services.api.request({
        url: `emergency-contact/${contact_id}`,
        method: "delete"
    }).then(response => {
        return response.data;
    });
};

export const ActionDoGenerateContract = (context, payload) => {
    return services.api.request({
        url: "contract",
        method: "post",
        data: payload
    }).then(response => {
        return response.data;
    });
};