import axios from "axios";
import store from "../store";
import router from "../router";

const _headers = {
    "Content-Type": "application/json",
    "Accept": "application/json"
};

export const api = axios.create({
    //baseURL: "https://apiadmin.melissaandrade.com.br/api/v1/",
    //baseURL: "http://localhost:8002/api/v1/",
    baseURL: process.env.VUE_APP_API_URL,
    headers: _headers
});

api.interceptors.request.use(config => {
    if (store && store.state.auth.token) {
        config.headers.Authorization = "Bearer " + store.state.auth.token;
    }

    return config;
});

api.interceptors.response.use(response => response, error => {
    const status = error.response ? error.response.status : null;

    if (status === 401) {
        router.push({ name: "auth" });
    }

    return Promise.reject(error);
});

export default {
    api
};
