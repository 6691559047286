var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"600px","persistent":_vm.loading,"scrollable":""},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',{staticClass:"pa-4"},[_c('v-card-title',[_vm._v(" Editar contato de emergência "),_c('i',[_vm._v(" "+_vm._s(_vm.name))])]),_c('v-card-text',[_c('v-form',{ref:"formContact"},[_c('v-row',[(_vm.error_message)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"type":"error darken-4"}},[_vm._v(" "+_vm._s(_vm.error_message)+" ")])],1):_vm._e(),(_vm.success_message)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"type":"success"}},[_vm._v(" "+_vm._s(_vm.success_message)+" ")])],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Nome","rules":[_vm.basic_rules.required]},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"Grau de parentesco"},model:{value:(_vm.degree_kinship),callback:function ($$v) {_vm.degree_kinship=$$v},expression:"degree_kinship"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field-simplemask',{attrs:{"label":"Telefone","options":{
                                inputMask: '+## (##) #####-####',
                                outputMask: '#############',
                                empty: null,
                                applyAfter: false,
                                alphanumeric: true,
                                lowerCase: false,
                            }},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-row',{attrs:{"no-gutters":"","justify":"space-between"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"disabled":_vm.loading,"loading":_vm.loading,"dark":"","color":"primary-pink","elevation":"0"},on:{"click":_vm.close}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-arrow-u-left-top")]),_vm._v(" Voltar")],1)],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"disabled":_vm.loading,"loading":_vm.loading,"dark":"","color":"primary-blue","elevation":"0"},on:{"click":_vm.save}},[_vm._v("Salvar "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-check-bold")])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }