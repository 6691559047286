<template>
    <v-dialog v-model="show" max-width="600px" :persistent="loading" scrollable>
        <v-card class="pa-4">
            <v-card-title>
                Editar registro financeiro
            </v-card-title>
            <v-card-text>
                <v-form ref="formExtract">
                    <v-row>
                        <v-col v-if="error_message" cols="12">
                            <v-alert type="error darken-4">
                                {{ error_message }}
                            </v-alert>
                        </v-col>
                        <v-col v-if="success_message" cols="12">
                            <v-alert type="success">
                                {{ success_message }}
                            </v-alert>
                        </v-col>
                        <v-col :cols="type ? 6 : 12">
                            <v-select
                                v-model="type"
                                label="Tipo"
                                :disabled="customerData || taxeData ? true : false"
                                :items="[
                                    { text: 'Recebido de cliente', value: 'R' },
                                    { text: 'Pagamento de despesa programada', value: 'D' },
                                ]"
                                :rules="[basic_rules.required]"
                            />
                        </v-col>
                        <v-col v-if="type" :cols="6">
                            <v-autocomplete
                                v-if="type === 'R'"
                                v-model="customer"
                                :items="customers_list"
                                :disabled="customerData ? true : false"
                                label="Cliente"
                                :rules="[basic_rules.required]"
                            />
                            <v-select
                                v-if="type === 'D'"
                                v-model="taxe"
                                label="Imposto"
                                :disabled="taxeData ? true : false"
                                :items="taxes_list"
                                :rules="[basic_rules.required]"
                            />
                        </v-col>
                        <v-col cols="6">
                            <v-menu
                                ref="extractDate"
                                v-model="show_datepicker_extract"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        :value="formatDate(extract_date)"
                                        label="Data do registro"
                                        append-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        @input="$event => extract_date = formatUsa($event)"
                                        :rules="[basic_rules.required]"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="extract_date"
                                    no-title
                                    scrollable
                                    editable
                                    @input="show_datepicker_extract = false"
                                />
                            </v-menu>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field-money
                                v-model="ammount_paid"
                                label="Valor"
                                :properties="{
                                    prefix: 'R$',
                                }"
                                :options="{
                                    locale: 'pt-BR',
                                    length: 11,
                                    precision: 2,
                                    empty: null,
                                }"
                            />
                        </v-col>
                        <v-col cols="6">
                            <v-switch
                                v-model="add_next_payday"
                                color="primary-blue"
                                label="Adicionar próximo pagamento?"
                            ></v-switch>
                        </v-col>
                        <v-col cols="6">
                            <v-menu
                                v-if="add_next_payday"
                                ref="nextPayday"
                                v-model="show_next_payday"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        :value="formatDate(next_payday)"
                                        label="Data do próx. pagamento"
                                        append-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        @input="$event => next_payday = formatUsa($event)"
                                        :rules="[basic_rules.required]"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="next_payday"
                                    no-title
                                    scrollable
                                    editable
                                    @input="show_next_payday = false"
                                />
                            </v-menu>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field v-model="title" label="Título do registro" :rules="[basic_rules.required]" />
                        </v-col>
                        <v-col cols="12">
                            <v-textarea v-model="description" label="Descrição" />
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-row no-gutters justify="space-between">
                    <v-col cols="auto">
                        <v-btn :disabled="loading" :loading="loading" dark color="primary-pink" elevation="0" @click="close"><v-icon left>mdi-arrow-u-left-top</v-icon> Voltar</v-btn>
                    </v-col>
                    <v-col cols="auto">
                        <v-btn :disabled="loading" :loading="loading" dark color="primary-blue" elevation="0" @click="save">Salvar <v-icon right>mdi-check-bold</v-icon></v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import dayjs from "dayjs";
import { mapActions, mapState } from 'vuex';

dayjs.locale('pt-br');
dayjs.extend(require('dayjs/plugin/customParseFormat'));

export default {
    props: {
        value: {
            type: Boolean,
            required: false,
            default: false
        },
        customerData: {
            type: Object,
            required: false,
            default: null
        },
        taxeData: {
            type: Object,
            required: false,
            default: null
        },
        extractData: {
            type: Object,
            required: false,
            default: null
        }
    },
    data() {
        return {
            show: this.value,
            basic_rules: {
                required: value => !!value || "Informação obrigatória"
            },
            show_datepicker_extract: false,
            loading: false,
            error_message: null,
            success_message: null,
            show_next_payday: false,
            next_payday: null,
            extract_date: dayjs().format('YYYY-MM-DD'),
            ammount_paid: null,
            title: null,
            customer: null,
            taxe: null,
            description: null,
            type: null,
            add_next_payday: true
        };
    },
    computed: {
        ...mapState("customers", {
            customers_list: state => state.customers_list?.filter(customer => {
                return customer.status != 'I';
            })?.map(customer => {
                return { text: customer.name, value: customer };
            })
        }),
        ...mapState("taxes", {
            taxes_list: state => state.taxes_list?.map(taxe => {
                return { text: taxe.title, value: taxe };
            })
        })
    },
    watch: {
        value() {
            this.show = this.value;
        },
        show() {
            if (!this.show) {
                this.$emit("close");

                this.show_datepicker_extract = false;
                this.loading = false;
                this.error_message = null;
                this.success_message = null;
                this.show_next_payday = false;
                this.next_payday = null;
                this.extract_date = null;
                this.ammount_paid = null;
                this.title = null;
                this.customer = null;
                this.taxe = null;
                this.description = null;
                this.type = null;
                this.add_next_payday = false;
            }

            this.error_message = null;
            this.success_message = null;
            this.$emit("input", this.show);
        },
        customerData() {
            if (this.customerData) {
                this.customer = this.customers_list.find(customer => {
                    return customer.value.id === this.customerData.id;
                }).value;
            }
        },
        taxeData() {
            this.taxe = this.taxeData;
        },
        extractData() {
            if (this.extractData) {
                this.extract_date = this.extractData.extract_date;
                this.ammount_paid = this.extractData.ammount_paid / 100;
                this.title = this.extractData.title;
                this.description = this.extractData.description;
                this.type = this.extractData.type;
            }
        },
        taxe() {
            if (this.taxe) {
                this.next_payday = this.taxe.due_date
                this.add_next_payday = this.taxe.due_date ? true : false;
                this.$forceUpdate();
            }
        },
        customer() {
            if (this.customer) {
                this.next_payday = this.customer.payday
                this.add_next_payday = this.customer.payday ? true : false;
                this.$forceUpdate();
            }
        }
    },
    mounted() {
        dayjs.locale('pt-br');
        this.getCustomers();
        this.getTaxes();
        if (this.customerData) {
            this.customer = this.customers_list.find(customer => {
                return customer.value.id === this.customerData.id;
            }).value;
        }
        this.taxe = this.taxeData;
    },
    methods: {
        ...mapActions("extract", ["ActionDoUpdateExtract"]),
        ...mapActions("customers", ["ActionDoGetCustomers"]),
        ...mapActions("taxes", ["ActionDoGetTaxes"]),
        getCustomers() {
            if (!this.customers_list) {
                this.ActionDoGetCustomers();
            }
        },
        getTaxes() {
            if (!this.taxes_list) {
                this.ActionDoGetTaxes();
            }
        },
        formatDate(date) {
            return date ? dayjs(date).format("DD/MM/YYYY") : null;
        },
        formatUsa(date) {
            if (date) {
                if (date.length === 8) {
                    return dayjs(date, 'DDMMYYYY').format('YYYY-MM-DD');
                } else if (date.length === 10) {
                    return dayjs(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
                }
            }

            return null;
        },
        close() {
            this.$refs.formExtract.reset();
            this.show = false;
        },
        save() {
            this.loading = true;
            this.error_message = null;
            this.success_message = null;

            if (this.$refs.formExtract.validate()) {
                this.ActionDoUpdateExtract({
                    extract_date: this.extract_date,
                    ammount_paid: Number.isInteger(this.ammount_paid) ? this.ammount_paid * 100 : parseInt(this.ammount_paid.toString().replace(/[^0-9]+/g, "")),
                    type: this.type,
                    title: this.title,
                    description: this.description,
                    customer_id: this.customer?.id,
                    taxe_id: this.taxe?.id,
                    next_payday: this.add_next_payday ? this.next_payday : null,
                    extract_id: this.extractData.id
                }).then(() => {
                    this.success_message = "Registro editado com sucesso";
                    this.$refs.formExtract.reset();
                    this.loading = false;
                    this.$emit("edited");
                    this.show = false;
                }).catch(error => {
                    console.error(error);
                    this.error_message = error.response.data;
                    this.loading = false;
                });
            } else {
                this.loading = false;
            }
        }
    }
};
</script>
