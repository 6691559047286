var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"600px","persistent":_vm.loading,"scrollable":""},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',{staticClass:"pa-4"},[_c('v-card-title',[_vm._v(" Deletar cliente "),_c('i',[_vm._v(_vm._s(_vm.name))])]),_c('v-card-text',[_c('v-form',{ref:"formCustomer"},[_c('v-row',[(_vm.error_message)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"type":"error darken-4"}},[_vm._v(" "+_vm._s(_vm.error_message)+" ")])],1):_vm._e(),(_vm.success_message)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"type":"success"}},[_vm._v(" "+_vm._s(_vm.success_message)+" ")])],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',[_c('v-divider')],1),_c('v-col',{attrs:{"cols":"auto"}},[_vm._v(" Informações do cliente ")]),_c('v-col',[_c('v-divider')],1)],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"readonly":"","label":"Nome"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field-simplemask',{attrs:{"label":"CPF","properties":{
                                readonly: true
                            },"options":{
                                inputMask: '###.###.###-##',
                                outputMask: '###########',
                                empty: null,
                                applyAfter: false,
                                alphanumeric: true,
                                lowerCase: false,
                            }},model:{value:(_vm.cpf),callback:function ($$v) {_vm.cpf=$$v},expression:"cpf"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"Email","readonly":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field-simplemask',{attrs:{"label":"Telefone","properties":{
                                readonly: true
                            },"options":{
                                inputMask: '+## (##) #####-####',
                                outputMask: '#############',
                                empty: null,
                                applyAfter: false,
                                alphanumeric: true,
                                lowerCase: false,
                            }},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"label":"Status do cliente","items":[
                                { text: 'Primeiro contato', value: 'P' },
                                { text: 'Cliente ativo', value: 'A' },
                                { text: 'Cliente inativo', value: 'I' }
                            ],"readonly":""},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',[_c('v-divider')],1),_c('v-col',{attrs:{"cols":"auto"}},[_vm._v(" Informações do processo terapeutico ")]),_c('v-col',[_c('v-divider')],1)],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"label":"Tipo de pagamento","items":[
                                { text: 'Pagamento mensal', value: 'M' },
                                { text: 'Pagamento por sessão', value: 'S' }
                            ],"readonly":""},model:{value:(_vm.payment_type),callback:function ($$v) {_vm.payment_type=$$v},expression:"payment_type"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field-money',{attrs:{"label":"Valor a ser pago","properties":{
                                prefix: 'R$',
                                readonly: true
                            },"options":{
                                outputMask: '################',
                                locale: 'pt-BR',
                                length: 11,
                                precision: 2,
                                empty: null,
                            }},model:{value:(_vm.ammount),callback:function ($$v) {_vm.ammount=$$v},expression:"ammount"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"value":_vm.formatDate(_vm.payday),"label":"Dia do próximo pagamento","append-icon":"mdi-calendar","readonly":""}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"label":"Frequencia de pagamento","items":[
                                { text: 'Mensal', value: 'M' },
                                { text: 'Quinzenal', value: 'Q' },
                                { text: 'Semanal', value: 'S' }
                            ],"readonly":""},model:{value:(_vm.payment_frequency),callback:function ($$v) {_vm.payment_frequency=$$v},expression:"payment_frequency"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"label":"Dia da sessão","items":[
                                { text: 'Segunda', value: 'monday' },
                                { text: 'Terça', value: 'tuesday' },
                                { text: 'Quarta', value: 'wednesday' },
                                { text: 'Quinta', value: 'thursday' },
                                { text: 'Sexta', value: 'friday' },
                                { text: 'Sábado', value: 'saturday' },
                                { text: 'Domingo', value: 'sunday' }
                            ],"readonly":""},model:{value:(_vm.week_day_session),callback:function ($$v) {_vm.week_day_session=$$v},expression:"week_day_session"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"label":"Frequencia da sessão","items":[
                                { text: 'Mensal', value: 'M' },
                                { text: 'Quinzenal', value: 'Q' },
                                { text: 'Semanal', value: 'S' }
                            ],"readonly":""},model:{value:(_vm.session_frequency),callback:function ($$v) {_vm.session_frequency=$$v},expression:"session_frequency"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"label":"Observações","readonly":""},model:{value:(_vm.comments),callback:function ($$v) {_vm.comments=$$v},expression:"comments"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-row',{attrs:{"no-gutters":"","justify":"space-between"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"disabled":_vm.loading,"loading":_vm.loading,"dark":"","color":"primary-pink","elevation":"0"},on:{"click":_vm.close}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-arrow-u-left-top")]),_vm._v(" Voltar")],1)],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"disabled":_vm.loading,"loading":_vm.loading,"dark":"","color":"secondary-red","elevation":"0"},on:{"click":_vm.save}},[_vm._v("Deletar "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-check-bold")])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }