<template>
    <v-dialog v-model="show" max-width="1200px" scrollable>
        <v-card class="pa-4">
            <v-card-title>
                <v-row>
                    <v-col>
                        Contatos de emergência de &nbsp;<span class="primary-blue--text font-italic font-weight-bold">{{ customerData?.name }}</span>
                    </v-col>
                    <v-col cols="auto">
                        <v-btn elevation="0" dark color="primary-blue" @click="show_add_contact = true">Novo contato <v-icon right>mdi-plus-thick</v-icon></v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-data-table
                    :headers="headers"
                    :items="list_contacts"
                    sort-by="name"
                    sort-desc
                >
                    <template v-slot:[`item.phone`]="{ item }">
                        <a :href="'https://wa.me/+' + item.phone" target="_blank">{{ item.phone?.replace(/(\d{2})(\d{2})(\d{5})(\d{4})/, "+$1 ($2) $3-$4") }}</a>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-row no-gutters class="flex-nowrap">
                            <v-col cols="auto" class="px-1">
                                <v-tooltip top color="blue">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon @click="openEditContact(item)" color="blue" v-bind="attrs" v-on="on">
                                            <v-icon>
                                                mdi-account-edit-outline
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    Editar
                                </v-tooltip>
                            </v-col>
                            <v-col cols="auto" class="px-1">
                                <v-tooltip top color="error darken-4">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon @click="openDeleteContact(item)" color="error darken-4" v-bind="attrs" v-on="on">
                                            <v-icon>
                                                mdi-account-remove-outline
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    Deletar
                                </v-tooltip>
                            </v-col>
                        </v-row>
                    </template>
                </v-data-table>
            </v-card-text>
            <v-card-actions>
                <v-row no-gutters justify="space-between">
                    <v-col cols="auto">
                        <v-btn dark color="primary-pink" elevation="0" @click="close"><v-icon left>mdi-arrow-u-left-top</v-icon> Voltar</v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
        <dialog-add-emergency-contact v-model="show_add_contact" :customer-id="customerData?.id" @created="getContacts()" />
        <dialog-edit-emergency-contact v-model="show_edit_contact" :contact-data="contact_data" @edited="getContacts()" @close="closeEditContact()" />
        <dialog-delete-emergency-contact v-model="show_delete_contact" :contact-data="contact_data" @deleted="getContacts()" @close="closeDeleteContact()" />
    </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
import DialogAddEmergencyContact from "./DialogAddEmergencyContact.vue";
import DialogEditEmergencyContact from "./DialogEditEmergencyContact.vue";
import DialogDeleteEmergencyContact from "./DialogDeleteEmergencyContact.vue";

export default {
    components: {
        DialogAddEmergencyContact,
        DialogEditEmergencyContact,
        DialogDeleteEmergencyContact
    },
    props: {
        value: {
            type: Boolean,
            required: false,
            default: false
        },
        customerData: {
            type: Object,
            required: false,
            default: null
        }
    },
    data() {
        return {
            show: this.value,
            headers: [
                { text: "Nome", value: "name", sortable: true },
                { text: "Parentesco", value: "degree_kinship", sortable: true },
                { text: "Telefone", value: "phone", sortable: false },
                { text: '', value: 'actions', sortable: false },
            ],
            list_contacts: [],
            contact_data: null,
            show_add_contact: false,
            show_edit_contact: false,
            show_delete_contact: false
        };
    },
    watch: {
        value() {
            this.show = this.value;
        },
        show() {
            if (!this.show) {
                this.$emit("close");
            }

            this.$emit("input", this.show);
        },
        customerData() {
            this.getContacts();
        }
    },
    mounted() {
        this.getContacts();
    },
    methods: {
        ...mapActions("customers", ["ActionDoGetEmergencyContactsCustomer"]),
        getContacts() {
            if (this.customerData?.id) {
                this.ActionDoGetEmergencyContactsCustomer({customer_id: this.customerData.id}).then(response => {
                    this.list_contacts = response;
                });
            }
        },
        close() {
            this.show = false;
        },
        closeEditContact() {
            this.show_edit_contact = false;
            this.contact_data = null;
        },
        closeDeleteContact() {
            this.show_delete_contact = false;
            this.contact_data = null;
        },
        openEditContact(contact) {
            this.contact_data = contact;
            this.show_edit_contact = true;
        },
        openDeleteContact(contact) {
            this.contact_data = contact;
            this.show_delete_contact = true;
        },
    }
};
</script>
