var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"600px","persistent":_vm.loading,"scrollable":""},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',{staticClass:"pa-4"},[_c('v-card-title',[_vm._v(" Editar cliente "),_c('i',[_vm._v(" "+_vm._s(_vm.name))])]),_c('v-card-text',[_c('v-form',{ref:"formCustomer"},[_c('v-row',[(_vm.error_message)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"type":"error darken-4"}},[_vm._v(" "+_vm._s(_vm.error_message)+" ")])],1):_vm._e(),(_vm.success_message)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"type":"success"}},[_vm._v(" "+_vm._s(_vm.success_message)+" ")])],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',[_c('v-divider')],1),_c('v-col',{attrs:{"cols":"auto"}},[_vm._v(" Informações do cliente ")]),_c('v-col',[_c('v-divider')],1)],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"Nome","rules":[_vm.basic_rules.required]},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field-simplemask',{attrs:{"label":"CPF","options":{
                                inputMask: '###.###.###-##',
                                outputMask: '###########',
                                empty: null,
                                applyAfter: false,
                                alphanumeric: true,
                                lowerCase: false,
                            }},model:{value:(_vm.cpf),callback:function ($$v) {_vm.cpf=$$v},expression:"cpf"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"RG"},model:{value:(_vm.rg),callback:function ($$v) {_vm.rg=$$v},expression:"rg"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"Email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field-simplemask',{attrs:{"label":"Telefone","options":{
                                inputMask: '+## (##) #####-####',
                                outputMask: '#############',
                                empty: null,
                                applyAfter: false,
                                alphanumeric: true,
                                lowerCase: false,
                            }},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{ref:"menuBirthPicker",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.formatDate(_vm.birthday),"label":"Data de nascimento","append-icon":"mdi-calendar"},on:{"input":$event => _vm.birthday = _vm.formatUsa($event)}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.show_datepicker_birthday),callback:function ($$v) {_vm.show_datepicker_birthday=$$v},expression:"show_datepicker_birthday"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","editable":""},on:{"input":function($event){_vm.show_datepicker_birthday = false}},model:{value:(_vm.birthday),callback:function ($$v) {_vm.birthday=$$v},expression:"birthday"}})],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"label":"Status do cliente","items":[
                                { text: 'Primeiro contato', value: 'P' },
                                { text: 'Cliente ativo', value: 'A' },
                                { text: 'Cliente inativo', value: 'I' }
                            ],"rules":[_vm.basic_rules.required]},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-switch',{attrs:{"color":"primary-blue","label":"Contrato assinado"},model:{value:(_vm.signed_contract),callback:function ($$v) {_vm.signed_contract=$$v},expression:"signed_contract"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',[_c('v-divider')],1),_c('v-col',{attrs:{"cols":"auto"}},[_vm._v(" Informações do pagamento ")]),_c('v-col',[_c('v-divider')],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-switch',{attrs:{"color":"primary-blue","label":"O cliente é o pagador"},model:{value:(_vm.equal_payer),callback:function ($$v) {_vm.equal_payer=$$v},expression:"equal_payer"}})],1),(!_vm.equal_payer)?_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"Nome do pagador","rules":[_vm.basic_rules.required]},model:{value:(_vm.payer_name),callback:function ($$v) {_vm.payer_name=$$v},expression:"payer_name"}})],1):_vm._e(),(!_vm.equal_payer)?_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field-simplemask',{attrs:{"label":"CPF do pagador","options":{
                                inputMask: '###.###.###-##',
                                outputMask: '###########',
                                empty: null,
                                applyAfter: false,
                                alphanumeric: true,
                                lowerCase: false,
                            }},model:{value:(_vm.payer_cpf),callback:function ($$v) {_vm.payer_cpf=$$v},expression:"payer_cpf"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"label":"Tipo de pagamento","items":[
                                { text: 'Pagamento mensal', value: 'M' },
                                { text: 'Pagamento por sessão', value: 'S' }
                            ],"rules":[_vm.basic_rules.required]},model:{value:(_vm.payment_type),callback:function ($$v) {_vm.payment_type=$$v},expression:"payment_type"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field-money',{attrs:{"label":"Valor a ser pago","properties":{
                                prefix: 'R$',
                            },"options":{
                                outputMask: '################',
                                locale: 'pt-BR',
                                length: 11,
                                precision: 2,
                                empty: null,
                            }},model:{value:(_vm.ammount),callback:function ($$v) {_vm.ammount=$$v},expression:"ammount"}})],1),_c('v-col',{attrs:{"cols":_vm.payment_type !== 'M' ? 6 : 12}},[_c('v-menu',{ref:"menuDatePicker",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.formatDate(_vm.payday),"label":"Dia do próximo pagamento","append-icon":"mdi-calendar"},on:{"input":$event => _vm.payday = _vm.formatUsa($event)}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.show_datepicker),callback:function ($$v) {_vm.show_datepicker=$$v},expression:"show_datepicker"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","editable":""},on:{"input":function($event){_vm.show_datepicker = false}},model:{value:(_vm.payday),callback:function ($$v) {_vm.payday=$$v},expression:"payday"}})],1)],1),(_vm.payment_type !== 'M')?_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"label":"Frequencia de pagamento","items":[
                                { text: 'Mensal', value: 'M' },
                                { text: 'Quinzenal', value: 'Q' },
                                { text: 'Semanal', value: 'S' }
                            ],"rules":[_vm.basic_rules.required]},model:{value:(_vm.payment_frequency),callback:function ($$v) {_vm.payment_frequency=$$v},expression:"payment_frequency"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field-money',{attrs:{"label":"Saldo do cliente","properties":{
                                prefix: 'R$',
                            },"options":{
                                locale: 'pt-BR',
                                length: 11,
                                precision: 2,
                                empty: null,
                            }},model:{value:(_vm.balance),callback:function ($$v) {_vm.balance=$$v},expression:"balance"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',[_c('v-divider')],1),_c('v-col',{attrs:{"cols":"auto"}},[_vm._v(" Informações do processo terapeutico ")]),_c('v-col',[_c('v-divider')],1)],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"label":"Dia da sessão","items":[
                                { text: 'Segunda', value: 'monday' },
                                { text: 'Terça', value: 'tuesday' },
                                { text: 'Quarta', value: 'wednesday' },
                                { text: 'Quinta', value: 'thursday' },
                                { text: 'Sexta', value: 'friday' },
                                { text: 'Sábado', value: 'saturday' },
                                { text: 'Domingo', value: 'sunday' }
                            ],"rules":[_vm.basic_rules.required]},model:{value:(_vm.week_day_session),callback:function ($$v) {_vm.week_day_session=$$v},expression:"week_day_session"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{ref:"menuTimePicker",attrs:{"close-on-content-click":false,"return-value":_vm.hour_session,"transition":"scale-transition","offset-y":"","min-width":"300px"},on:{"update:returnValue":function($event){_vm.hour_session=$event},"update:return-value":function($event){_vm.hour_session=$event}},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Hora da sessão","append-icon":"mdi-calendar","readonly":"","rules":[_vm.basic_rules.required]},model:{value:(_vm.hour_session),callback:function ($$v) {_vm.hour_session=$$v},expression:"hour_session"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.show_timepicker),callback:function ($$v) {_vm.show_timepicker=$$v},expression:"show_timepicker"}},[(_vm.show_timepicker)?_c('v-time-picker',{attrs:{"format":"24hr","full-width":""},on:{"click:minute":function($event){return _vm.$refs.menuTimePicker.save(_vm.hour_session)}},model:{value:(_vm.hour_session),callback:function ($$v) {_vm.hour_session=$$v},expression:"hour_session"}}):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"label":"Frequencia da sessão","items":[
                                { text: 'Mensal', value: 'M' },
                                { text: 'Quinzenal', value: 'Q' },
                                { text: 'Semanal', value: 'S' }
                            ],"rules":[_vm.basic_rules.required]},model:{value:(_vm.session_frequency),callback:function ($$v) {_vm.session_frequency=$$v},expression:"session_frequency"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"Duração (em minutos)","type":"number"},model:{value:(_vm.session_duration),callback:function ($$v) {_vm.session_duration=$$v},expression:"session_duration"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"label":"Observações"},model:{value:(_vm.comments),callback:function ($$v) {_vm.comments=$$v},expression:"comments"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-row',{attrs:{"no-gutters":"","justify":"space-between"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"disabled":_vm.loading,"loading":_vm.loading,"dark":"","color":"primary-pink","elevation":"0"},on:{"click":_vm.close}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-arrow-u-left-top")]),_vm._v(" Voltar")],1)],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"disabled":_vm.loading,"loading":_vm.loading,"dark":"","color":"primary-blue","elevation":"0"},on:{"click":_vm.save}},[_vm._v("Salvar "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-check-bold")])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }