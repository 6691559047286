<template>
    <v-dialog v-model="show" max-width="1000px" :persistent="loading" scrollable>
        <v-card class="pa-4">
            <v-card-title>
                Gerar recibos
            </v-card-title>
            <v-card-text>
                <v-form ref="formGenerateVouchers">
                    <v-row>
                        <v-col v-if="error_message" cols="12">
                            <v-alert type="error darken-4">
                                {{ error_message }}
                            </v-alert>
                        </v-col>
                        <v-col v-if="success_message" cols="12">
                            <v-alert type="success">
                                {{ success_message }}
                            </v-alert>
                        </v-col>
                        <v-col cols="6">
                            <v-select
                                v-model="reference_month"
                                label="Mês de referência"
                                :disabled="loading"
                                :items="months"
                            />
                        </v-col>
                        <v-col cols="6">
                            <v-select
                                v-model="reference_year"
                                label="Ano de referência"
                                :disabled="loading"
                                :items="yearsList"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-data-table
                                :headers="headers"
                                :items="table_contents"
                                sort-by="payer_name"
                                :items-per-page="-1"
                                hide-default-footer
                                single-expand
                                show-expand
                                item-key="payer_name"
                            >
                                <template v-slot:[`item.ammount`]="{ item }">
                                    {{ (item.ammount / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}
                                </template>
                                <template v-slot:[`item.voucher`]="{ item }">
                                    <v-btn v-if="item.voucher && !item.downloaded" :loading="item.loading" icon color="secondary-blue" @click="download(item)">
                                        <v-icon>mdi-download</v-icon>
                                    </v-btn>
                                    <v-icon v-if="item.downloaded" color="success darken-4">mdi-check-bold</v-icon>
                                </template>
                                <template v-slot:expanded-item="{ headers, item }">
                                    <td :colspan="headers.length" class="primary-gray pa-4">
                                        <v-card class="pa-4">
                                            <v-row>
                                                <v-col cols="12" md="">
                                                    <v-data-table
                                                        :headers="[
                                                            { text: 'Data', value: 'extract_date', sortable: true },
                                                            { text: 'Valor', value: 'ammount_paid', sortable: true },
                                                            { text: 'Título', value: 'title', sortable: true },
                                                        ]"
                                                        :items="item.extracts"
                                                        sort-by="extract_date"
                                                        sort-desc
                                                        hide-default-footer
                                                        hide-default-header
                                                        :items-per-page="-1"
                                                        dense
                                                    >
                                                        <template v-slot:[`item.extract_date`]="{ item }">
                                                            {{ formatDate(item.extract_date) }}
                                                        </template>
                                                        <template v-slot:[`item.ammount_paid`]="{ item }">
                                                            {{ (item.ammount_paid / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}
                                                        </template>
                                                    </v-data-table>
                                                </v-col>
                                            </v-row>
                                        </v-card>
                                    </td>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-row no-gutters justify="space-between">
                    <v-col cols="auto">
                        <v-btn :disabled="loading" :loading="loading" dark color="primary-pink" elevation="0" @click="close"><v-icon left>mdi-arrow-u-left-top</v-icon> Voltar</v-btn>
                    </v-col>
                    <v-col cols="auto">
                        <v-btn :disabled="loading || disabled_button" :loading="loading" color="primary-blue" dark elevation="0" @click="generateVouchers">Gerar recibos <v-icon right>mdi-check-bold</v-icon></v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import dayjs from "dayjs";
import localeData from "dayjs/plugin/localeData";
import { mapActions } from "vuex";
import axios from "axios";
import html2pdf from "html2pdf.js";
import extenso from "extenso";
require("dayjs/locale/pt-br");

export default {
    props: {
        value: {
            type: Boolean,
            required: false,
            default: false
        },
        extracts: {
            type: Array,
            required: false,
            default: null
        }
    },
    data() {
        return {
            show: this.value,
            loading: false,
            error_message: null,
            success_message: null,
            reference_month: dayjs().subtract(1, 'month').format('MM'),
            reference_year: dayjs().subtract(1, 'month').format('YYYY'),
            months: [
                { value: '01', text: 'Janeiro' },
                { value: '02', text: 'Fevereiro' },
                { value: '03', text: 'Março' },
                { value: '04', text: 'Abril' },
                { value: '05', text: 'Maio' },
                { value: '06', text: 'Junho' },
                { value: '07', text: 'Julho' },
                { value: '08', text: 'Agosto' },
                { value: '09', text: 'Setembro' },
                { value: '10', text: 'Outubro' },
                { value: '11', text: 'Novembro' },
                { value: '12', text: 'Dezembro' }
            ],
            headers: [
                { text: "Pagador", value: "payer_name" },
                { text: "Valor total", value: "ammount" },
                { text: "Cliente", value: "customer_name" },
                { text: "", value: "voucher" },
                { text: '', value: 'data-table-expand' },
            ],
            generated: 0,
            table_contents: [],
            disabled_button: false
        };
    },
    computed: {
        yearsList() {
            let current_year = 2023;
            let years = [];

            while (current_year <= dayjs().format('YYYY')) {
                years.push(current_year.toString());
                current_year++;
            }

            return years;
        }
    },
    watch: {
        value() {
            this.show = this.value;
        },
        show() {
            if (!this.show) {
                this.$emit("close");

                this.loading = false;
                this.disabled_button = false;
                this.error_message = null;
                this.success_message = null;
                this.reference_month = null;
                this.reference_year = null;
            } else {
                this.reference_month = dayjs().subtract(1, 'month').format('MM');
                this.reference_year = dayjs().subtract(1, 'month').format('YYYY');
            }

            this.error_message = null;
            this.success_message = null;
            this.$emit("input", this.show);
        },
        extracts() {
            this.makeTableContents();
        }
    },
    mounted() {
        dayjs.extend(require('dayjs/plugin/customParseFormat'));
        dayjs.extend(localeData);
        dayjs.locale('pt-br');
        this.makeTableContents();
    },
    methods: {
        ...mapActions('vouchers', ['ActionDoCreateVoucher', 'ActionDoGetVoucher']),
        makeTableContents() {
            let content = {};

            for (let extract of this.extracts) {
                if (content[extract.customer.id] === undefined) {
                    content[extract.customer.id] = {
                        id: extract.customer.id,
                        payer_name: extract.customer.payer_name ?? extract.customer.name,
                        customer_name: extract.customer.name,
                        customer_data: extract.customer,
                        ammount: 0,
                        extracts: [],
                        voucher: null,
                        loading: false,
                        downloaded: false
                    }
                }

                content[extract.customer.id].ammount += extract.ammount_paid;
                content[extract.customer.id].extracts.push(extract);
            }

            this.table_contents = Object.values(content);
        },
        close() {
            this.$refs.formGenerateVouchers.reset();
            this.show = false;
        },
        formatDate(date) {
            return date ? dayjs(date).format("DD/MM/YYYY") : null;
        },
        generateVouchers() {
            this.loading = true;
            this.generated = 0;

            for (let voucher of this.table_contents) {
                this.ActionDoCreateVoucher({
                    customer_id: voucher.customer_data.id,
                    ammount: voucher.ammount,
                    voucher_date: dayjs().format('YYYY-MM-DD'),
                    extracts_ids: voucher.extracts.map(extract => extract.id),
                    payer_name: voucher.customer_data.payer_name && voucher.customer_data.payer_cpf ? voucher.customer_data.payer_name : voucher.customer_data.name,
                    payer_cpf: voucher.customer_data.payer_name && voucher.customer_data.payer_cpf ? voucher.customer_data.payer_cpf : voucher.customer_data.cpf,
                    reference_month_year: this.reference_year + '-' + this.reference_month
                }).then((response) => {
                    let data = this.table_contents.find(item => item.id === response.voucher.customer_id);
                    data.voucher = response.voucher.id;

                    this.generated++;

                    if (this.generated === this.table_contents.length) {
                        this.generated = 0;
                        this.disabled_button = true;
                        this.loading = false;
                    }
                });
            }
        },
        download(content) {
            let data = this.table_contents.find(item => item.id === content.id);
            data.loading = true;
            this.generatePDF(content.voucher).then(() => {
                data.loading = false;
                data.downloaded = true;
            });
        },
        generatePDF(voucher_id) {
            return this.ActionDoGetVoucher(voucher_id).then(voucher => {
                let options = {
                    margin: 25,
                    filename: voucher.payer_name.replace(/\s+/g, '_') + '_- Recibo_pagamento.pdf',
                    image: { type: 'jpeg', quality: 1 },
                    html2canvas: { dpi: 192, letterRendering: true },
                    jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
                }

                if (voucher.payer_name === voucher.customer.name) {
                    return axios.get('/voucher/voucher_same_payer.html').then(response => {
                        let html = response.data;
                        html = html.replace('[PAYER_NAME]', voucher.payer_name);
                        html = html.replace('[PAYER_CPF]', voucher.payer_cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4"));
                        html = html.replace('[AMMOUNT]', (voucher.ammount / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }));
                        html = html.replace('[AMMOUNT_IN_WORDS]', extenso((voucher.ammount / 100).toLocaleString('pt-BR'), { mode: 'currency' }));
                        html = html.replace('[MONTH_NAME]', dayjs(voucher.reference_month_year + '-01').format('MMMM [de] YYYY'));
                        html = html.replace('[DATE]', dayjs(voucher.voucher_date).format('DD [de] MMMM [de] YYYY'));

                        html2pdf().from(html).set(options).save();
                    });
                } else {
                    return axios.get('/voucher/voucher_other_payer.html').then(response => {
                        let html = response.data;
                        html = html.replace('[PAYER_NAME]', voucher.payer_name);
                        html = html.replace('[PAYER_CPF]', voucher.payer_cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4"));
                        html = html.replace('[AMMOUNT]', (voucher.ammount / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }));
                        html = html.replace('[AMMOUNT_IN_WORDS]', extenso((voucher.ammount / 100).toLocaleString('pt-BR'), { mode: 'currency' }));
                        html = html.replace('[CUSTOMER_NAME]', voucher.customer.name);
                        html = html.replace('[CUSTOMER_CPF]', voucher.customer.cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4"));
                        html = html.replace('[MONTH_NAME]', dayjs(voucher.reference_month_year + '-01').format('MMMM [de] YYYY'));
                        html = html.replace('[DATE]', dayjs(voucher.voucher_date).format('DD [de] MMMM [de] YYYY'));

                        html2pdf().from(html).set(options).save();
                    });
                }
            });
        }
    }
};
</script>
