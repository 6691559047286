import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import pt from 'vuetify/lib/locale/pt';

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
      locales: { pt },
      current: 'pt',
    },
    theme: {
        themes: {
            light: {
                "primary-blue": "#8696a7", // Azul 8696a7
                "primary-pink": "#cab9bb", // Rosa cab9bb
                "primary-gray": "#f3f3f3", // Cinza f3f3f3
                "primary-white": "#ffffff", // Branco
                "secondary-pink": "#b79ea1", // Cor adicional - Um rosa suave que harmoniza com o #e3d0d2
                "secondary-blue": "#5c7b8a", // Cor adicional - Um tom mais escuro de azul acinzentado, complementar ao #8696a6
                "secondary-gray": "#d8d8d8", // Cor adicional - Um cinza claro neutro que funciona bem com cores claras como #f3f3f3 e #ffffff
                "secondary-orange": "#f0d1b5", // Cor adicional - Um tom pastel de laranja, adicionando um toque de calor à paleta
                "secondary-green": "#9dbcc1", // Cor adicional - Um verde água suave que complementa os tons mais frios da sua paleta
                "secondary-purple": "#c9b6c6", // Cor adicional - Um lavanda claro, oferecendo uma cor suave e feminina
                "secondary-yellow": "#f1e3c6", // Cor adicional - Um amarelo cremoso, adicionando uma cor luminosa e alegre
                "secondary-red": "#d87c7b", // Cor adicional - Este é um vermelho suave e levemente desaturado, que pode complementar os tons mais frios da sua paleta sem desequilibrar a harmonia geral
                "tertiary-gray": "#a5a5a5", // Cor adicional - Um cinza médio que pode ser uma transição suave entre os tons mais claros e escuros
                "tertiary-red": "#aa4d4c", // Cor adicional - Este é um vermelho mais escuro, mas ainda com uma tonalidade suave e não tão vibrante, o que o torna adequado para combinar com os tons mais suaves e neutros da sua paleta.

                "blue": "#546475",
                "orange": "#91471D",
                "tertiary": "#589fa4",
            }
        }
    }
});