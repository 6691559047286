<template>
    <v-app>
        <side-menu v-if="user" />
        <v-main class="primary-gray">
            <v-container fluid :class="!user ? 'ma-0 pa-0' : ''">
                <the-notify />
                <router-view />
            </v-container>
        </v-main>
        <the-float-button v-if="user" />
    </v-app>
</template>

<script>
import SideMenu from './components/SideMenu.vue';
import TheNotify from './components/TheNotify.vue';
import TheFloatButton from './components/TheFloatButton.vue';
import { mapState } from 'vuex';

export default {
    components: {
        SideMenu,
        TheNotify,
        TheFloatButton
    },
    computed: {
        ...mapState("auth", {
            user: state => state.user
        })
    }
};
</script>
