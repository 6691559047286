<template>
    <v-dialog v-model="show" max-width="600px" :persistent="loading" scrollable>
        <v-card class="pa-4">
            <v-card-title>
                Excluir registro financeiro
            </v-card-title>
            <v-card-text>
                <v-form ref="formExtract">
                    <v-row>
                        <v-col v-if="error_message" cols="12">
                            <v-alert type="error darken-4">
                                {{ error_message }}
                            </v-alert>
                        </v-col>
                        <v-col v-if="success_message" cols="12">
                            <v-alert type="success">
                                {{ success_message }}
                            </v-alert>
                        </v-col>
                        <v-col :cols="type ? 6 : 12">
                            <v-select
                                v-model="type"
                                label="Tipo"
                                readonly
                                :items="[
                                    { text: 'Recebido de cliente', value: 'R' },
                                    { text: 'Pagamento de despesa programada', value: 'D' },
                                ]"
                            />
                        </v-col>
                        <v-col v-if="type" :cols="6">
                            <v-select
                                v-if="type === 'R'"
                                v-model="customer"
                                label="Cliente"
                                readonly
                                :items="customers_list"
                            />
                            <v-select
                                v-if="type === 'D'"
                                v-model="taxe"
                                label="Imposto"
                                readonly
                                :items="taxes_list"
                            />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                :value="formatDate(extract_date)"
                                label="Data do registro"
                                append-icon="mdi-calendar"
                                readonly
                            ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field-money
                                v-model="ammount_paid"
                                label="Valor"
                                :properties="{
                                    prefix: 'R$',
                                    readonly: true
                                }"
                                :options="{
                                    locale: 'pt-BR',
                                    length: 11,
                                    precision: 2,
                                    empty: null,
                                }"
                            />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                :value="formatDate(next_payday)"
                                label="Data do próx. pagamento"
                                append-icon="mdi-calendar"
                                readonly
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field v-model="title" label="Título do registro" readonly />
                        </v-col>
                        <v-col cols="12">
                            <v-textarea v-model="description" label="Descrição" readonly />
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-row no-gutters justify="space-between">
                    <v-col cols="auto">
                        <v-btn :disabled="loading" :loading="loading" dark color="primary-pink" elevation="0" @click="close"><v-icon left>mdi-arrow-u-left-top</v-icon> Voltar</v-btn>
                    </v-col>
                    <v-col cols="auto">
                        <v-btn :disabled="loading" :loading="loading" dark color="secondary-red" elevation="0" @click="save">Remover <v-icon right>mdi-check-bold</v-icon></v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import dayjs from "dayjs";
import { mapActions, mapState } from 'vuex';

dayjs.locale('pt-br');

export default {
    props: {
        value: {
            type: Boolean,
            required: false,
            default: false
        },
        customerData: {
            type: Object,
            required: false,
            default: null
        },
        taxeData: {
            type: Object,
            required: false,
            default: null
        },
        extractData: {
            type: Object,
            required: false,
            default: null
        }
    },
    data() {
        return {
            show: this.value,
            basic_rules: {
                required: value => !!value || "Informação obrigatória"
            },
            show_datepicker_extract: false,
            loading: false,
            error_message: null,
            success_message: null,
            show_next_payday: false,
            next_payday: null,
            extract_date: dayjs().format('YYYY-MM-DD'),
            ammount_paid: null,
            title: null,
            customer: null,
            taxe: null,
            description: null,
            type: null
        };
    },
    computed: {
        ...mapState("customers", {
            customers_list: state => state.customers_list?.filter(customer => {
                return customer.status != 'I';
            })?.map(customer => {
                return { text: customer.name, value: customer };
            })
        }),
        ...mapState("taxes", {
            taxes_list: state => state.taxes_list?.map(taxe => {
                return { text: taxe.title, value: taxe };
            })
        })
    },
    watch: {
        value() {
            this.show = this.value;
        },
        show() {
            if (!this.show) {
                this.$emit("close");

                this.show_datepicker_extract = false;
                this.loading = false;
                this.error_message = null;
                this.success_message = null;
                this.show_next_payday = false;
                this.next_payday = null;
                this.extract_date = null;
                this.ammount_paid = null;
                this.title = null;
                this.customer = null;
                this.taxe = null;
                this.description = null;
                this.type = null;
            }

            this.error_message = null;
            this.success_message = null;
            this.$emit("input", this.show);
        },
        customerData() {
            if (this.customerData) {
                this.customer = this.customers_list.find(customer => {
                    return customer.value.id === this.customerData.id;
                }).value;
            }
        },
        taxeData() {
            this.taxe = this.taxeData;
        },
        extractData() {
            if (this.extractData) {
                this.extract_date = this.extractData.extract_date;
                this.ammount_paid = this.extractData.ammount_paid / 100;
                this.title = this.extractData.title;
                this.description = this.extractData.description;
                this.type = this.extractData.type;
            }
        },
        taxe() {
            if (this.taxe) {
                this.next_payday = this.taxe.due_date
                this.$forceUpdate();
            }
        },
        customer() {
            if (this.customer) {
                this.next_payday = this.customer.payday
                this.$forceUpdate();
            }
        }
    },
    mounted() {
        dayjs.locale('pt-br');
        this.getCustomers();
        this.getTaxes();
        if (this.customerData) {
            this.customer = this.customers_list.find(customer => {
                return customer.value.id === this.customerData.id;
            }).value;
        }
        this.taxe = this.taxeData;
    },
    methods: {
        ...mapActions("extract", ["ActionDoDeleteExtract"]),
        ...mapActions("customers", ["ActionDoGetCustomers"]),
        ...mapActions("taxes", ["ActionDoGetTaxes"]),
        getCustomers() {
            if (!this.customers_list) {
                this.ActionDoGetCustomers();
            }
        },
        getTaxes() {
            if (!this.taxes_list) {
                this.ActionDoGetTaxes();
            }
        },
        formatDate(date) {
            return date ? dayjs(date).format("DD/MM/YYYY") : null;
        },
        close() {
            this.$refs.formExtract.reset();
            this.show = false;
        },
        save() {
            this.loading = true;
            this.error_message = null;
            this.success_message = null;

            if (this.$refs.formExtract.validate()) {
                this.ActionDoDeleteExtract(this.extractData.id).then(() => {
                    this.success_message = "Registro deletado com sucesso";
                    this.$refs.formExtract.reset();
                    this.loading = false;
                    this.$emit("deleted");
                    this.show = false;
                }).catch(error => {
                    console.error(error);
                    this.error_message = error.response.data;
                    this.loading = false;
                });
            } else {
                this.loading = false;
            }
        }
    }
};
</script>
