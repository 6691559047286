<template>
    <v-dialog v-model="show" max-width="1200px" scrollable>
        <v-card class="pa-4">
            <v-card-title>
                Pagamentos de &nbsp;<span class="primary-blue--text font-italic font-weight-bold">{{ customerData?.name ?? taxeData?.title }}</span>
            </v-card-title>
            <v-card-text>
                <v-data-table
                    :headers="headers"
                    :items="customerData?.extracts ?? taxeData?.extracts"
                    sort-by="extract_date"
                    sort-desc
                >
                    <template v-slot:[`item.extract_date`]="{ item }">
                        {{ formatDate(item.extract_date) }}
                    </template>
                    <template v-slot:[`item.ammount_paid`]="{ item }">
                        {{ (item.ammount_paid / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-row no-gutters class="flex-nowrap">
                            <v-col cols="auto" class="px-1">
                                <v-btn icon @click="openEditExtract(item)" color="primary-blue">
                                    <v-icon>
                                        mdi-pencil
                                    </v-icon>
                                </v-btn>
                            </v-col>
                            <v-col cols="auto" class="px-1">
                                <v-btn icon @click="openDeleteExtract(item)" color="error darken-4">
                                    <v-icon>
                                        mdi-delete-empty-outline
                                    </v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </template>
                </v-data-table>
            </v-card-text>
            <v-card-actions>
                <v-row no-gutters justify="space-between">
                    <v-col cols="auto">
                        <v-btn dark color="primary-pink" elevation="0" @click="close"><v-icon left>mdi-arrow-u-left-top</v-icon> Voltar</v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
            <dialog-edit-extract :customer-data="show_edit_extract ? customerData : null" :taxe-data="show_edit_extract ? taxeData : null" :extract-data="extract_data" v-model="show_edit_extract" @close="closeEditExtract" @edited="$emit('updateData')" />
            <dialog-delete-extract :customer-data="show_delete_extract ? customerData : null" :taxe-data="show_delete_extract ? taxeData : null" :extract-data="extract_data" v-model="show_delete_extract" @close="closeDeleteExtract" @deleted="$emit('updateData')" />
        </v-card>
    </v-dialog>
</template>

<script>
import dayjs from "dayjs";
dayjs.extend(require('dayjs/plugin/customParseFormat'));

import DialogEditExtract from './DialogEditExtract.vue';
import DialogDeleteExtract from './DialogDeleteExtract.vue';

export default {
    components: {
        DialogEditExtract,
        DialogDeleteExtract
    },
    props: {
        value: {
            type: Boolean,
            required: false,
            default: false
        },
        customerData: {
            type: Object,
            required: false,
            default: null
        },
        taxeData: {
            type: Object,
            required: false,
            default: null
        }
    },
    data() {
        return {
            show: this.value,
            headers: [
                { text: "Data", value: "extract_date", sortable: true },
                { text: "Valor", value: "ammount_paid", sortable: true },
                { text: "Título", value: "title", sortable: true },
                { text: "Descrição", value: "description", sortable: true },
                { text: '', value: 'actions', sortable: false },
            ],
            extract_data: null,
            show_edit_extract: false,
            show_delete_extract: false
        };
    },
    watch: {
        value() {
            this.show = this.value;
        },
        show() {
            if (!this.show) {
                this.$emit("close");
            }

            this.$emit("input", this.show);
        }
    },
    methods: {
        formatDate(date) {
            return date ? dayjs(date).format("DD/MM/YYYY") : null;
        },
        close() {
            this.show = false;
        },
        closeEditExtract() {
            this.show_edit_extract = false;
            this.extract_data = null;
        },
        closeDeleteExtract() {
            this.show_delete_extract = false;
            this.extract_data = null;
        },
        openEditExtract(extract) {
            this.extract_data = extract;
            this.show_edit_extract = true;
        },
        openDeleteExtract(extract) {
            this.extract_data = extract;
            this.show_delete_extract = true;
        }
    }
};
</script>
