<template>
    <v-dialog v-model="show" max-width="600px" :persistent="loading" scrollable>
        <v-card class="pa-4">
            <v-card-title>
                Editar cliente  <i>&nbsp;{{ name }}</i>
            </v-card-title>
            <v-card-text>
                <v-form ref="formCustomer">
                    <v-row>
                        <v-col v-if="error_message" cols="12">
                            <v-alert type="error darken-4">
                                {{ error_message }}
                            </v-alert>
                        </v-col>
                        <v-col v-if="success_message" cols="12">
                            <v-alert type="success">
                                {{ success_message }}
                            </v-alert>
                        </v-col>
                        <v-col cols="12">
                            <v-row align="center">
                                <v-col>
                                    <v-divider></v-divider>
                                </v-col>
                                <v-col cols="auto">
                                    Informações do cliente
                                </v-col>
                                <v-col>
                                    <v-divider></v-divider>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field v-model="name" label="Nome" :rules="[basic_rules.required]" />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field-simplemask
                                v-model="cpf"
                                label="CPF"
                                :options="{
                                    inputMask: '###.###.###-##',
                                    outputMask: '###########',
                                    empty: null,
                                    applyAfter: false,
                                    alphanumeric: true,
                                    lowerCase: false,
                                }"
                            />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field v-model="rg" label="RG" />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field v-model="email" label="Email" />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field-simplemask
                                v-model="phone"
                                label="Telefone"
                                :options="{
                                    inputMask: '+## (##) #####-####',
                                    outputMask: '#############',
                                    empty: null,
                                    applyAfter: false,
                                    alphanumeric: true,
                                    lowerCase: false,
                                }"
                            />
                        </v-col>
                        <v-col cols="6">
                            <v-menu
                                ref="menuBirthPicker"
                                v-model="show_datepicker_birthday"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        :value="formatDate(birthday)"
                                        label="Data de nascimento"
                                        append-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        @input="$event => birthday = formatUsa($event)"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="birthday"
                                    no-title
                                    scrollable
                                    editable
                                    @input="show_datepicker_birthday = false"
                                />
                            </v-menu>
                        </v-col>
                        <v-col cols="6">
                            <v-select
                                v-model="status"
                                label="Status do cliente"
                                :items="[
                                    { text: 'Primeiro contato', value: 'P' },
                                    { text: 'Cliente ativo', value: 'A' },
                                    { text: 'Cliente inativo', value: 'I' }
                                ]"
                                :rules="[basic_rules.required]"
                            />
                        </v-col>
                        <v-col cols="6">
                            <v-switch
                                v-model="signed_contract"
                                color="primary-blue"
                                label="Contrato assinado"
                            ></v-switch>
                        </v-col>
                        <v-col cols="12">
                            <v-row align="center">
                                <v-col>
                                    <v-divider></v-divider>
                                </v-col>
                                <v-col cols="auto">
                                    Informações do pagamento
                                </v-col>
                                <v-col>
                                    <v-divider></v-divider>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12">
                            <v-switch
                                v-model="equal_payer"
                                color="primary-blue"
                                label="O cliente é o pagador"
                            ></v-switch>
                        </v-col>
                        <v-col v-if="!equal_payer" cols="6">
                            <v-text-field v-model="payer_name" label="Nome do pagador" :rules="[basic_rules.required]" />
                        </v-col>
                        <v-col v-if="!equal_payer" cols="6">
                            <v-text-field-simplemask
                                v-model="payer_cpf"
                                label="CPF do pagador"
                                :options="{
                                    inputMask: '###.###.###-##',
                                    outputMask: '###########',
                                    empty: null,
                                    applyAfter: false,
                                    alphanumeric: true,
                                    lowerCase: false,
                                }"
                            />
                        </v-col>
                        <v-col cols="6">
                            <v-select
                                v-model="payment_type"
                                label="Tipo de pagamento"
                                :items="[
                                    { text: 'Pagamento mensal', value: 'M' },
                                    { text: 'Pagamento por sessão', value: 'S' }
                                ]"
                                :rules="[basic_rules.required]"
                            />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field-money
                                v-model="ammount"
                                label="Valor a ser pago"
                                :properties="{
                                    prefix: 'R$',
                                }"
                                :options="{
                                    outputMask: '################',
                                    locale: 'pt-BR',
                                    length: 11,
                                    precision: 2,
                                    empty: null,
                                }"
                            />
                        </v-col>
                        <v-col :cols="payment_type !== 'M' ? 6 : 12">
                            <v-menu
                                ref="menuDatePicker"
                                v-model="show_datepicker"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        :value="formatDate(payday)"
                                        label="Dia do próximo pagamento"
                                        append-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        @input="$event => payday = formatUsa($event)"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="payday"
                                    no-title
                                    scrollable
                                    editable
                                    @input="show_datepicker = false"
                                />
                            </v-menu>
                        </v-col>
                        <v-col v-if="payment_type !== 'M'" cols="6">
                            <v-select
                                v-model="payment_frequency"
                                label="Frequencia de pagamento"
                                :items="[
                                    { text: 'Mensal', value: 'M' },
                                    { text: 'Quinzenal', value: 'Q' },
                                    { text: 'Semanal', value: 'S' }
                                ]"
                                :rules="[basic_rules.required]"
                            />
                        </v-col>
                        <v-col cols="12">
                            <v-text-field-money
                                v-model="balance"
                                label="Saldo do cliente"
                                :properties="{
                                    prefix: 'R$',
                                }"
                                :options="{
                                    locale: 'pt-BR',
                                    length: 11,
                                    precision: 2,
                                    empty: null,
                                }"
                            />
                        </v-col>
                        <v-col cols="12">
                            <v-row align="center">
                                <v-col>
                                    <v-divider></v-divider>
                                </v-col>
                                <v-col cols="auto">
                                    Informações do processo terapeutico
                                </v-col>
                                <v-col>
                                    <v-divider></v-divider>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="6">
                            <v-select
                                v-model="week_day_session"
                                label="Dia da sessão"
                                :items="[
                                    { text: 'Segunda', value: 'monday' },
                                    { text: 'Terça', value: 'tuesday' },
                                    { text: 'Quarta', value: 'wednesday' },
                                    { text: 'Quinta', value: 'thursday' },
                                    { text: 'Sexta', value: 'friday' },
                                    { text: 'Sábado', value: 'saturday' },
                                    { text: 'Domingo', value: 'sunday' }
                                ]"
                                :rules="[basic_rules.required]"
                            />
                        </v-col>
                        <v-col cols="6">
                            <v-menu
                                ref="menuTimePicker"
                                v-model="show_timepicker"
                                :close-on-content-click="false"
                                :return-value.sync="hour_session"
                                transition="scale-transition"
                                offset-y
                                min-width="300px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="hour_session"
                                        label="Hora da sessão"
                                        append-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        :rules="[basic_rules.required]"
                                    ></v-text-field>
                                </template>
                                <v-time-picker
                                    v-if="show_timepicker"
                                    v-model="hour_session"
                                    format="24hr"
                                    full-width
                                    @click:minute="$refs.menuTimePicker.save(hour_session)"
                                />
                            </v-menu>
                        </v-col>
                        <v-col cols="6">
                            <v-select
                                v-model="session_frequency"
                                label="Frequencia da sessão"
                                :items="[
                                    { text: 'Mensal', value: 'M' },
                                    { text: 'Quinzenal', value: 'Q' },
                                    { text: 'Semanal', value: 'S' }
                                ]"
                                :rules="[basic_rules.required]"
                            />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field v-model="session_duration" label="Duração (em minutos)" type="number" />
                        </v-col>
                        <v-col cols="12">
                            <v-textarea v-model="comments" label="Observações" />
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-row no-gutters justify="space-between">
                    <v-col cols="auto">
                        <v-btn :disabled="loading" :loading="loading" dark color="primary-pink" elevation="0" @click="close"><v-icon left>mdi-arrow-u-left-top</v-icon> Voltar</v-btn>
                    </v-col>
                    <v-col cols="auto">
                        <v-btn :disabled="loading" :loading="loading" dark color="primary-blue" elevation="0" @click="save">Salvar <v-icon right>mdi-check-bold</v-icon></v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import dayjs from "dayjs";
dayjs.extend(require('dayjs/plugin/customParseFormat'));
import { mapActions } from 'vuex';

export default {
    props: {
        value: {
            type: Boolean,
            required: false,
            default: false
        },
        customerData: {
            type: Object,
            required: false,
            default() {
                return {};
            }
        }
    },
    data() {
        return {
            show: this.value,
            basic_rules: {
                required: value => !!value || "Informação obrigatória",
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return pattern.test(value) || 'Email inválido'
                }
            },
            show_datepicker: false,
            show_datepicker_birthday: false,
            loading: false,
            error_message: null,
            success_message: null,
            name: null,
            cpf: null,
            rg: null,
            email: null,
            phone: null,
            status: "P",
            signed_contract: false,
            ammount: null,
            payment_type: "M",
            payday: null,
            birthday: null,
            payment_frequency: "M",
            week_day_session: null,
            session_frequency: "S",
            session_duration: 50,
            comments: null,
            show_timepicker: false,
            hour_session: null,
            payer_name: null,
            payer_cpf: null,
            equal_payer: true,
            balance: 0
        };
    },
    watch: {
        value() {
            this.error_message = null;
            this.success_message = null;
            this.show = this.value;
        },
        show() {
            if (!this.show) {
                this.$emit("close");

                this.name = null;
                this.cpf = null;
                this.email = null;
                this.rg = null;
                this.phone = null;
                this.birthday = null;
                this.status = null;
                this.signed_contract = false;
                this.ammount = null;
                this.payment_type = null;
                this.payday = null;
                this.payment_frequency = null;
                this.week_day_session = null;
                this.session_frequency = null;
                this.session_duration = null;
                this.hour_session = null;
                this.comments = null;
                this.payer_name = null;
                this.payer_cpf = null;
                this.equal_payer = null;
                this.balance = null;
            }
            this.$emit("input", this.show);
        },
        customerData() {
            if (this.customerData) {
                this.name = this.customerData.name;
                this.cpf = this.customerData.cpf;
                this.email = this.customerData.email;
                this.rg = this.customerData.rg;
                this.phone = this.customerData.phone;
                this.birthday = this.customerData.birthday;
                this.status = this.customerData.status;
                this.signed_contract = this.customerData.signed_contract;
                this.ammount = this.customerData.ammount / 100;
                this.payment_type = this.customerData.payment_type;
                this.payday = this.customerData.payday;
                this.payment_frequency = this.customerData.payment_frequency;
                this.week_day_session = this.customerData.week_day_session;
                this.session_frequency = this.customerData.session_frequency;
                this.session_duration = this.customerData.session_duration;
                this.hour_session = this.customerData.hour_session;
                this.comments = this.customerData.comments;
                this.payer_name = this.customerData.payer_name;
                this.payer_cpf = this.customerData.payer_cpf;
                this.equal_payer = this.customerData.payer_name ? false : true;
                this.balance = this.customerData.balance / 100;
            }
        },
        signed_contract(new_value) {
            if (new_value) {
                this.status = 'A';
            }
        }
    },
    methods: {
        ...mapActions("customers", ["ActionDoUpdateCustomer", "ActionDoGetCustomers"]),
        formatDate(date) {
            return date ? dayjs(date).format("DD/MM/YYYY") : null;
        },
        formatUsa(date) {
            if (date) {
                if (date.length === 8) {
                    return dayjs(date, 'DDMMYYYY').format('YYYY-MM-DD');
                } else if (date.length === 10) {
                    return dayjs(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
                }
            }

            return null;
        },
        close() {
            this.$refs.formCustomer.reset();
            this.show = false;
        },
        save() {
            this.loading = true;
            this.error_message = null;
            this.success_message = null;

            if (this.$refs.formCustomer.validate()) {
                this.ActionDoUpdateCustomer({
                    name: this.name,
                    cpf: this.cpf,
                    email: this.email,
                    rg: this.rg,
                    phone: this.phone,
                    birthday: this.birthday,
                    ammount: Number.isInteger(this.ammount) ? this.ammount * 100 : parseInt(this.ammount.toString().replace(/[^0-9]+/g, "")),
                    payment_type: this.payment_type,
                    status: this.status,
                    signed_contract: this.signed_contract,
                    payday: this.payday,
                    payment_frequency: this.payment_frequency,
                    session_frequency: this.session_frequency,
                    session_duration: this.session_duration,
                    week_day_session: this.week_day_session,
                    hour_session: this.hour_session,
                    next_session: null,
                    comments: this.comments,
                    payer_name: this.payer_name,
                    payer_cpf: this.payer_cpf,
                    balance: Number.isInteger(this.balance) ? this.balance * 100 : parseInt(this.balance.toString().replace(/[^0-9]+/g, "")),
                    customer_id: this.customerData.id
                }).then(() => {
                    this.ActionDoGetCustomers();

                    this.success_message = "Cliente editado com sucesso";
                    this.$refs.formCustomer.reset();
                    this.loading = false;
                    this.$emit("edited");
                    this.show = false;
                }).catch(error => {
                    console.error(error);
                    this.error_message = error.response.data;
                    this.loading = false;
                });
            } else {
                this.loading = false;
            }
        }
    }
};
</script>
