<template>
    <!-- <v-row class="fill-height" no-gutters>
        <v-col class="fill-height">
            <v-img :height="$vuetify.breakpoint.height" width="100%" src="/images/background_image.png"> -->
            <v-card :height="$vuetify.breakpoint.height" color="primary-pink">
                <v-row class="fill-height" justify="center" align="center">
                    <v-col cols="auto">
                        <v-card class="pa-15" max-width="500px" rounded="lg" color="primary-white">
                            <v-form ref="formLogin">
                                <v-row>
                                    <v-col cols="12">
                                        <v-row align="center" justify="center">
                                            <v-col cols="auto">
                                                <v-img max-width="100px" contain src="/images/new_logo_pink.png" />
                                            </v-col>
                                            <v-col cols="auto" class="text-center primary-pink--text">
                                                <span class="text-h5">Melissa Andrade</span><br>
                                                <span class="text-body-2">Gestão & Controle</span>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col v-if="error_message" cols="12">
                                        <v-alert type="error darken-4">
                                            {{ error_message }}
                                        </v-alert>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field
                                            v-model="email"
                                            label="Email"
                                            :disabled="loading"
                                            :rules="email_rules"
                                            color="primary-pink"
                                        />
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field
                                            v-model="password"
                                            label="Senha"
                                            :disabled="loading"
                                            :rules="password_rules"
                                            type="password"
                                            color="primary-pink"
                                        />
                                    </v-col>
                                    <v-col cols="12" class="d-flex justify-center">
                                        <v-btn color="primary-pink" dark :loading="loading" :disabled="loading" @click="login">Entrar</v-btn>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card>
            <!-- </v-img>
        </v-col>
    </v-row> -->
</template>

<script>
import { mapActions } from "vuex";
import * as types from "./store/mutations-types";

export default {
    data() {
        return {
            email: null,
            password: null,
            loading: false,
            email_rules: [
                v => !!v || "Digite um email",
                v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || "Digite um email válido"
            ],
            password_rules: [
                v => !!v || "Digite uma senha"
            ],
            error_message: null
        };
    },
    mounted() {
        this.$store.commit("auth/" + types.SET_TOKEN, null);
        this.$store.commit("auth/" + types.SET_USER, null);
    },
    methods: {
        ...mapActions("auth", ["ActionDoLogin"]),
        async login() {
            this.loading = true;

            if (this.$refs.formLogin.validate()) {
                this.error_message = null;

                try {
                    await this.ActionDoLogin({ email: this.email, password: this.password }).then(response => {
                        if (response.error === "") {
                            this.$router.push({name: "schedule"});
                            this.loading = false;
                        } else {
                            this.loading = false;
                            this.error_mesasge = response.error;
                        }
                    }).catch(error => {
                        this.loading = false;

                        if (error?.response?.data?.error) {
                            this.error_message = error.response.data.error;
                        } else {
                            this.error_message = error.message;
                        }
                    });
                } catch (error) {
                    this.loading = false;
                    this.error_mesasge = error.response.data.error;
                }
            } else {
                this.loading = false;
            }
        }
    }
};
</script>
