import { store as auth } from "../views/auth";
import { store as customers } from "../views/customers";
import { store as taxes } from "../views/taxes";
import { store as sessions } from "../views/sessions";
import { store as extract } from "../views/extract";
import { store as vouchers } from "../views/vouchers";
import { store as utils } from "../utils";
import { store as schedule } from "../views/schedule";
import { store as dashboard } from "../views/dashboard";

export default {
    auth,
    customers,
    taxes,
    sessions,
    extract,
    vouchers,
    utils,
    schedule,
    dashboard
};
