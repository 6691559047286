<template>
    <v-dialog v-model="show" max-width="600px" :persistent="loading" scrollable>
        <v-card class="pa-4">
            <v-card-title>
                Deletar cliente <i>{{ name }}</i>
            </v-card-title>
            <v-card-text>
                <v-form ref="formCustomer">
                    <v-row>
                        <v-col v-if="error_message" cols="12">
                            <v-alert type="error darken-4">
                                {{ error_message }}
                            </v-alert>
                        </v-col>
                        <v-col v-if="success_message" cols="12">
                            <v-alert type="success">
                                {{ success_message }}
                            </v-alert>
                        </v-col>
                        <v-col cols="12">
                            <v-row align="center">
                                <v-col>
                                    <v-divider></v-divider>
                                </v-col>
                                <v-col cols="auto">
                                    Informações do cliente
                                </v-col>
                                <v-col>
                                    <v-divider></v-divider>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field v-model="name" readonly label="Nome" />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field-simplemask
                                v-model="cpf"
                                label="CPF"
                                :properties="{
                                    readonly: true
                                }"
                                :options="{
                                    inputMask: '###.###.###-##',
                                    outputMask: '###########',
                                    empty: null,
                                    applyAfter: false,
                                    alphanumeric: true,
                                    lowerCase: false,
                                }"
                            />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field v-model="email" label="Email" readonly />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field-simplemask
                                v-model="phone"
                                label="Telefone"
                                :properties="{
                                    readonly: true
                                }"
                                :options="{
                                    inputMask: '+## (##) #####-####',
                                    outputMask: '#############',
                                    empty: null,
                                    applyAfter: false,
                                    alphanumeric: true,
                                    lowerCase: false,
                                }"
                            />
                        </v-col>
                        <v-col cols="12">
                            <v-select
                                v-model="status"
                                label="Status do cliente"
                                :items="[
                                    { text: 'Primeiro contato', value: 'P' },
                                    { text: 'Cliente ativo', value: 'A' },
                                    { text: 'Cliente inativo', value: 'I' }
                                ]"
                                readonly
                            />
                        </v-col>
                        <v-col cols="12">
                            <v-row align="center">
                                <v-col>
                                    <v-divider></v-divider>
                                </v-col>
                                <v-col cols="auto">
                                    Informações do processo terapeutico
                                </v-col>
                                <v-col>
                                    <v-divider></v-divider>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="6">
                            <v-select
                                v-model="payment_type"
                                label="Tipo de pagamento"
                                :items="[
                                    { text: 'Pagamento mensal', value: 'M' },
                                    { text: 'Pagamento por sessão', value: 'S' }
                                ]"
                                readonly
                            />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field-money
                                v-model="ammount"
                                label="Valor a ser pago"
                                :properties="{
                                    prefix: 'R$',
                                    readonly: true
                                }"
                                :options="{
                                    outputMask: '################',
                                    locale: 'pt-BR',
                                    length: 11,
                                    precision: 2,
                                    empty: null,
                                }"
                            />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                :value="formatDate(payday)"
                                label="Dia do próximo pagamento"
                                append-icon="mdi-calendar"
                                readonly
                            ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-select
                                v-model="payment_frequency"
                                label="Frequencia de pagamento"
                                :items="[
                                    { text: 'Mensal', value: 'M' },
                                    { text: 'Quinzenal', value: 'Q' },
                                    { text: 'Semanal', value: 'S' }
                                ]"
                                readonly
                            />
                        </v-col>
                        <v-col cols="6">
                            <v-select
                                v-model="week_day_session"
                                label="Dia da sessão"
                                :items="[
                                    { text: 'Segunda', value: 'monday' },
                                    { text: 'Terça', value: 'tuesday' },
                                    { text: 'Quarta', value: 'wednesday' },
                                    { text: 'Quinta', value: 'thursday' },
                                    { text: 'Sexta', value: 'friday' },
                                    { text: 'Sábado', value: 'saturday' },
                                    { text: 'Domingo', value: 'sunday' }
                                ]"
                                readonly
                            />
                        </v-col>
                        <v-col cols="6">
                            <v-select
                                v-model="session_frequency"
                                label="Frequencia da sessão"
                                :items="[
                                    { text: 'Mensal', value: 'M' },
                                    { text: 'Quinzenal', value: 'Q' },
                                    { text: 'Semanal', value: 'S' }
                                ]"
                                readonly
                            />
                        </v-col>
                        <v-col cols="12">
                            <v-textarea v-model="comments" label="Observações" readonly />
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-row no-gutters justify="space-between">
                    <v-col cols="auto">
                        <v-btn :disabled="loading" :loading="loading" dark color="primary-pink" elevation="0" @click="close"><v-icon left>mdi-arrow-u-left-top</v-icon> Voltar</v-btn>
                    </v-col>
                    <v-col cols="auto">
                        <v-btn :disabled="loading" :loading="loading" dark color="secondary-red" elevation="0" @click="save">Deletar <v-icon right>mdi-check-bold</v-icon></v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import dayjs from "dayjs";
import { mapActions } from 'vuex';

export default {
    props: {
        value: {
            type: Boolean,
            required: false,
            default: false
        },
        customerData: {
            type: Object,
            required: false,
            default() {
                return {};
            }
        }
    },
    data() {
        return {
            show: this.value,
            basic_rules: {
                required: value => !!value || "Informação obrigatória",
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return pattern.test(value) || 'Email inválido'
                }
            },
            show_datepicker: false,
            loading: false,
            error_message: null,
            success_message: null,
            name: null,
            cpf: null,
            email: null,
            phone: null,
            status: "P",
            ammount: null,
            payment_type: "M",
            payday: null,
            payment_frequency: "M",
            week_day_session: null,
            session_frequency: "S",
            comments: null
        };
    },
    watch: {
        value() {
            this.error_message = null;
            this.success_message = null;
            this.show = this.value;
        },
        show() {
            if (!this.show) {
                this.$emit("close");
            }
            this.$emit("input", this.show);
        },
        customerData() {
            if (this.customerData) {
                this.name = this.customerData.name;
                this.cpf = this.customerData.cpf;
                this.email = this.customerData.email;
                this.phone = this.customerData.phone;
                this.status = this.customerData.status;
                this.ammount = this.customerData.ammount / 100;
                this.payment_type = this.customerData.payment_type;
                this.payday = this.customerData.payday;
                this.payment_frequency = this.customerData.payment_frequency;
                this.week_day_session = this.customerData.week_day_session;
                this.session_frequency = this.customerData.session_frequency;
                this.comments = this.customerData.comments;
            }
        }
    },
    methods: {
        ...mapActions("customers", ["ActionDoDeleteCustomer"]),
        formatDate(date) {
            return date ? dayjs(date).format("DD/MM/YYYY") : null;
        },
        close() {
            this.$refs.formCustomer.reset();
            this.show = false;
        },
        save() {
            this.loading = true;
            this.error_message = null;
            this.success_message = null;

            this.ActionDoDeleteCustomer(this.customerData.id).then(() => {
                this.success_message = "Cliente deletado com sucesso";
                this.$refs.formCustomer.reset();
                this.loading = false;
                this.$emit("deleted");
                this.show = false;
            }).catch(error => {
                console.error(error);
                this.error_message = error.response.data;
                this.loading = false;
            });
        }
    }
};
</script>
