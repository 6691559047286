import services from "../../http/index";

export const ActionDoUpdateUser = (context, payload) => {
    console.log(context);
    return services.api.request({
        url: `user/${payload.user_id}`,
        method: "put",
        data: payload
    }).then(response => {
        return response.data;
    });
};