<template>
    <v-dialog v-model="show" max-width="600px" :persistent="loading" scrollable>
        <v-card class="pa-4">
            <v-card-title>
                Agendar nova sessão
            </v-card-title>
            <v-card-text>
                <v-form ref="formSession">
                    <v-row>
                        <v-col v-if="error_message" cols="12">
                            <v-alert type="error">
                                {{ error_message }}
                            </v-alert>
                        </v-col>
                        <v-col v-if="success_message" cols="12">
                            <v-alert type="success">
                                {{ success_message }}
                            </v-alert>
                        </v-col>
                        <v-col cols="6">
                            <v-autocomplete
                                v-model="customer"
                                :items="customers_list"
                                :disabled="customerData ? true : false"
                                label="Cliente"
                                :rules="[basic_rules.required]"
                            />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field-money
                                v-model="session_value"
                                label="Valor da sessão"
                                :properties="{
                                    prefix: 'R$',
                                }"
                                :options="{
                                    locale: 'pt-BR',
                                    length: 11,
                                    precision: 2,
                                    empty: null,
                                }"
                            />
                        </v-col>
                        <v-col v-if="customer && (customer.balance / 100 < session_value)" cols="12">
                            <v-alert dense text type="error" color="error darken-4">O cliente não tem saldo suficiente para esta sessão.<br>Saldo do cliente: {{ (customer.balance / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</v-alert>
                        </v-col>
                        <v-col cols="4">
                            <v-menu
                                ref="sessionDate"
                                v-model="show_datepicker_date"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        :value="formatDate(session_date)"
                                        label="Data da sessão"
                                        append-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        :rules="[basic_rules.required]"
                                        @input="$event => session_date = formatUsa($event)"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="session_date"
                                    no-title
                                    scrollable
                                    editable
                                    @input="show_datepicker_date = false"
                                />
                            </v-menu>
                        </v-col>
                        <v-col cols="4">
                            <v-menu
                                ref="menuTimePicker"
                                v-model="show_timepicker"
                                :close-on-content-click="false"
                                :return-value.sync="hour_session"
                                transition="scale-transition"
                                offset-y
                                min-width="300px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="hour_session"
                                        label="Hora da sessão"
                                        append-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        :rules="[basic_rules.required]"
                                    ></v-text-field>
                                </template>
                                <v-time-picker
                                    v-if="show_timepicker"
                                    v-model="hour_session"
                                    format="24hr"
                                    full-width
                                    @click:minute="$refs.menuTimePicker.save(hour_session)"
                                />
                            </v-menu>
                        </v-col>
                        <v-col cols="4">
                            <v-text-field v-model="session_duration" label="Duração (em minutos)" type="number" />
                        </v-col>
                        <v-col cols="12">
                            <v-switch
                                v-model="mounted"
                                color="primary-blue"
                                label="Sessão já estruturada?"
                            ></v-switch>
                        </v-col>
                        <v-col cols="12">
                            <v-textarea v-model="comments" rows="1" label="Observações" />
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-row no-gutters justify="space-between">
                    <v-col cols="auto">
                        <v-btn :disabled="loading" :loading="loading" color="primary-pink" dark elevation="0" @click="close"><v-icon left>mdi-arrow-u-left-top</v-icon> Voltar</v-btn>
                    </v-col>
                    <v-col cols="auto">
                        <v-btn :disabled="loading" :loading="loading" color="primary-blue" dark elevation="0" @click="save">Salvar <v-icon right>mdi-check-bold</v-icon></v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
            <v-dialog v-model="show_confirmation_free_session" max-width="500px">
                <v-card class="pa-4">
                    <v-card-title>
                        Sessão gratuita
                    </v-card-title>
                    <v-card-text>
                        Essa sessão está com valor <b>R$0,00</b>. Tem certeza que deseja salvar?
                    </v-card-text>
                    <v-card-actions>
                        <v-row no-gutters justify="space-between">
                            <v-col cols="auto">
                                <v-btn :disabled="loading_confirmation" :loading="loading_confirmation" dark color="primary-pink" elevation="0" @click="closeConfirmation"><v-icon left>mdi-close-thick</v-icon> Cancelar</v-btn>
                            </v-col>
                            <v-col cols="auto">
                                <v-btn :disabled="loading_confirmation" :loading="loading_confirmation" dark color="primary-blue" elevation="0" @click="createSession">Salvar <v-icon right>mdi-check-bold</v-icon></v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="show_alert_one_more_session" max-width="500px">
                <v-card class="pa-4">
                    <v-card-title>
                        Saldo acabando!
                    </v-card-title>
                    <v-card-text>
                        ATENÇÃO!!! {{ no_more_session ? 'O saldo disponível de' : '' }} {{ customer?.name }} {{ no_more_session ? 'não é suficiente para' : 'possui saldo para somente' }} mais uma sessão.
                    </v-card-text>
                    <v-card-actions>
                        <v-row no-gutters justify="space-between">
                            <v-col cols="auto">
                                <v-btn dark color="primary-pink" elevation="0" @click="finish"><v-icon left>mdi-check</v-icon> Ok</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-card>
    </v-dialog>
</template>

<script>
import dayjs from "dayjs";
dayjs.extend(require('dayjs/plugin/customParseFormat'));
import { mapActions, mapState } from 'vuex';
import * as types from '../../../utils/store/mutations-types';

export default {
    props: {
        value: {
            type: Boolean,
            required: false,
            default: false
        },
        customerData: {
            type: Object,
            required: false,
            default: null
        }
    },
    data() {
        return {
            show: this.value,
            basic_rules: {
                required: value => !!value || "Informação obrigatória"
            },
            show_datepicker_date: false,
            loading: false,
            error_message: null,
            success_message: null,
            show_timepicker: false,
            customer: null,
            session_date: null,
            hour_session: null,
            comments: null,
            session_duration: null,
            mounted: false,
            session_value: null,
            show_confirmation_free_session: false,
            show_alert_one_more_session: false,
            no_more_session: false,
            loading_confirmation: false
        };
    },
    computed: {
        ...mapState("customers", {
            customers_list: state => state.customers_list?.filter(customer => {
                return customer.status !== 'I';
            })?.map(customer => {
                return { text: customer.name, value: customer };
            })
        }),
    },
    watch: {
        value() {
            this.show = this.value;
        },
        show() {
            if (!this.show) {
                this.$emit("close");

                this.show_datepicker_date = false;
                this.loading = false;
                this.error_message = null;
                this.success_message = null;
                this.show_timepicker = false;
                this.customer = null;
                this.session_date = null;
                this.hour_session = null;
                this.comments = null;
                this.session_duration = null;
                this.mounted = false;
                this.session_value = null;
                this.show_confirmation_free_session = false;
                this.show_alert_one_more_session = false;
                this.no_more_session = false;
                this.loading_confirmation = false;
            }

            this.error_message = null;
            this.success_message = null;
            this.$emit("input", this.show);
        },
        customer() {
            if (this.customer) {
                this.hour_session = this.customer.hour_session;
                this.session_duration = this.customer.session_duration;

                switch (this.customer.session_frequency) {
                    case "S":
                        this.session_date = dayjs(this.customer.last_scheduled_session.session_date).add(7, "day").format("YYYY-MM-DD");
                        break;
                    case "Q":
                        this.session_date = dayjs(this.customer.last_scheduled_session.session_date).add(2, "week").format("YYYY-MM-DD");
                        break;
                    case "M":
                        this.session_date = dayjs(this.customer.last_scheduled_session.session_date).add(4, "week").format("YYYY-MM-DD");
                        break;
                }

                switch (this.customer.payment_type) {
                    case "S":
                        this.session_value = this.customer.ammount / 100;
                        break;
                    case "M":
                        this.session_value = (this.customer.ammount / 100) / 4;
                        break;
                }
            }
        },
        customerData() {
            if (this.customerData) {
                this.customer = this.customers_list.find(customer => {
                    return customer.value.id === this.customerData.id;
                }).value;
            }
        },
        customers_list() {
            if (this.customerData) {
                this.customer = this.customers_list.find(customer => {
                    return customer.value.id === this.customerData.id;
                }).value;
            }
        }
    },
    mounted() {
        this.getCustomers();
        if (this.customerData) {
            this.customer = this.customers_list.find(customer => {
                return customer.value.id === this.customerData.id;
            }).value;
        }
    },
    methods: {
        ...mapActions("sessions", ["ActionDoCreateSession", "ActionDoGetSessions"]),
        ...mapActions("customers", ["ActionDoGetCustomers"]),
        getCustomers() {
            if (!this.customers_list) {
                this.ActionDoGetCustomers();
            }
        },
        formatDate(date) {
            return date ? dayjs(date).format("DD/MM/YYYY") : null;
        },
        formatUsa(date) {
            if (date) {
                if (date.length === 8) {
                    return dayjs(date, 'DDMMYYYY').format('YYYY-MM-DD');
                } else if (date.length === 10) {
                    return dayjs(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
                }
            }

            return null;
        },
        close() {
            this.$refs.formSession.reset();
            this.show = false;
        },
        save() {
            this.loading = true;
            this.error_message = null;
            this.success_message = null;

            if (this.$refs.formSession.validate()) {
                let session_value = typeof this.session_value === 'number' ? this.session_value * 100 : parseInt(this.session_value.toString().replace(/[^0-9]+/g, ""));

                if (session_value > 0) {
                    this.createSession();
                } else {
                    this.show_confirmation_free_session = true;
                }
            } else {
                this.loading = false;
            }
        },
        createSession() {
            this.loading_confirmation = true;
            let session_value = typeof this.session_value === 'number' ? this.session_value * 100 : parseInt(this.session_value.toString().replace(/[^0-9]+/g, ""))

            this.ActionDoCreateSession({
                customer_id: this.customer.id,
                session_date: this.session_date + ' ' + this.hour_session,
                attended: false,
                comments: this.comments,
                session_value: session_value,
                session_duration: this.session_duration,
                session_record: false,
                finished: false,
                mounted: this.mounted
            }).then(response => {
                this.ActionDoGetCustomers();
                this.ActionDoGetSessions();

                if (response.session_id) {
                    if ((this.customer.balance - session_value) <= session_value) {
                        this.show_alert_one_more_session = true;
                        this.no_more_session = (this.customer.balance - session_value) < session_value;
                    } else {
                        this.finish();
                    }
                } else {
                    this.error_message = "Houve um erro ao registrar a sessão";
                    this.show_confirmation_free_session = false;
                    this.loading_confirmation = false;
                    this.loading = false;
                }
                
            }).catch(error => {
                console.error(error);
                this.error_message = error.response.data;
                this.show_confirmation_free_session = false;
                this.loading_confirmation = false;
                this.loading = false;
            });
        },
        finish() {
            this.$store.commit("utils/" + types.UPDATE_REFRESH);
            this.show_alert_one_more_session = false;
            this.success_message = "Sessão registrada com sucesso";
            this.$refs.formSession.reset();
            this.loading = false;
            this.$emit("created");
            this.show = false;
        },
        closeConfirmation() {
            this.loading = false;
            this.show_confirmation_free_session = false;
        }
    }
};
</script>
