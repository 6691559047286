var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"600px","persistent":_vm.loading,"scrollable":""},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',{staticClass:"pa-4"},[_c('v-card-title',[_vm._v(" Excluir registro financeiro ")]),_c('v-card-text',[_c('v-form',{ref:"formExtract"},[_c('v-row',[(_vm.error_message)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"type":"error darken-4"}},[_vm._v(" "+_vm._s(_vm.error_message)+" ")])],1):_vm._e(),(_vm.success_message)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"type":"success"}},[_vm._v(" "+_vm._s(_vm.success_message)+" ")])],1):_vm._e(),_c('v-col',{attrs:{"cols":_vm.type ? 6 : 12}},[_c('v-select',{attrs:{"label":"Tipo","readonly":"","items":[
                                { text: 'Recebido de cliente', value: 'R' },
                                { text: 'Pagamento de despesa programada', value: 'D' },
                            ]},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})],1),(_vm.type)?_c('v-col',{attrs:{"cols":6}},[(_vm.type === 'R')?_c('v-select',{attrs:{"label":"Cliente","readonly":"","items":_vm.customers_list},model:{value:(_vm.customer),callback:function ($$v) {_vm.customer=$$v},expression:"customer"}}):_vm._e(),(_vm.type === 'D')?_c('v-select',{attrs:{"label":"Imposto","readonly":"","items":_vm.taxes_list},model:{value:(_vm.taxe),callback:function ($$v) {_vm.taxe=$$v},expression:"taxe"}}):_vm._e()],1):_vm._e(),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"value":_vm.formatDate(_vm.extract_date),"label":"Data do registro","append-icon":"mdi-calendar","readonly":""}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field-money',{attrs:{"label":"Valor","properties":{
                                prefix: 'R$',
                                readonly: true
                            },"options":{
                                locale: 'pt-BR',
                                length: 11,
                                precision: 2,
                                empty: null,
                            }},model:{value:(_vm.ammount_paid),callback:function ($$v) {_vm.ammount_paid=$$v},expression:"ammount_paid"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"value":_vm.formatDate(_vm.next_payday),"label":"Data do próx. pagamento","append-icon":"mdi-calendar","readonly":""}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Título do registro","readonly":""},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"label":"Descrição","readonly":""},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-row',{attrs:{"no-gutters":"","justify":"space-between"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"disabled":_vm.loading,"loading":_vm.loading,"dark":"","color":"primary-pink","elevation":"0"},on:{"click":_vm.close}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-arrow-u-left-top")]),_vm._v(" Voltar")],1)],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"disabled":_vm.loading,"loading":_vm.loading,"dark":"","color":"secondary-red","elevation":"0"},on:{"click":_vm.save}},[_vm._v("Remover "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-check-bold")])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }