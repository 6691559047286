import { routes as auth } from "../views/auth";
import { routes as home } from "../views/dashboard";
import { routes as customers } from "../views/customers";
import { routes as taxes } from "../views/taxes";
import { routes as sessions } from "../views/sessions";
import { routes as vouchers } from "../views/vouchers";
import { routes as schedule } from "../views/schedule";
import { routes as utils } from "../utils";

export default [
    ...auth,
    ...home,
    ...customers,
    ...taxes,
    ...sessions,
    ...vouchers,
    ...schedule,
    ...utils
];
