<template>
    <v-row>
        <v-col cols="12">
            <h2 class="black--text">Prontuários pendentes</h2>
        </v-col>
        <v-col cols="12">
            <v-data-table
                :headers="headers"
                :items="session_list"
                class="primary-gray"
                :loading="loading"
                sort-by="session_date"
            >
                <template v-slot:[`item.session_date`]="{ item }">
                    {{ formatDate(item.session_date, true) }}
                </template>
                <template v-slot:[`item.attended`]="{ item }">
                    <v-icon v-if="item.attended" color="success darken-4">mdi-check-bold</v-icon>
                    <v-icon v-else color="error darken-4">mdi-close-thick</v-icon>
                </template>
                <template v-slot:[`item.session_record`]="{ item }">
                    <v-icon v-if="item.session_record" color="success darken-4">mdi-check-bold</v-icon>
                    <v-icon v-else color="error darken-4">mdi-close-thick</v-icon>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <v-row no-gutters class="flex-nowrap">
                        <v-col cols="auto" class="px-1">
                            <v-btn icon @click="openEditSession(item)" color="primary-blue">
                                <v-icon>
                                    mdi-eye-outline
                                </v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </template>
            </v-data-table>
        </v-col>
        <dialog-edit-session :customer-data="show_edit_session ? session_data.customer : null" :session-data="session_data" v-model="show_edit_session" @close="closeEditSession" @edited="getSessions" />
    </v-row>
</template>

<script>
import DialogEditSession from '../../sessions/components/DialogEditSession.vue';
import dayjs from 'dayjs';
import { mapActions, mapState } from 'vuex';

export default {
    components: {
        DialogEditSession
    },
    data() {
        return {
            loading: false,
            headers: [
                { text: "Cliente", value: "customer.name" },
                { text: "Data", value: "session_date", sortable: true },
                { text: "Compareceu", value: "attended", sortable: true },
                { text: "Prontuário", value: "session_record", sortable: true },
                { text: '', value: 'actions', sortable: false },
            ],
            show_edit_session: false,
            session_data: null,
            session_list: []
        };
    },
    computed: {
        ...mapState("utils", {
            refresh: state => state.refresh
        })
    },
    watch: {
        refresh() {
            this.getSessions();
        }
    },
    mounted() {
        this.getSessions();
    },
    methods: {
        ...mapActions("sessions", ["ActionDoGetSessions"]),
        getSessions() {
            this.loading = true;
            this.ActionDoGetSessions({ active_customer: true, session_record: false, finished: true }).then((response) => {
                this.session_list = response;
                this.loading = false;
            });
        },
        formatDate(date, hour = false) {
            return dayjs(date).format('DD/MM/YYYY' + (hour ? ' HH:mm' : ''));
        },
        closeEditSession() {
            this.getSessions();
            this.show_edit_session = false;
            this.session_data = null;
        },
        openEditSession(session) {
            this.session_data = session;
            this.show_edit_session = true;
        },
    }
};
</script>
