<template>
    <v-row v-if="customer" no-gutters>
        <v-col cols="12" class="d-flex justify-end mt-n5 ml-4">
            <v-btn icon color="black" absolute @click="$emit('closeInfo')">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-col>
        <v-col cols="12">
            <v-row no-gutters justify="center">
                <v-col cols="auto" class="text-center">
                    <h2>{{ customer.name }}</h2>
                </v-col>
            </v-row>
            <v-row class="mt-0" justify=center>
                <v-col cols="6" class="text-end">
                    <span class="text-subtitle-1">Saldo: <b>{{ (customer.balance / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</b></span>
                </v-col>
                <v-col cols="6" class="text-start">
                    <span class="text-subtitle-1"><b>{{ formatDate(session_info?.session_date, true) }}</b></span>
                </v-col>
            </v-row>
            <v-row class="mt-0" justify="center">
                <v-col v-if="!session_info?.finished" cols="auto">
                    <v-tooltip bottom color="secondary-purple">
                        <template #activator="{ attrs, on }">
                            <v-btn fab small color="secondary-purple" class="white--text" @click="openConfirmSession" v-bind="attrs" v-on="on">
                                <v-icon>mdi-head-check-outline</v-icon>
                            </v-btn>
                        </template>
                        Confirmar sessão
                    </v-tooltip>
                </v-col>
                <v-col cols="auto">
                    <v-tooltip bottom color="primary-blue">
                        <template #activator="{ attrs, on }">
                            <v-btn fab small color="primary-blue" class="white--text" dark @click="openEditSession(session_info)" v-bind="attrs" v-on="on">
                                <v-icon>mdi-calendar-edit</v-icon>
                            </v-btn>
                        </template>
                        Editar sessão
                    </v-tooltip>
                </v-col>
                <v-col cols="auto">
                    <v-tooltip bottom color="secondary-green">
                        <template #activator="{ attrs, on }">
                            <v-btn fab small color="secondary-green" class="white--text" @click="openAddExtract" v-bind="attrs" v-on="on">
                                <v-icon>mdi-cash-plus</v-icon>
                            </v-btn>
                        </template>
                        Adicionar pagamento
                    </v-tooltip>
                </v-col>
                <v-col cols="auto">
                    <v-tooltip bottom color="primary-pink">
                        <template #activator="{ attrs, on }">
                            <v-btn fab small color="primary-pink" class="white--text" @click="openAddSession" v-bind="attrs" v-on="on">
                                <v-icon>mdi-clock-plus-outline</v-icon>
                            </v-btn>
                        </template>
                        Agendar sessão extra
                    </v-tooltip>
                </v-col>
                <v-col cols="auto">
                    <v-tooltip bottom color="secondary-red">
                        <template #activator="{ attrs, on }">
                            <v-btn fab small color="secondary-red" class="white--text" @click="openDeleteSession(session_info)" v-bind="attrs" v-on="on">
                                <v-icon>mdi-clock-remove-outline</v-icon>
                            </v-btn>
                        </template>
                        {{ session_info?.finished ? 'Excluir sessão' : 'Remover agendamento' }}
                    </v-tooltip>
                </v-col>
            </v-row>
            <v-row class="mt-0">
                <v-col cols="12">
                    <v-card class="pa-3" rounded="lg" flat color="primary-white black--text" height="100%">
                        <v-row>
                            <v-col>
                                <span class="text-h6">Resumo</span>
                            </v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-col cols="12" md="6">
                                <v-row dense align="center" class="fill-height">
                                    <v-col v-if="customer.birthday" cols="12" class="text-body-2">{{ getAge(customer.birthday) }} anos</v-col>
                                    <v-col v-if="customer.birthday" cols="12" class="text-body-2">{{ formatDate(customer.birthday) }}</v-col>
                                    <v-col v-if="customer.cpf" cols="12" class="text-body-2">{{ customer.cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4") }}</v-col>
                                    <v-col v-if="customer.phone" cols="12" class="text-body-2">
                                        <a :href="'https://wa.me/+' + customer.phone" target="_blank" class="text-decoration-none black--text">{{ customer.phone?.replace(/(\d{2})(\d{2})(\d{5}|\d{4})(\d{4})/, "+$1 ($2) $3-$4") }}</a>
                                    </v-col>
                                    <v-col v-if="customer.email" cols="12" class="text-body-2">
                                        <a :href="'mailto:' + customer.email" target="_blank" class="text-decoration-none black--text">{{ customer.email }}</a>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-row>
                                    <v-col>
                                        <apexchart 
                                            type="donut"
                                            :options="chartOptions"
                                            :series="[customer.sessions_attended_count, customer.sessions_missed_count, customer.sessions_rescheduled_count]"
                                            width="100%"
                                            height="175"
                                        />
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-row>
                        <v-col>
                            <h2>Sessões</h2>
                        </v-col>
                        <v-col cols="auto">
                            <v-tooltip top color="primary-blue">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn color="primary-blue" outlined elevation="3" small @click="showSessions(customer)" v-bind="attrs" v-on="on">
                                        <v-icon>mdi-list-box-outline</v-icon>
                                    </v-btn>
                                </template>
                                Ver todas
                            </v-tooltip>
                        </v-col>
                    </v-row>
                    <v-card class="pa-3" rounded="lg" flat color="primary-white">
                        <v-row dense no-gutters>
                            <v-col cols="12">
                                <apexchart type="bar" height="200" :options="chartOptionsBar" :series="seriesSessionsChart" />
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-row>
                        <v-col>
                            <h2>Últimos pagamentos</h2>
                        </v-col>
                        <v-col cols="auto">
                            <v-tooltip top color="secondary-green">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn color="secondary-green" small outlined elevation="3" @click="showCustomerPayments(customer)" v-bind="attrs" v-on="on">
                                        <v-icon>mdi-list-box-outline</v-icon>
                                    </v-btn>
                                </template>
                                Ver todos
                            </v-tooltip>
                        </v-col>
                    </v-row>
                    <v-card class="pa-3" rounded="lg" flat color="primary-white">
                        <v-row dense>
                            <v-col cols="12">
                                <v-data-table
                                    :headers="headers_extract"
                                    :items="customer.extracts"
                                    dense
                                    class="primary-white"
                                    sort-by="extract_date"
                                    sort-desc
                                    :items-per-page="3"
                                    hide-default-footer
                                >
                                    <template v-slot:[`item.extract_date`]="{ item }">
                                        {{ formatDate(item.extract_date) }}
                                    </template>
                                    <template v-slot:[`item.ammount_paid`]="{ item }">
                                        {{ (item.ammount_paid / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}
                                    </template>
                                    <template v-slot:[`item.actions`]="{ item }">
                                        <v-row no-gutters class="flex-nowrap">
                                            <v-col cols="auto" class="px-1">
                                                <v-tooltip top color="primary-blue">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn icon @click="openEditExtract(item)" color="primary-blue" v-bind="attrs" v-on="on">
                                                            <v-icon>
                                                                mdi-pencil
                                                            </v-icon>
                                                        </v-btn>
                                                    </template>
                                                    Editar
                                                </v-tooltip>
                                            </v-col>
                                            <v-col cols="auto" class="px-1">
                                                <v-tooltip top color="error darken-4">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn icon @click="openDeleteExtract(item)" color="error darken-4" v-bind="attrs" v-on="on">
                                                            <v-icon>
                                                                mdi-delete-empty-outline
                                                            </v-icon>
                                                        </v-btn>
                                                    </template>
                                                    Deletar
                                                </v-tooltip>
                                            </v-col>
                                        </v-row>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
        <dialog-add-extract :customer-data="customer_data" v-model="show_add_extract" @close="closeAddExtract" @created="getCustomerInfo(true)" />
        <dialog-confirm-session :customer-data="customer_data" :session-data="session_data" v-model="show_confirm_session" @close="closeConfirmSession" @created="getCustomerInfo(true)"/>
        <dialog-add-session :customer-data="customer_data" v-model="show_add_session" @close="closeAddSession" @created="getCustomerInfo(true)"/>
        <dialog-edit-extract :customer-data="customer_data" :extract-data="extract_data" v-model="show_edit_extract" @close="closeEditExtract" @edited="getCustomerInfo(true)" />
        <dialog-edit-session :customer-data="customer_data" :session-data="session_data" v-model="show_edit_session" @close="closeEditSession" @edited="getCustomerInfo(true)" />
        <dialog-delete-extract :customer-data="customer_data" :extract-data="extract_data" v-model="show_delete_extract" @close="closeDeleteExtract" @deleted="getCustomerInfo(true)" />
        <dialog-delete-session :customer-data="customer_data" :session-data="session_data" v-model="show_delete_session" @close="closeDeleteSession" @deleted="getCustomerInfo(true)" />
        <dialog-list-sessions :customer-data="customer_data" v-model="show_list_sessions" @close="closeListSessions" @updateData="getCustomerInfo(true)"/>
        <dialog-list-payments :customer-data="customer_data" v-model="show_list_customer_payments" @close="closeCustomerPayments" @updateData="getCustomerInfo(true)" />
    </v-row>
</template>

<script>
import DialogAddSession from '../../sessions/components/DialogAddSession.vue';
import DialogConfirmSession from '../../sessions/components/DialogConfirmSession.vue';
import DialogAddExtract from '../../extract/components/DialogAddExtract.vue';
import DialogEditExtract from '../../extract/components/DialogEditExtract.vue';
import DialogEditSession from '../../sessions/components/DialogEditSession.vue';
import DialogDeleteExtract from '../../extract/components/DialogDeleteExtract.vue';
import DialogDeleteSession from '../../sessions/components/DialogDeleteSession.vue';
import DialogListSessions from '../../sessions/components/DialogListSessions.vue';
import DialogListPayments from '../../extract/components/DialogListPayments.vue';
import dayjs from 'dayjs';
import { mapActions } from 'vuex';

export default {
    components: {
        DialogAddSession,
        DialogConfirmSession,
        DialogAddExtract,
        DialogEditExtract,
        DialogEditSession,
        DialogDeleteExtract,
        DialogDeleteSession,
        DialogListSessions,
        DialogListPayments
    },
    props: {
        customerId: {
            type: Number,
            required: false,
            default: null
        },
        sessionInfo: {
            type: Object,
            required: false,
            default: null
        }
    },
    data() {
        return {
            customer_data: null,
            extract_data: null,
            session_data: null,
            show_add_session: false,
            show_confirm_session: false,
            show_add_extract: false,
            show_edit_extract: false,
            show_edit_session: false,
            show_delete_extract: false,
            show_delete_session: false,
            show_list_sessions: false,
            show_list_customer_payments: false,
            headers_extract: [
                { text: "Data", value: "extract_date", sortable: false },
                { text: "Valor", value: "ammount_paid", sortable: false },
                { text: '', value: 'actions', sortable: false },
            ],
            headers_sessions: [
                { text: "Data", value: "session_date", sortable: false },
                { text: "Compareceu?", value: "attended", sortable: false },
                { text: "Prontuário", value: "session_record", sortable: false },
                { text: '', value: 'actions', sortable: false },
            ],
            customer: null,
            chartOptions: {
                labels: ['Compareceu', 'Faltou', 'Remarcou'],
                colors: [this.$vuetify.theme.themes.light['secondary-purple'], this.$vuetify.theme.themes.light['secondary-red'], this.$vuetify.theme.themes.light['secondary-yellow']],
                dataLabels: {
                    enable: true,
                    formatter: function (val) {
                        return Math.round(val) + "%";
                    }
                },
                legend: {
                    show: false
                },
                plotOptions: {
                    pie: {
                        expandOnClick: false,
                        donut: {
                            size: '50%',
                            labels: {
                                show: true,
                                name: {
                                    show: true,
                                    color: '#000'
                                },
                                value: {
                                    show: true,
                                    color: '#000'
                                },
                                total: {
                                    show: true,
                                    showAlways: true,
                                    color: '#000'
                                }
                            }
                        }
                    }
                },
                stroke: {
                    show: false
                }
            },
            session_info: null,
            chartOptionsBar: {
                colors: [this.$vuetify.theme.themes.light['secondary-purple'], this.$vuetify.theme.themes.light['secondary-red'], this.$vuetify.theme.themes.light['secondary-yellow']],
                chart: {
                    type: 'bar',
                    height: 200,
                    stacked: true,
                    toolbar: {
                        show: false
                    },
                    zoom: {
                        enabled: false
                    }
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        legend: {
                            position: 'bottom',
                            offsetX: -10,
                            offsetY: 0
                        }
                    }
                }],
                plotOptions: {
                    bar: {
                        horizontal: false,
                        borderRadius: 10,
                        borderRadiusApplication: 'end', // 'around', 'end'
                        borderRadiusWhenStacked: 'last', // 'all', 'last'
                        dataLabels: {
                            total: {
                                enabled: true,
                                style: {
                                    fontSize: '13px',
                                    fontWeight: 900,
                                    color: '#fff'
                                }
                            }
                        }
                    },
                },
                xaxis: {
                    type: 'category',
                    categories: [],
                },
                legend: {
                    show: false,
                    position: 'right',
                    offsetY: 10
                },
                fill: {
                    opacity: 1
                }
            },
            seriesSessionsChart: []
        }
    },
    watch: {
        customerId() {
            this.customer = null;
            if (this.customerId) {
                this.getCustomerInfo();
            }
        },
        sessionInfo() {
            this.session_info = this.sessionInfo;
        },
        customer() {
            if (this.customer?.sessions) {
                this.seriesSessionsChart = [];
                let unordered = {};

                for (let session of this.customer.sessions) {
                    let month_year = dayjs(session.session_date).format('YYYY-MM');
                    if (unordered[month_year] == undefined) {
                        unordered[month_year] = {
                            attended: 0,
                            missed: 0,
                            rescheduled_data: 0
                        };
                    }

                    if (session.attended) {
                        unordered[month_year].attended += 1;
                    } else if (session.rescheduled) {
                        unordered[month_year].rescheduled_data += 1;
                    } else {
                        unordered[month_year].missed += 1;
                    }
                }

                const ordered = Object.keys(unordered).sort().reduce(
                    (obj, key) => { 
                        obj[key] = unordered[key]; 
                        return obj;
                    }, 
                    {}
                );

                let attended_data = [];
                let missed_data = [];
                let rescheduled_data = [];
                let chart_labels = [];
                for (let month in ordered) {
                    attended_data.push(ordered[month].attended);
                    missed_data.push(ordered[month].missed);
                    rescheduled_data.push(ordered[month].rescheduled_data);
                    chart_labels.push(dayjs(month + '01').format('MMM') + ' ' + dayjs(month + '01').format('YY'))
                }

                this.seriesSessionsChart = [
                    {
                        name: "Compareceu",
                        data: attended_data
                    },
                    {
                        name: "Faltou",
                        data: missed_data
                    },
                    {
                        name: "Remarcou",
                        data: rescheduled_data
                    }
                ];

                this.chartOptionsBar.xaxis.categories = chart_labels;
            }
        }
    },
    mounted() {
        this.getCustomerInfo();
    },
    methods: {
        ...mapActions("customers", ["ActionDoGetCustomer", "ActionDoGetCustomers"]),
        ...mapActions("schedule", ["ActionDoGetCalendarEvents"]),
        ...mapActions("sessions", ["ActionDoGetSession"]),
        getCustomerInfo(update_calendar = false) {
            this.ActionDoGetCustomer(this.customerId).then(response => {
                this.customer = response;
                this.customer_data = this.customer;

                this.ActionDoGetSession(this.sessionInfo.id).then(resp_session => {
                    this.session_info = resp_session;
                })

                if (update_calendar) {
                    this.ActionDoGetCalendarEvents();
                }
            });
        },
        getAge(birthday) {
            return dayjs().diff(dayjs(birthday), 'year');
        },
        openConfirmSession() {
            this.session_data = this.session_info;
            this.show_confirm_session = true;
        },
        closeConfirmSession() {
            this.show_confirm_session = false;
            this.session_data = null;
        },
        openAddSession() {
            this.customer_data = this.customer;
            this.show_add_session = true;
        },
        closeAddSession() {
            this.show_add_session = false;
            this.customer_data = null;
        },
        openAddExtract() {
            this.customer_data = this.customer;
            this.show_add_extract = true;
        },
        closeAddExtract() {
            this.show_add_extract = false;
            this.customer_data = null;
        },
        openEditExtract(extract) {
            this.customer_data = this.customer;
            this.extract_data = extract;
            this.show_edit_extract = true;
        },
        closeEditExtract() {
            this.show_edit_extract = false;
            this.extract_data = null;
            this.customer_data = null;
        },
        openDeleteExtract(extract) {
            this.customer_data = this.customer;
            this.extract_data = extract;
            this.show_delete_extract = true;
        },
        closeDeleteExtract() {
            this.show_delete_extract = false;
            this.extract_data = null;
            this.customer_data = null;
        },
        openEditSession(session) {
            this.customer_data = this.customer;
            this.session_data = session;
            this.show_edit_session = true;
        },
        closeEditSession() {
            this.show_edit_session = false;
            this.session_data = null;
            this.customer_data = null;
        },
        openDeleteSession(session) {
            this.customer_data = this.customer;
            this.session_data = session;
            this.show_delete_session = true;
        },
        closeDeleteSession() {
            this.show_delete_session = false;
            this.extract_data = null;
            this.customer_data = null;
        },
        formatDate(date, hour = false) {
            if (hour) {
                return dayjs(date).format('DD/MM/YYYY HH:mm');
            } else {
                return dayjs(date).format('DD/MM/YYYY');
            }
        },
        showSessions(customer) {
            this.customer_data = customer;
            this.show_list_sessions = true;
        },
        closeListSessions() {
            this.show_list_sessions = false;
            this.customer_data = null;
        },
        showCustomerPayments(customer) {
            this.customer_data = customer;
            this.show_list_customer_payments = true;
        },
        closeCustomerPayments() {
            this.show_list_customer_payments = false;
            this.customer_data = null;
        }
    }
}
</script>
