import Vue from 'vue';
import App from './App.vue';
import dotenv from 'dotenv';
dotenv.config({ path: '.env.local' });

import vuetify from './plugins/vuetify';
import store from "./store";
import router from './router';

import "./plugins/vuetify-mask";
import "./plugins/apexcharts";

Vue.config.productionTip = false;

new Vue({
    store,
    router,
    vuetify,
    render: h => h(App)
}).$mount('#app');
