<template>
    <v-dialog v-model="show" max-width="600px" :persistent="loading" scrollable>
        <v-card class="pa-4">
            <v-card-title>
                Remover sessão
            </v-card-title>
            <v-card-text>
                <v-form ref="formSession">
                    <v-row>
                        <v-col v-if="error_message" cols="12">
                            <v-alert type="error darken-4">
                                {{ error_message }}
                            </v-alert>
                        </v-col>
                        <v-col v-if="success_message" cols="12">
                            <v-alert type="success">
                                {{ success_message }}
                            </v-alert>
                        </v-col>
                        <v-col cols="6">
                            <v-select
                                v-model="customer"
                                label="Cliente"
                                :disabled="customer ? true : false"
                                :items="customers_list"
                                readonly
                            />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field-money
                                v-model="session_value"
                                label="Valor da sessão"
                                :properties="{
                                    prefix: 'R$',
                                    readonly: true
                                }"
                                :options="{
                                    locale: 'pt-BR',
                                    length: 11,
                                    precision: 2,
                                    empty: null,
                                }"
                            />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                :value="formatDate(session_date)"
                                label="Data da sessão"
                                append-icon="mdi-calendar"
                                readonly
                            ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                v-model="hour_session"
                                label="Hora da sessão"
                                append-icon="mdi-calendar"
                                readonly
                            ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-switch
                                v-model="attended"
                                color="primary-blue"
                                label="O cliente compareceu"
                                readonly
                            ></v-switch>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                :value="formatDate(next_session)"
                                label="Data da próxima sessão"
                                append-icon="mdi-calendar"
                                readonly
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-textarea v-model="comments" label="Observações" readonly />
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-row no-gutters justify="space-between">
                    <v-col cols="auto">
                        <v-btn :disabled="loading" :loading="loading" color="primary-pink" dark elevation="0" @click="close"><v-icon left>mdi-arrow-u-left-top</v-icon> Voltar</v-btn>
                    </v-col>
                    <v-col cols="auto">
                        <v-btn :disabled="loading" :loading="loading" color="secondary-red" dark elevation="0" @click="save">Excluir <v-icon right>mdi-check-bold</v-icon></v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import dayjs from "dayjs";
import { mapActions, mapState } from 'vuex';

export default {
    props: {
        value: {
            type: Boolean,
            required: false,
            default: false
        },
        customerData: {
            type: Object,
            required: false,
            default: null
        },
        sessionData: {
            type: Object,
            required: false,
            default: null
        }
    },
    data() {
        return {
            show: this.value,
            loading: false,
            error_message: null,
            success_message: null,
            customer: null,
            session_date: null,
            hour_session: null,
            next_session: null,
            comments: null,
            attended: false,
            session_value: null
        };
    },
    computed: {
        ...mapState("customers", {
            customers_list: state => state.customers_list?.map(customer => {
                return {
                    text: customer.name,
                    value: {
                        id: customer.id,
                        next_session: customer.next_session,
                        next_session_mounted: customer.next_session_mounted
                    }
                };
            })
        }),
    },
    watch: {
        value() {
            this.show = this.value;
        },
        show() {
            if (!this.show) {
                this.$emit("close");

                this.loading = false;
                this.error_message = null;
                this.success_message = null;
                this.customer = null;
                this.session_date = null;
                this.hour_session = null;
                this.next_session = null;
                this.comments = null;
                this.attended = false;
                this.session_value = null
            }

            this.error_message = null;
            this.success_message = null;
            this.$emit("input", this.show);
        },
        customer() {
            if (this.customer) {
                this.next_session = this.customer.next_session;
            }
        },
        customerData() {
            this.setCustomer();
        },
        sessionData() {
            if (this.sessionData) {
                this.customer_id = this.sessionData.customer_id;
                this.session_value = this.sessionData.session_value / 100;
                this.session_date = dayjs(this.sessionData.session_date).format('YYYY-MM-DD');
                this.hour_session = dayjs(this.sessionData.session_date).format('HH:mm');
                this.attended = this.sessionData.attended;
                this.comments = this.sessionData.comments;
            }
        }
    },
    mounted() {
        this.getCustomers();
        this.setCustomer();
    },
    methods: {
        ...mapActions("sessions", ["ActionDoDeleteSession", "ActionDoGetSessions"]),
        ...mapActions("customers", ["ActionDoGetCustomers"]),
        getCustomers() {
            if (!this.customers_list) {
                this.ActionDoGetCustomers();
            }
        },
        setCustomer() {
            this.customer = {
                id: this.customerData?.id,
                next_session: this.customerData?.next_session,
                next_session_mounted: this.customerData?.next_session_mounted
            };
        },
        formatDate(date) {
            return date ? dayjs(date).format("DD/MM/YYYY") : null;
        },
        close() {
            this.$refs.formSession.reset();
            this.show = false;
        },
        save() {
            this.loading = true;
            this.error_message = null;
            this.success_message = null;

            if (this.$refs.formSession.validate()) {
                this.ActionDoDeleteSession(this.sessionData.id).then(() => {
                    this.ActionDoGetCustomers();
                    this.ActionDoGetSessions();

                    this.success_message = "Sessão deletada com sucesso";
                    this.$refs.formSession.reset();
                    this.loading = false;
                    this.$emit("deleted");
                    this.show = false;
                }).catch(error => {
                    console.error(error);
                    this.error_message = error.response.data;
                    this.loading = false;
                });
            } else {
                this.loading = false;
            }
        }
    }
};
</script>
