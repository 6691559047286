var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"600px","persistent":_vm.loading,"scrollable":""},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',{staticClass:"pa-4"},[_c('v-card-title',[_vm._v(" Gerar contrato ")]),_c('v-card-text',[_c('v-form',{ref:"formContract"},[_c('v-row',[(_vm.error_message)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"type":"error darken-4"}},[_vm._v(" "+_vm._s(_vm.error_message)+" ")])],1):_vm._e(),(_vm.success_message)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"type":"success"}},[_vm._v(" "+_vm._s(_vm.success_message)+" ")])],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',[_c('v-divider')],1),_c('v-col',{attrs:{"cols":"auto"}},[_vm._v(" Confirme as informações do contrato ")]),_c('v-col',[_c('v-divider')],1)],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"Nome do contratante","rules":[_vm.basic_rules.required]},model:{value:(_vm.contractor),callback:function ($$v) {_vm.contractor=$$v},expression:"contractor"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field-simplemask',{attrs:{"label":"CPF","options":{
                                inputMask: '###.###.###-##',
                                outputMask: '###########',
                                empty: null,
                                applyAfter: false,
                                alphanumeric: true,
                                lowerCase: false,
                            }},model:{value:(_vm.cpf),callback:function ($$v) {_vm.cpf=$$v},expression:"cpf"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"RG","rules":[_vm.basic_rules.required]},model:{value:(_vm.rg),callback:function ($$v) {_vm.rg=$$v},expression:"rg"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"Duração (em minutos)","type":"number","rules":[_vm.basic_rules.required]},model:{value:(_vm.minutes),callback:function ($$v) {_vm.minutes=$$v},expression:"minutes"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"label":"Dia da sessão","items":[
                                { text: 'Segunda', value: 'monday' },
                                { text: 'Terça', value: 'tuesday' },
                                { text: 'Quarta', value: 'wednesday' },
                                { text: 'Quinta', value: 'thursday' },
                                { text: 'Sexta', value: 'friday' },
                                { text: 'Sábado', value: 'saturday' },
                                { text: 'Domingo', value: 'sunday' }
                            ],"rules":[_vm.basic_rules.required]},model:{value:(_vm.weekday),callback:function ($$v) {_vm.weekday=$$v},expression:"weekday"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{ref:"menuTimePicker",attrs:{"close-on-content-click":false,"return-value":_vm.hour,"transition":"scale-transition","offset-y":"","min-width":"300px"},on:{"update:returnValue":function($event){_vm.hour=$event},"update:return-value":function($event){_vm.hour=$event}},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Hora da sessão","append-icon":"mdi-calendar","readonly":"","rules":[_vm.basic_rules.required]},model:{value:(_vm.hour),callback:function ($$v) {_vm.hour=$$v},expression:"hour"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.show_timepicker),callback:function ($$v) {_vm.show_timepicker=$$v},expression:"show_timepicker"}},[(_vm.show_timepicker)?_c('v-time-picker',{attrs:{"format":"24hr","full-width":""},on:{"click:minute":function($event){return _vm.$refs.menuTimePicker.save(_vm.hour)}},model:{value:(_vm.hour),callback:function ($$v) {_vm.hour=$$v},expression:"hour"}}):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field-money',{attrs:{"label":"Valor da sessão","properties":{
                                prefix: 'R$',
                            },"options":{
                                locale: 'pt-BR',
                                length: 11,
                                precision: 2,
                                empty: null,
                            }},model:{value:(_vm.ammount),callback:function ($$v) {_vm.ammount=$$v},expression:"ammount"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"Forma de pagamento","rules":[_vm.basic_rules.required]},model:{value:(_vm.payment_type),callback:function ($$v) {_vm.payment_type=$$v},expression:"payment_type"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field-money',{attrs:{"label":"Taxa de cancelamento","properties":{
                                prefix: 'R$',
                            },"options":{
                                locale: 'pt-BR',
                                length: 11,
                                precision: 2,
                                empty: null,
                            }},model:{value:(_vm.cancel_value),callback:function ($$v) {_vm.cancel_value=$$v},expression:"cancel_value"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{ref:"menuDatePicker",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.formatDate(_vm.date),"label":"Data do contrato","append-icon":"mdi-calendar"},on:{"input":$event => _vm.date = _vm.formatUsa($event)}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.show_datepicker),callback:function ($$v) {_vm.show_datepicker=$$v},expression:"show_datepicker"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","editable":""},on:{"input":function($event){_vm.show_datepicker = false}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-row',{attrs:{"no-gutters":"","justify":"space-between"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"disabled":_vm.loading,"loading":_vm.loading,"dark":"","color":"primary-pink","elevation":"0"},on:{"click":_vm.close}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-arrow-u-left-top")]),_vm._v(" Voltar")],1)],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"disabled":_vm.loading,"loading":_vm.loading,"dark":"","color":"primary-blue","elevation":"0"},on:{"click":_vm.save}},[_vm._v("Gerar "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-check-bold")])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }