<template>
    <v-row>
        <v-col cols="12">
            <h2 class="black--text">Sessões a estruturar</h2>
        </v-col>
        <v-col cols="12">
            <v-data-table
                :headers="headers"
                :items="session_list"
                class="primary-gray"
                :loading="loading"
                sort-by="next_session"
            >
                <template v-slot:[`item.session_date`]="{ item }">
                    {{ formatDate(item.session_date, true) }}
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <v-row no-gutters class="flex-nowrap">
                        <v-col cols="auto" class="px-1">
                            <v-btn text color="primary-blue" @click="showDialogConform(item)">
                                Ok
                                <v-icon right>
                                    mdi-check-bold
                                </v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </template>
            </v-data-table>
        </v-col>
        <v-dialog v-model="show_confirm" max-width="600px" :persistent="loading">
            <v-card class="pa-4">
                <v-card-title>
                    Sessão estruturada
                </v-card-title>
                <v-card-text>
                    <v-row no-gutters>
                        <v-col cols="12" class="mb-4">
                            Deseja confirmar que a sessão abaixo já foi estruturada?
                        </v-col>
                        <v-col cols="12">
                            Cliente: <b>{{ selected_session?.customer?.name }}</b>
                        </v-col>
                        <v-col cols="12">
                            Data da sessão: <b>{{ formatDate(selected_session?.session_date, true) }}</b>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-row no-gutters justify="space-between">
                        <v-col cols="auto">
                            <v-btn :disabled="loading" :loading="loading" outlined color="secondary-blue" @click="closeDialogConfirm"><v-icon left>mdi-arrow-u-left-top</v-icon> Não</v-btn>
                        </v-col>
                        <v-col cols="auto">
                            <v-btn :disabled="loading" :loading="loading" color="primary-blue" elevation="0" @click="save">Sim <v-icon right>mdi-check-bold</v-icon></v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import dayjs from 'dayjs';
import { mapActions, mapState } from 'vuex';

export default {
    data() {
        return {
            loading: false,
            headers: [
                { text: "Cliente", value: "customer.name" },
                { text: "Próxima sessão", value: "session_date", sortable: true },
                { text: '', value: 'actions', sortable: false },
            ],
            show_confirm: false,
            selected_session: null,
            session_list: []
        };
    },
    computed: {
        ...mapState("utils", {
            refresh: state => state.refresh
        })
    },
    watch: {
        refresh() {
            this.getSessions();
        }
    },
    mounted() {
        this.getSessions();
    },
    methods: {
        ...mapActions("sessions", ["ActionDoGetSessions", "ActionDoUpdateSession"]),
        getSessions() {
            this.loading = true;
            this.ActionDoGetSessions({ active_customer: true, finished: false, mounted: false }).then((response) => {
                this.session_list = response;
                this.loading = false;
            });
        },
        formatDate(date, hour = false) {
            return dayjs(date).format('DD/MM/YYYY' + (hour ? ' HH:mm' : ''));
        },
        showDialogConform(session) {
            this.selected_session = session;
            this.show_confirm = true;
        },
        closeDialogConfirm() {
            this.show_confirm = false;
            this.selected_session = null;
        },
        save() {
            this.loading = true;
            this.ActionDoUpdateSession({
                customer_id: this.selected_session.customer.id,
                session_date: this.selected_session.session_date,
                attended: false,
                comments: this.selected_session.comments,
                session_value: this.selected_session.session_value,
                session_duration: this.selected_session.session_duration,
                session_record: false,
                finished: false,
                mounted: true,
                session_id: this.selected_session.id
            }).then(() => {
                this.getSessions();
                this.closeDialogConfirm();
            }).catch(error => {
                console.error(error);
                this.loading = false;
            });
        }
    }
};
</script>
